@import "../custom_variables";

.associated-files-tooltip,
.tooltip.client-info-tooltip-v2,
.tooltip.filing-services-tooltip,
.tooltip.vendor-services-tooltip,
.tooltip-dark.show {
  pointer-events: auto !important;
  opacity: 1;
}

.tooltip.bs-tooltip-right.client-info-tooltip-v2 {
  margin-left: 5px;
}

.tooltip.bs-tooltip-right.client-info-tooltip-v2 .arrow::before {
  border-right-color: $dark-normal;
}

.tooltip.client-info-tooltip-v2 .tooltip-inner {
  background-color: $dark-normal;
  color: $light-normal;
  border: none;
  max-width: 360px;
  text-align: left;
  border-radius: 0;
  padding: 0;
}

.tooltip.bs-tooltip-top.qa-auto-accept .tooltip-inner {
  width: 610px;
  max-width: none;
}

.tooltip.filing-services-tooltip .tooltip-inner {
  background-color: $dark-normal;
  color: $light-normal;
  border: none;
  text-align: left;
  border-radius: 0;
  padding: 0;
  max-width: inherit;
}

.tooltip.bs-tooltip-top.vendor-services-tooltip {
  margin-bottom: 5px;
}

.tooltip.vendor-services-tooltip .tooltip-inner {
  background-color: #ffffff;
  min-width: 225px;
  text-align: left;
  font-size: 11px;
  padding: 0;
}

.tooltip.associated-files-tooltip {
  opacity: 1;
}

.tooltip.associated-files-tooltip .tooltip-inner {
  border: 0;
  padding: 0;
  width: 250px;
  font-size: 14px;
  background-color: #000;
}

// Additional style only for dashboard tile tooltip
.tooltip.bs-tooltip-top.dashboard-tile {
  margin-top: 100px;
  width: 200px;
}

 //UPDATED for comparison
.tooltip.tooltip-dark .tooltip-inner {
  color: $light-normal;
  border: none;
  background-color: black;
  text-align: left;
  border-radius: 0.25rem;
}

.bs-tooltip-top.tooltip-dark .arrow::before {
  border-top-color: $dark-normal;
}

.bs-tooltip-right.tooltip-dark .arrow::before {
  border-right-color: $dark-normal;
}

.bs-tooltip-bottom.tooltip-dark .arrow::before {
  border-bottom-color: $dark-normal;
}

.bs-tooltip-left.tooltip-dark .arrow::before {
  border-left-color: $dark-normal;
}

// Custom styling for tooltip in annuity table
.tooltip.annuity-table-tooltip.show {
  opacity: 1;
}

.tooltip.annuity-table-tooltip .tooltip-inner {
  background-color: #343a40;
  color: #fff;
  border: none;
  max-width: 630px;
}

// Custom styling for tooltip in company renewal reminder
.tooltip.company-renewal-reminder-tooltip.show {
  opacity: 1;
}

.tooltip.company-renewal-reminder-tooltip .tooltip-inner {
  background-color: #343a40;
  color: #fff;
  border: none;
  max-width: 630px;
}
