.ui-panel {
	padding: 0.2em;
}

.ui-panel .ui-panel-titlebar {
	padding: .5em .75em;
}

.ui-panel .ui-panel-titlebar.ui-panel-titlebar-clickable {
    cursor: pointer;
}

.ui-panel .ui-panel-titlebar-icon {
	float: right;
    cursor: pointer;
}

.ui-panel .ui-panel-titlebar-icon {
	margin-left: 0.2em;
    margin-top: -0.1em;
}

.ui-panel .ui-panel-content {
	border: 0;
    background: none;
    padding: .5em .75em;
}

.ui-panel .ui-panel-footer {
	border-width: 1px 0 0;
	padding: .25em .5em;
	text-align:left;
}
  
.ui-panel-content-wrapper-overflown {
    overflow: hidden;
}     