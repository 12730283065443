// Special Accordion Styles for each task
// Accordions have special colors depending on the task status.
// NOTE: Do not put non-accordion override styles here. Other styling goes to task-item.component.scss

@import "../../../../../stylesheet/custom_variables";

// Status: READY-TO-START
accordion-group .panel.townip-finances-accordion.in-progress .panel-heading.card-header,
accordion-group .panel.townip-finances-accordion.cancellation-request .panel-heading.card-header,
accordion-group .panel.townip-finances-accordion.ready-to-start .panel-heading.card-header {
  background-color: $green;
  color: #FFFFFF;
  font-weight: 400;
}

accordion-group .panel.townip-finances-accordion.cancellation-request.card,
accordion-group .panel.townip-finances-accordion.ready-to-start.card {
  border: 2px solid $green;
}
// -------------------------------------------------------------------------------------------------------------------

// Status: COMPLETE
accordion-group .panel.townip-finances-accordion.complete .panel-heading.card-header {
  background-color: #FFFFFF;
  color: #000000;
  font-weight: 400;
}

accordion-group .panel.panel-open .panel.townip-finances-accordion.complete .panel-heading.card-header {
  border-bottom: 2px solid $green;
}

accordion-group .panel.townip-finances-accordion.complete.card {
  border: 2px solid $green;
}
// -------------------------------------------------------------------------------------------------------------------

// Status: OVERDUE
accordion-group .panel.townip-finances-accordion.overdue .panel-heading.card-header {
  background-color: #B61717;
  color: #FFFFFF;
  font-weight: 400;
}

accordion-group .panel.townip-finances-accordion.overdue.card {
  border: 2px solid #B61717;
}
// -------------------------------------------------------------------------------------------------------------------

// Status: DEFAULT
accordion-group .panel.townip-finances-accordion.default .panel-heading.card-header {
  background-color: #FFFFFF;
  color: #000000;
  font-weight: 400;
}

accordion-group .panel.panel-open .panel.townip-finances-accordion.default .panel-heading.card-header {
  border-bottom: 2px solid #CCCCCC;
}

accordion-group .panel.townip-finances-accordion.default.card {
  border: 2px solid #CCCCCC;
}
// -------------------------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------------------------

// Status: LATE/DELAYED
accordion-group .panel.townip-finances-accordion.late .panel-heading.card-header {
  background-color: #FFFFFF;
}

accordion-group .panel.panel-open .panel.townip-finances-accordion.late .panel-heading.card-header {
  border-bottom: 2px solid red;
}

accordion-group .panel.townip-finances-accordion.late.card {
  border: 2px solid red;
}
// -------------------------------------------------------------------------------------------------------------------

.task-split-menu a {
  text-align: left;
}

.task-split-menu .ui-menuitem-text {
  font-size: 12px;
  font-weight: 400;
  font-family: $font-family-default !important;
  text-transform: capitalize;
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link:hover {
  text-decoration: none !important;
}

accordion-group .panel.townip-finances-accordion.estimate-review .card-body {
  padding-bottom: 0;
}

accordion-group .panel.townip-finances-accordion.service-item .card-body {
  padding-top: 5px;
}
