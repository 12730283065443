@import "custom_variables.scss";

/**
This file should only contain global styles that used in form controls.
 - DO NOT USE !important here.
 - If you are using !important, you're probably doing it wrong. (ex: Wrong selector override)
 */

$default-form-input-border: 2px solid #cacaca;

townip-root .form-group.has-icon-right,
townip-root .form-group.has-icon-left {
  position: relative;
}

townip-root .form-group.has-icon-right .form-control {
  padding-right: 2.5rem;
}

townip-root .form-group.has-icon-left .form-control {
  padding-left: 2.5rem;
}

townip-root .form-group .input-icon {
  position: absolute;
  top: 0.8rem;
  color: #ccc;
}

townip-root .form-group .input-icon.icon-right {
  right: 1rem;
}

townip-root .form-group .input-icon.icon-left {
  left: 1rem;
}

townip-root .form-control:disabled, .form-control[readonly] {
  background-color: transparent;
  border: 2px solid #cacaca;
  box-shadow: none !important;
  opacity: .65;
}

townip-root .form-control:disabled.filter-input, .form-control[readonly].filter-input {
  border: 1px solid #efefef
}

// Legacy override - was overridden in the wrong way in our base global style.
townip-root input.form-control {
  font-family: $font-family-default;
}

// The right way to override bootstrap's default form-control. Always match the original selector.
.form-control {
  border: $default-form-input-border;
  border-radius: 2px;
  padding: 0.5rem 1rem;
  height: 3.3rem;

  // NOTE: We are only adding important here because it had !important in the base styles.
  // Now we have to cascade it to override in here.
  // This is a negative effect of the !important in styles.scss
  line-height: 1.5 !important;
  font-size: 1rem !important;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 1.1rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.form-control-lg {
  height: calc(3.875rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

townip-root .form-control.custom-control {
  padding: 0;
  border: none;
  display: block;
  height: auto;
  // Bootstrap 4.6.X added z-index to custom control which breaks dropdown renderings.
  // Need to unset to make sure dropdowns work properly.
  z-index: unset;
}

 townip-root select.form-control:not([size]):not([multiple]):not(.standard-control) {
  height: auto;
  padding: .85rem 1rem;
}

townip-root .form-control.filter-input {
  border: 1px solid #E1E5E6;
  border-radius: 4px;
}

townip-root select.form-control.filter-input {
  border: 1px solid #E1E5E6;
  border-radius: 4px;
}

townip-root .basic-filter .form-group {
  padding: 0 0.2rem;
}

townip-root .basic-filter .form-group:first-child {
  padding-left: 0;
}


// By default, the dropdowns should be block-level styling. (Always fill 100% of the container)
// If you want to disable this for certain forms, add a custom class. (e.x townip-root form .form-dropdown.new-class)
townip-root form p-dropdown .ui-dropdown {
  width: 100%;
  border: $default-form-input-border;
  border-radius: 2px;
}

townip-root form p-dropdown.is-invalid .ui-dropdown,
townip-dropdown:not(.standard-control).ng-touched.ng-invalid .ui-dropdown {
  border-color: #dc3545;
}

townip-root form p-dropdown .ui-dropdown .ui-dropdown-label {
  font-size: 1rem;
  padding: .8rem 1rem;
  line-height: unset; // Must be the same as the .form-control above.
}

townip-root form p-dropdown .ui-dropdown.dropdown-sm {
  border-radius: .2rem;
  border-width: 1px;
}

townip-root form p-dropdown .ui-dropdown.dropdown-sm .ui-dropdown-label {
  padding: 0.3rem .5rem;
  font-size: 12pt !important;
}

// By default, the multiselect should be block-level styling. (Always fill 100% of the container)
// If you want custom size, wrap it in a container width a different size.
townip-root form p-multiSelect .ui-multiselect {
  width: 100%;
  font-size: 1rem;
  font-weight: normal;
  padding: .8rem 1rem;
  border: $default-form-input-border;
  line-height: 1.2;
  border-radius: 2px;
}

form p-multiSelect .ui-multiselect .ui-multiselect-trigger .fa {
  margin-top: 0.85em;
}

townip-root form p-autoComplete input.ui-autocomplete-input.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  height: auto;
  padding: 0.9rem 1rem;
  border: $default-form-input-border;
  border-radius: 2px;
}
