@import './custom_variables';

/* details and patent details country input box */
.projects-container .ui-dropdown-panel .ui-dropdown-filter-container {
  width: 100%;
}

.projects-container townip-message-content,
.projects-container townip-note-content {
  line-height: 20px;
  width: 100%;
}

/* messages dropdown */
.projects-container .messages .ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
  height: 50px;
  line-height: 40px;
  max-width: 350px;
}

.projects-container .messages label.ui-multiselect-label.ui-corner-all {
  line-height: 40px !important;
}

.projects-container input.form-control.thread-search-control { /* search box */
  border: 1px solid #eeeeee;
  padding-left: 30px;
}

.projects-container span.fa-search-input.search-message { /* search icon */
  margin-top: -25px;
  margin-left: 10px;
  color: #cccccc;
  position: absolute;
}

.projects-container .btn-cm-prev, /* previous & next button */
.projects-container .btn-cm-next {
  background: transparent;
  border: none;
  color: #9b9b9b;
}

/* ------------------ Overview Styles -------------------------*/
.projects-container .project-title-head {
  font-weight: 300;
  letter-spacing: 1.1px;
  text-align: left;
  color: #9b9b9b;
  text-transform: uppercase;
  line-height: 30px;
}

.projects-container .heading-content {
  font-weight: 300;
  text-align: left;
  color: $gray-dark;
}

.projects-container .due-date-content {
  font-weight: 300;
  letter-spacing: 1px;
  text-align: left;
  color: $white;
  width: 100px;
  height: 19px;
  border-radius: 4px;
  background-color: $green;
  padding: 5px;
}

.projects-container .project-client-details,
.projects-container .company-title,
.projects-container .client-title,
.projects-container .company-group {
  color: $green;
}

.projects-container .project-number {
  font-weight: 500;
  text-align: left;
  color: $gray-dark;
}

.projects-container .pct-header {
  height: 60px;
  background-color: $green;
  line-height: 58px;
}

.projects-container .pd-container {
  max-width: 650px;
  margin: 20px auto;
  background-color: $white;
  border: solid 1px $green;
  padding-bottom: 20px;
}

.projects-container .details-container {
  width: 85%;
  min-width: 800px;
  margin: 20px auto;
  background-color: $white;
  border: 1px solid #E1E5E6;
  border-radius: 4px;
  padding: 45px 40px;
}

/* country dropdown */
.pd-container  input.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  border: none;
}

.pd-container .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item {
  padding: 0 0.75em;
}

.pd-container .ui-inputtext {
  font-size: 11pt;
}

.pd-container .ui-dropdown {
  width: 100% !important;
}

.projects-container .project-num-title,
.projects-container .project-cat,
.projects-container .client-detail,
.projects-container .project-detail,
.projects-container .patent-detail {
  font-weight: 300;
  letter-spacing: 1.2px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: middle;
  height: 55px;
  display: table-cell;
}
.projects-container .project-num-title {
  color: $white;
}

.projects-container .client-detail,
.projects-container .patent-detail ,
.projects-container .project-detail{
  text-transform: capitalize;
}

.projects-container .project-cat {
  color: #9b9b9b;
}

.projects-container .project-num {
  text-align: left;
  color: $white;
}

.projects-container .pin-container i,
.projects-container .fp-container i {
  padding-right: 5px;
}

.projects-container .pin-container a,
.projects-container .fp-container a {
  cursor: pointer;
}

.projects-container .pin-container .selected,
.projects-container .fp-container .selected {
  color: $green;
}

.projects-container .container-tasks {
  border: none;
}

@media (min-width: 768px) {
  .projects-container .project-num-header {
    max-width: 200px;
  }
}

.projects-container .pd-row townip-simple-user {
  display: inline-flex;
}

.projects-container .pd-row {
  min-height: 58px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  padding: 20px;
  margin: 0 auto;
}

.projects-container .top-border {
  border-top: 1px solid #eeeeee;
}

.projects-container .pd-row span {
  display: table-row-group;
  vertical-align: middle;
  height: 58px;
}
.projects-container span.fa.fa-fw.fa-caret-down.ui-c {
  height: 45px;
}

.projects-container .ui-tabview-title {
  font-weight: bold;
  line-height: 2.08;
  letter-spacing: 1.6px;
  text-align: center;
  color: #9b9b9b;
  text-transform: uppercase;
}

.projects-container .ui-state-active .ui-tabview-title {
  color: $gray-dark;
}

.projects-container .ui-tabview .ui-tabview-panel {
  background-color: #f8fbfb;
}

.projects-container .btns-container {
  padding: 0 5px 18px;
  max-width: initial;
}

.projects-container .total-tasks,
.projects-container .total-projects {
  font-weight: bold;
  letter-spacing: 1.6px;
  color: $gray-dark;
  height: 60px;
  background-color: #f8fffd;
  border: solid 1px #eeeeee;
  padding: 15px;
  text-transform: uppercase;
}

/* ---------- end project ------------------ */

// ----------------------------//
/* details */

.projects-container .ui-tabview .ui-tabview-panel {
  background-color: #f8fbfb;
}

/* left align column title */
.projects-container th.ui-state-default {
  text-align: left;
}

.projects-container .col-name {
  color: #f8fbfb;
}

.projects-container .client-name {
  color: $gray-dark;
  font-weight: 400;
}

.projects-container .project-client-name,
.projects-container .project-p-type {
  color: $green;
  font-weight: 400;
}

/* --------- */

/* client's tab - remove the 2nd border on profile-container */
.townip-detail-nav-tabs-content .profile-container {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
/* --------- */

.projects-container .stats {
  padding: 0 10px;
}

/* Finances - Margin Analysis */
.projects-container .sub-tabs-outlet .ui-datatable .ui-datatable-tfoot > tr > td {
  border-color: transparent;
}

.projects-container .sub-tabs-outlet .ui-datatable .ui-datatable-tfoot > tr > td {
  padding: 0;
}

.projects-container tfoot.ui-datatable-tfoot tr:first-child td:first-child {
  padding-top: 10px !important;
}

.projects-container .sub-tabs-outlet .ui-table .ui-table-tfoot > tr > td {
  border-color: transparent;
}

.projects-container tfoot.ui-table-tfoot tr:first-child td:first-child {
  padding-top: 10px !important;
}

.projects-container .services .ml-5 {
  margin-top: 20px;
}
/* ----- */

.next-button,
.back-button {
  height: 30px;
  width: 30px;
  border: 1px solid #01B2A9;
  border-radius: 3px;
  background-color: $white;
  color: #01B2A9;
}

.back-button i,
.next-button i {
  height: 15px;
  width: 12px;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 15px;
}

.back-button:hover,
.next-button:hover {
  background-color: #01B2A9;
  color: $white;
}

td.footer-title.ui-state-default {
  text-align: right;
}
