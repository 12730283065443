/**
Styles for NGX-bootstraps accordion.
To use this styling you must use panelClass="townip-finances-accordion" input in the accordion-group.
This was to prevent leakage of new styles to previous accordion instances that had a different styling.

Moving forward though, this must be the style that we must use on all accordions.

<accordion>
  <accordion-group panelClass="townip-finances-accordion" [isOpen]="true">
  </accordion-group>
</accordion>
 */

@import "../custom_variables";

.panel.townip-finances-accordion .panel-heading.card-header {
  margin-left: 0;
  background-color: #E1E5E6;
  border-bottom: none;
  border-radius: 0;
  padding: .4rem 1.25rem;
  font-size: 15px;
  font-weight: 500;
}

.panel.townip-finances-accordion .panel-heading.card-header:hover {
  background-color: #E1E5E6;
}

.panel.townip-finances-accordion.pm-fileuploader.card {
  border-width: 1px 0;
}

.panel.pm-fileuploader .panel-body {
  padding: 0;
}

.panel.pm-fileuploader .panel-heading.card-header {
  background-color: #E1E5E6 !important;
}

.panel.pm-fileuploader .panel-heading {
  color: #000000 !important;
}

.panel.townip-finances-accordion.translation-document.card {
  border-width: 0;
}

.panel.townip-finances-accordion.card {
  border: 1px solid #E1E5E6;
  box-shadow: none;
  padding: 0;
  border-radius: 3px;
}

.panel.townip-finances-accordion {
  margin-bottom: 15px;
}

.panel.townip-finances-accordion.no-padding .panel-body {
  padding: 0;
}

.panel.townip-finances-accordion.active .panel-heading.card-header {
  background-color: $green;
  color: white;
}

.panel.townip-finances-accordion.active.card {
  border: 1px solid $green;
}

.panel.townip-finances-accordion.translation-document .panel-body.card-body {
  padding: 0;
  border: none;
}

// START: Primary Styles
// Primary style is the green-header version of the panel. (since our primary color is $green)
.panel.townip-finances-accordion.primary .panel-heading.card-header {
  background-color: $green;
}

.panel.townip-finances-accordion.primary .panel-heading {
  color: white;
}

.panel.townip-finances-accordion.primary.card {
  border: 1px solid $green;
}
// -- END: Primary Styles

.panel.townip-finances-accordion.gen-tran {
  width: 100%;
  border-radius: 3px;
  background-color: #F6F7F8;
  border-color: #F6F7F8;
  box-shadow: none;
  padding: 15px;
}

/**
  Vendor Pricing Service Accordion Styling
 */

townip-settings-v2 accordion .panel.panel-open .language-service .panel-heading.card-header {
  border-radius: 4px 4px 0 0;
}

.panel.language-service {
  padding: 12px 0 8px 0;
}

.panel.service-target-language {
  margin-bottom: 10px;
}

.panel.language-service > .panel-heading {
  background-color: #FFFFFF;
  font-weight: normal;
  border: 1px solid #dfdfdf;
  padding: 10px 10px 5px;
}

.panel.service-source-language > .panel-heading,
.panel.service-target-language > .panel-heading {
  background-color: #F7F7F7;
  border: 1px solid #E2E5E8;
}

.panel.service-target-language > .panel-heading {
  padding: 3px 15px;
}

.panel.service-target-language .panel-body {
  border: 1px solid #E2E5E8;
  border-top-width: 0;
}


/* [START] Bulk Complete Task - edit item accordion */

.panel.card.bulk-complete-item-accordion {
  padding: 0;
  background-color: #E1E5E6;
  box-shadow: none;
}

.panel.card.bulk-complete-item-accordion .card-header {
  margin: 0;
  padding: 0;
  background-color: #565656;
  color: #FFFFFF;
  border: 0
}

.panel.card.bulk-complete-item-accordion.complete .card-header {
  background-color: #173368;
}

.panel.card.bulk-complete-item-accordion.active .card-header {
  background-color: #00B1A8;
}

.panel.card.bulk-complete-item-accordion .accordion-toggle {
  font-size: 14px !important;
}

/* [END] Bulk Complete Task - edit item accordion */



// ------------------------------------
// START: basic accordion
// ------------------------------------
// DO NOT CHANGE THIS unless you have asked @dpili.
// Chances are you don't need to change any of these

$basic-accordion-default-color: #E1E5E6;
$basic-accordion-default-radius: 0.3rem;

.panel.card.basic-accordion {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: white;
}

// Spacing for multiple accordion-group under 1 accordion.
.panel-group > .panel > .panel.card.basic-accordion {
  margin-top: 1.5rem;
}

.panel-group > .panel:first-child > .panel.card.basic-accordion {
  margin-top: 0;
}

.panel.card.basic-accordion .panel-heading.card-header {
  margin: 0;
  padding: 0.6rem 1rem;
  background-color: $basic-accordion-default-color;
  color: $gray-dark;
  border: none;
  border-radius: $basic-accordion-default-radius;
}

.panel-open .panel.card.basic-accordion .panel-heading.card-header {
  border-radius: $basic-accordion-default-radius $basic-accordion-default-radius 0 0;
}

.panel.card.basic-accordion .panel-body.card-body {
  padding: 1rem;
  border-left: 1px solid $basic-accordion-default-color;
  border-right: 1px solid $basic-accordion-default-color;
  border-bottom: 1px solid $basic-accordion-default-color;
  border-radius: 0 0 $basic-accordion-default-radius $basic-accordion-default-radius;
}

.panel.card.basic-accordion .accordion-toggle {
  font-size: 1rem;
}

// --- WHITE ---
.panel.card.basic-accordion.basic-accordion-white .panel-heading.card-header {
  background-color: white;
}

.panel.card.basic-accordion.basic-accordion-white .panel-body.card-body {
  border-color: transparent;
}


// --- GREEN ---
.panel.card.basic-accordion.active > .panel-heading.card-header {
  background-color: $primary;
  color: white;
}

.panel.card.basic-accordion.active > .panel-collapse > .panel-body.card-body {
  border-color: $primary;
}


// ------------------------------------
// END: basic accordion
// ------------------------------------


// ------------------------------------
// START: basic accordion themes
// ------------------------------------

.panel.card.basic-accordion.annuity-pm-info-panel .panel-heading.card-header {
  background: #FFFFFF;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

// ------------------------------------
// END: basic accordion themes
// ------------------------------------
