@import 'custom_variables';
@import "scss/functions";
@import "scss/variables";

.sc-container p-tabMenu .ui-widget-header {
  border: none;
}

.sc-container p-tabMenu .ui-state-active {
  color: $white !important;
}

.sc-container p-tabMenu .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link {
  background-color: $green;
}

.sc-container p-paginator .ui-widget-header {
  border: none;
}

.sc-container .form-control {
  line-height: 1.25 !important;
  font-size: 1rem !important;;
  padding: .5rem .75rem;
}

.sc-container .form-control.ng-touched.ng-invalid {
  border-color: $danger;
}

.sc-container .ui-panel-title {
  font-weight: bold;
  color: #4b5257;
}

.sc-container .ui-panel .ui-panel-titlebar {
  background-color: $block-panel-header-bg;
}

.sc-container button.btn.btn-primary.btn-sm,
.sc-container .btn-success {
  background: $green;
  border-color: $green;
  color: $white;
  box-shadow: none;
}

.sc-container .badge-info {
  background: $green;
}

/* ---- */


.sc-container i.fa.fa-search {
  font-size: 14pt;
}

.form-country-container .ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
  width: 100%;
}

/* vendor configuration */
.scc-container button.btn.btn-primary {
  background: $green;
  border-color: $green;
  box-shadow: none;
}


.sc-container span.badge.badge-info {
  background: $green;
}

.sc-container .btn-success,
.sc-container .btn-success.active,
.sc-container .btn-success:active,
.sc-container .show>.btn-success.dropdown-toggle {
  background: $green;
  color: $white;
}

.sc-container .btn-success:hover {
  opacity: 0.8;
  background: $green;
  border-color: $green;
}

/* calendar */
.sc-container .townip-cal .ui-inputtext {
  height: 34px;
}

.sc-container .ui-button:enabled:hover .fa-calendar {
  color: $white;
}

/* translation tool */
.translation-tool button.btn.btn-primary {
  background: $green;
  border-color: $green;
  box-shadow: none;
}
/* ----- */

.sc-container .ui-calendar input.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  padding-left: 20px !important;
}

.sc-container button.btn.btn-secondary.btn-sm:hover {
  background: none;
  color: $green;
}

.sc-container .ui-accordion .ui-accordion-header.ui-state-active {
  background-color: $green;
  color: $white;
}

@media (max-width: 768px) {
  .sc-container button.btn.btn-primary.btn-sm {
    margin-bottom: 20px;
  }

  .sc-container .btn-success {
    margin-bottom: 5px;
  }
}
