@import "custom_variables";

// TODO: Standardize button styling based on the STYLE GUIDE
// We need to decide which button class are we going to use for all our buttons or else we will all be using
// different stylings for different buttons

/** Styles for prime buttons **/

.ui-button-success,
.ui-button.ui-button-success,
.ui-button.ui-button-success.ui-state-default,
.ui-button.ui-button-success:enabled:hover,
.ui-button.ui-button-success:visited,
.ui-button.ui-button-success:active,
.ui-button.ui-button-success:enabled:active,
.ui-splitbutton.ui-button-success,
.ui-splitbutton.ui-button-success .ui-button.ui-state-default,
.ui-splitbutton.ui-button-success:enabled:hover,
.ui-splitbutton.ui-button-success:visited,
.ui-splitbutton.ui-button-success:active,
.ui-splitbutton.ui-button-success .ui-button:enabled:active {
  border: 1px solid $green;
  background-color: $green;
  color: $white;
}

.ui-button-secondary,
.ui-button.ui-button-secondary,
.ui-button.ui-button-secondary.ui-state-default,
.ui-button.ui-button-secondary:enabled:hover,
.ui-button.ui-button-secondary:visited,
.ui-button.ui-button-secondary:active,
.ui-button.ui-button-secondary:enabled:active,
.ui-splitbutton.ui-button-secondary,
.ui-splitbutton.ui-button-secondary .ui-button.ui-state-default,
.ui-splitbutton.ui-button-secondary:enabled:hover,
.ui-splitbutton.ui-button-secondary:visited,
.ui-splitbutton.ui-button-secondary:active,
.ui-splitbutton.ui-button-secondary .ui-button:enabled:active {
  border: 1px solid $white;
  background-color: $white;
  color: $green;
}

.ui-button-info,
.ui-button.ui-button-info,
.ui-button.ui-button-info.ui-state-default,
.ui-button.ui-button-info:enabled:hover,
.ui-button.ui-button-info:visited,
.ui-button.ui-button-info:active,
.ui-button.ui-button-info:enabled:active {
  border: 1px solid $table-gray;
  background-color: $table-gray;
  color: $white;
}

// Should fix alignment issues on certain browsers
// This is not a silver bullet though, there could be a chance that it might spawn a different issue. (cross-fingers)
.ui-splitbutton {
  display: flex;
  align-content: stretch;
}

body .ui-splitbutton.ui-buttonset .ui-button {
  border: 1px solid $green;
  background-color: $green;
  color: white;
}

body .ui-splitbutton.ui-buttonset.task-overview-split-btn .ui-button:first-child {
  width: 130px;
}

body .estimate-review.ui-splitbutton.ui-buttonset .ui-button {
  height: 45px;
}

body .ui-splitbutton.ui-buttonset .ui-button:hover,
body .ui-splitbutton.ui-buttonset .ui-button:active,
body .ui-splitbutton.ui-buttonset .ui-button:visited {
  background-color: $green2;
}

/**
  These butts will be the full width of whatever container they a placed in.
 */
button.ui-button-large {
  width: 100%;
  min-width: fit-content;
  margin: 10px;
  padding: 10px;
}

button.ui-button-large .ui-button-icon-left {
  font-size: medium;
  position: unset;
}

button.ui-button-large .ui-button-text {
  display: inline-block;
  padding-left: .5em;
}

/** Modal footer buttons */
p-footer button.ui-button-large {
  width: 210px;
  padding-top: 13px;
  padding-bottom: 13px;
}

/**
  Styles for prime dropdowns
 */
body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link:hover {
  color: white;
  background-color: $green;
}

body .ui-menu .ui-menu-list .ui-menuitem .ui-menuitem-link:hover {
  color: $green;
  background-color: $transparent;
  text-decoration: underline;
  transition: color 280ms, text-decoration 280ms;
}

/**
  Raised button styling
 */
.raised-button {
  border: transparent !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.raised-button:hover {
  border: transparent !important;
  box-shadow: $hover-shadow;
}

.raised-button:active {
  border: transparent !important;
  box-shadow: $active-shadow;
}

.raised-button:disabled {
  border: transparent !important;
  box-shadow: $default-shadow !important;
  cursor: not-allowed !important;
}


.raised-button.btn-secondary:hover,
.raised-button.btn-secondary:active,
.raised-button.btn-secondary.active,
.show>.raised-button.btn-secondary.dropdown-toggle{
  background-color: $white;
  color: #333333;
}

/**
  TownIP Buttons
 */
.tip-button {
  width: 100%;
  border: 1px solid #D1D1D1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: $roboto;
  background-color: $white;
  border-radius: 2px;
  font-size: 18px;
  text-transform: capitalize;
  color: $gray-dark;
  margin: 30px 10px 0 0;
  padding: 9px 12px;
  cursor: pointer;
}

.tip-button:hover {
  color: $green;
  transition: color 0.5s, box-shadow 0.5s;
}

.tip-button:active,
.btn.tip-button:active,
.btn.tip-button:focus {
  color: $green;
  transition: color 0.5s, box-shadow 150ms;
  border: 1px solid $green;
  font-weight: bold;
}

.tip-button.active {
  color: $green;
  border: 1px solid $green;
  font-weight: bold;
}

body .ui-state-disabled *, body .ui-widget:disabled * {
  cursor: not-allowed !important;
}


/** File Upload Component Buttons */

.ui-fileupload.ui-widget .ui-button,
.ui-fileupload.ui-widget .ui-button:hover {
  background-color: $green;
}

body .ui-fileupload-choose:not(.ui-state-disabled):active {
  background-color: $green;
}


/** Font Awesome Buttons */
.fa-button{
  color: $gray-dark;
  padding: 2px 8px;
  border: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.fa-button:hover,
.fa-button:active,
.fa-button:focus{
  color: $green;
}

.fa-button:focus {
  outline-color: transparent;
  box-shadow: none;
}

.fa-button:disabled {
  border: transparent !important;
  color: $gray-dark !important;
  cursor: not-allowed !important;
}


.question-button {
  margin: 0 10px;

  &:first-child {
    margin-left: 0;
  }
}

// --- BOOTSTRAP SPLIT DROPDOWN TOGGLE --- //
// NOTE: Do not modify these styles unless you're modifying globally.
// This style is for the DEFAULT dropdowns GLOBALLY.
// If you are trying to fix the style of the dropdown in a component because it looks weird,
// FIX IT in the component. Not here.
.dropdown-toggle::after {
  border: none;
  font-family: FontAwesome;
  content: $chevron-down;
  font-size: x-small !important;
  float: none !important;
}

.btn+.dropdown-toggle-split {
  width: 30px;
}
.btn+.dropdown-toggle-split::after {
  margin-left: -10px;
}
// --- END: BOOTSTRAP SPLIT DROPDOWN TOGGLE --- //

.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  box-shadow: none;
}

.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  background-color: $green;
  color: $white;
  border-color: $green;
}

.edit-button button .ui-button-icon-left {
  font-size: medium;
  left: 22px;
}

.edit-button button .ui-button-text {
  font-weight: 500;
  font-size: 16px;
}


.large-split p-splitButton {
  border-radius: 4px;
}

.large-split p-splitButton .ui-splitbutton {
  padding: 6px;
}

.large-split p-splitButton .ui-button-icon-left {
  font-size: medium;
}

.footer-buttons .ui-splitbutton .ui-button,
.footer-buttons .ui-splitbutton-menubutton {
  height: 50px;
  font-size: 18px;
}

/** buttons from settings */
button.btn-link {
  padding: 0;
}

.btn-sm {
  padding: 2px 15px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.btn-link.btn-xs {
  padding: 0;
  font-weight: normal;
  display: inline-block;
  min-width: 0;
  font-size: 1rem;
}

.btn-link.btn-xs:hover {
  text-decoration: none;
  color: darken($green, 20%);
}

.btn-link.btn-xs.dark {
  color: $gray-dark;
}

.btn-link.btn-xs.dark:hover {
  color: lighten($gray-dark, 20%);
}

.btn-lg {
  padding: 1rem 3rem;
  border-radius: 6px;
  font-size: 1rem;
}

.btn-secondary:hover {
  background-color: #CCCCCC;
  border-color: transparent;
}

.btn-light {
  color: $green;
  background-color: #F9FAFA;
  border-color: transparent;
  font-weight: bold;
}

.btn-icon {
  background-color: transparent;
  text-align: center;
  padding: 0 2px;
  min-width: 0;
}

.btn-icon:hover {
  color: $primary-hover;
}

.btn-icon .fa {
  margin-right: 0;
}

.btn-service-request {
  height: 40px;
  width: 150px;
  border-radius: 4px;
}

.btn-bulk-accept {
  height: 40px;
  min-width: 123.52px;
  border-radius: 6px;
}


// **********
// Generic townip button
// **********

button {
  margin-right: 5px;
}

button:last-child,
button:last-of-type {
  margin-right: 0;
}

.btn {
  font-weight: 400;
}

.btn:focus,
.btn:active {
  box-shadow: none;
}

.btn.btn-primary {
  background-color: $primary-normal;
  border-color: $primary-normal;
}

.btn.btn-primary:hover,
.btn.btn-primary:disabled {
  background-color: $primary-hover;
  border-color: $primary-hover;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.08);
}

.btn.btn-primary:active,
.btn.btn-primary:not([disabled]):not(.disabled).active,
.btn.btn-primary:not([disabled]):not(.disabled):active {
  background-color: $primary-active;
  border-color: $primary-active;
  box-shadow: none;
}

.btn-outline-primary {
  background-color: transparent;
  color: $primary-normal;
}

.btn-outline-primary:active:not(:disabled),
.btn-outline-primary.active,
.btn-outline-primary:not(:disabled):not(.disabled).active, {
  background-color: $primary;
  border-color: $primary;
  opacity: 1;
  box-shadow: none;
}

.btn.btn-secondary {
  background-color: $secondary-normal;
  border-color: $secondary-normal
}

.btn.btn-secondary:hover,
.btn.btn-secondary:disabled {
  background-color: $secondary-hover;
  border-color: $secondary-hover;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.08);
}

.btn.btn-secondary:active,
.btn.btn-secondary:not([disabled]):not(.disabled).active,
.btn.btn-secondary:not([disabled]):not(.disabled):active {
  background-color: $secondary-active;
  border-color: $secondary-active;
  box-shadow: none;
}

.btn.btn-light {
  background-color: $light-normal;
  border-color: transparent;
  color: $primary-normal;
}

.btn.btn.btn-light:hover,
.btn.btn.btn-light:disabled {
  background-color: $light-hover;
  border-color: $light-hover;
  box-shadow: none;
  color: #111;
}

.btn.btn.btn-light:active,
.btn.btn.btn-light:not([disabled]):not(.disabled).active,
.btn.btn.btn-light:not([disabled]):not(.disabled):active {
  background-color: $light-active;
  border-color: $light-active;
  box-shadow: none;
  color: #111;
}

.btn.btn-dark {
  background-color: $dark-normal;
  border-color: $dark-normal;
}

.btn.btn.btn-dark:hover,
.btn.btn.btn-dark:disabled {
  background-color: $dark-hover;
  border-color: $dark-hover;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.08);
}

.btn.btn.btn-dark:active,
.btn.btn.btn-dark:not([disabled]):not(.disabled).active,
.btn.btn.btn-dark:not([disabled]):not(.disabled):active {
  background-color: $dark-active;
  border-color: $dark-active;
  box-shadow: none;
}

.btn.btn-primary-outline {
  background-color: $white;
  border-color: $primary-normal;
  color: $primary-normal;
}

.btn.btn-primary-outline:hover {
  background-color: $white;
  border-color: $primary-hover;
  color: $primary-hover;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.08);
}

.btn.btn-primary-outline:active:not(:disabled),
.btn.btn-primary-outline:not([disabled]):not(.disabled).active,
.btn.btn-primary-outline:not([disabled]):not(.disabled):active {
  background-color: $primary-active;
  border-color: $primary-active;
  color: $white;
  box-shadow: none;
}

.btn.btn-secondary-outline {
  background-color: $white;
  border-color: $secondary-normal;
}

.btn.btn-secondary-outline:hover {
  background-color: $white;
  border-color: $secondary-hover;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.08);
}

.btn.btn-secondary-outline:active,
.btn.btn-secondary-outline:not([disabled]):not(.disabled).active,
.btn.btn-secondary-outline:not([disabled]):not(.disabled):active {
  background-color: $primary-active;
  border-color: $primary-active;
  color: $white;
  box-shadow: none;
}

.btn.btn-light-outline {
  background-color: $light-normal;
  border-color: $secondary-normal;
  color: $primary-normal;
}

.btn.btn.btn-light-outline:hover {
  border-color: $secondary-hover;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.08);
}

.btn.btn.btn-light-outline:active,
.btn.btn.btn-light-outline:not([disabled]):not(.disabled).active,
.btn.btn.btn-light-outline:not([disabled]):not(.disabled):active {
  border-color: $primary-normal;
  background-color: $primary-normal;
  box-shadow: none;
  color: $white
}

.btn.btn-dark-outline {
  background-color: $white;
  border-color: $white;
  color: $dark-normal;
}

.btn.btn.btn-dark-outline:hover,
.btn.btn.btn-dark-outline:disabled {
  background-color: $white;
  border-color: $white;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.08);
}

.btn.btn.btn-dark-outline:active,
.btn.btn.btn-dark-outline:not([disabled]):not(.disabled).active,
.btn.btn.btn-dark-outline:not([disabled]):not(.disabled):active {
  background-color: $white;
  border-color: $white;
  box-shadow: none;
}

// ----------
// Button sizes
// tiny, small, regular, medium, large
// ----------
// Note: still need to define the value for each sizes
// Difference between tiny - small, and regular - medium
// ----------
.btn.btn-tiny {
  padding: .2rem .5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.btn.btn-small {
  padding: .2rem .5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.btn.btn-regular {
  padding: .375rem .75rem;
  border-radius: 6px;
  font-size: 1rem;
}

.btn.btn-medium {
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1rem;
}

.btn.btn-large {
  padding: 1rem 3rem;
  border-radius: 6px;
  font-size: 1rem;
}

// Custom style class: Display popover of splitbutton on button, and make the width 100%
p-splitButton .ui-splitbutton.complete-project .ui-menu {
  width: 100%;
  top: 35px !important;
}

// Estimate Table Approve button
.ui-splitbutton.estimate-approve .ui-button-text-only .ui-button-text {
  padding: 4px 5px 4px 4px;
}

