.ui-table {
    position: relative;
}

.ui-table table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

.ui-table .ui-table-thead > tr > th,
.ui-table .ui-table-tbody > tr > td,
.ui-table .ui-table-tfoot > tr > td {
    padding: .25em .5em;
}

.ui-table .ui-sortable-column {
    cursor: pointer;
}

.ui-table p-sorticon {
    vertical-align: middle;
}

.ui-table-auto-layout > .ui-table-wrapper {
    overflow-x: auto;
}

.ui-table-auto-layout > .ui-table-wrapper > table {
    table-layout: auto;
}

/* Sections */
.ui-table-caption,
.ui-table-summary {
    padding: .25em .5em;
    text-align: center;
    font-weight: bold;
}

.ui-table-caption {
    border-bottom: 0 none;
}

.ui-table-summary {
    border-top: 0 none;
}

/* Paginator */
.ui-table .ui-paginator-top {
    border-bottom: 0 none;
}

.ui-table .ui-paginator-bottom {
    border-top: 0 none;
}

/* Scrollable */
.ui-table-scrollable-wrapper {
    position: relative;
}
.ui-table-scrollable-header,
.ui-table-scrollable-footer {
    overflow: hidden;
    border: 0 none;
}

.ui-table-scrollable-body {
    overflow: auto;
    position: relative;
}

.ui-table-virtual-table {
    position: absolute;
}

.ui-table-loading-virtual-table {
    display: none;
}

/* Frozen Columns */
.ui-table-frozen-view .ui-table-scrollable-body {
    overflow: hidden;
}

.ui-table-frozen-view > .ui-table-scrollable-body > table > .ui-table-tbody > tr > td:last-child {
    border-right: 0 none;
}

.ui-table-unfrozen-view {
    position: absolute;
    top: 0px;
}

/* Resizable */
.ui-table-resizable > .ui-table-wrapper {
    overflow-x: auto;
}

.ui-table-resizable .ui-table-thead > tr > th,
.ui-table-resizable .ui-table-tfoot > tr > td,
.ui-table-resizable .ui-table-tbody > tr > td {
    overflow: hidden;
}

.ui-table-resizable .ui-resizable-column {
    background-clip: padding-box;
    position: relative;
}

.ui-table-resizable-fit .ui-resizable-column:last-child .ui-column-resizer {
    display: none;
}

.ui-table .ui-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: .5em;
    height: 100%;
    padding: 0px;
    cursor:col-resize;
    border: 1px solid transparent;
}

.ui-table .ui-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
}

/* Edit */
.ui-table .ui-table-tbody > tr > td.ui-editing-cell {
    padding: 0;
}

.ui-table .ui-table-tbody > tr > td.ui-editing-cell p-celleditor >  * {
    width: 100%;
}

/* Reorder */
.ui-table-reorder-indicator-up,
.ui-table-reorder-indicator-down {
    position: absolute;
    display: none;
}

/* Responsive */
.ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    .ui-table-responsive .ui-table-thead > tr > th,
    .ui-table-responsive .ui-table-tfoot > tr > td {
        display: none !important;
    }

    .ui-table-responsive .ui-table-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none;
        width: 100% !important;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		clear: left;
    }

    .ui-table-responsive .ui-table-tbody > tr > td .ui-column-title {
        padding: .4em;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4em;
        font-weight: bold;
    }
}

/* Loader */
.ui-table-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
}

.ui-table-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

.ui-table .ui-table-loading-icon {
    font-size: 2em;
}
