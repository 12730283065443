.ui-fluid .ui-inputgroup p-inputmask {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.ui-inputgroup p-inputmask:not(:first-child) .ui-inputtext {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0 none;
}

.ui-inputgroup p-inputmask:not(:last-child) .ui-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none;
}
