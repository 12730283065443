.ui-scrollpanel-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    float: left;
}

.ui-scrollpanel-content {
    height: calc(100% + 18px);
    width: calc(100% + 18px);
    padding: 0 18px 18px 0;
    position: relative;
    overflow: auto;
    box-sizing: border-box;
}

.ui-scrollpanel-bar {
    position: relative;
    background: #c1c1c1;
    border-radius: 3px;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.25s linear;
}

.ui-scrollpanel-bar-y {
    width: 9px;
    top: 0;
}

.ui-scrollpanel-bar-x {
    height: 9px;
    bottom: 0;
}

.ui-scrollpanel-hidden {
    visibility: hidden;
}

.ui-scrollpanel:hover .ui-scrollpanel-bar,
.ui-scrollpanel:active .ui-scrollpanel-bar {
    opacity: 1;
}

.ui-scrollpanel-grabbed {
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}