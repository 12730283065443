@import "../custom_variables";

// Custom progress bar for new estimate
p-fileUpload .ui-fileupload .ui-fileupload-content .ui-progressbar {
  height: 25px;
  border-radius: 0px;
}

p-fileUpload .ui-fileupload .ui-fileupload-content .ui-progressbar .ui-progressbar-value {
  height: 25px;
  border-radius: 0px;
}

/** Start Tiny Upload Styling **/
body .ui-fileupload.tiny-upload .ui-fileupload-buttonbar {
  display: none;
}

body .ui-fileupload.tiny-upload .ui-fileupload-content {
  border: 0;
  padding: 0;
  background-color: #FBFBFB;
}

body .ui-fileupload.tiny-upload .ui-fileupload-content .tiny-upload-content {
  border: 2px dashed $primary;
  background-color: white;
  font-size: 14px;
  border-radius: 5px;
}

body .ui-fileupload.tiny-upload.gray .ui-fileupload-content .tiny-upload-content {
  border: 2px dashed #C0C0C0;
  background-color: #F3F5F6;
  font-size: 14px;
}

body .ui-fileupload.tiny-upload.upload-error .ui-fileupload-content .tiny-upload-content {
  border-color: $danger;
}

body .ui-fileupload.tiny-upload .ui-fileupload-content .ui-progressbar {
  height: 3px;
  border-radius: 0px;
}

body .ui-fileupload.tiny-upload .ui-fileupload-content .ui-progressbar .ui-progressbar-value {
  height: 3px;
}
/** End Tiny Upload Styling  **/
