@import '../custom_variables.scss';
@import '../../app/calendar/service-colors.scss';

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-event-container a {
  background: #fff;
  border: 1px solid #DEDEDE;
  border-left: 5px solid rgba(25,200,154,0.8);
  padding: 5px;
  margin:  2px 5px;
  font-size: 10px;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-more-popover .fc-widget-content {
  max-height: 250px;
  overflow: auto;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-more {
  margin: 5px 10px;
  background-color: #F1F1F1;
  font-size: 10px;
  padding: 2px 2px;
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.15);
  font-weight: 400;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .ui-button {
  background-color: #00B1A8;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-header {
  border: none;
  padding-bottom: 10px;
  text-align: left;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .ui-widget-header {
  border:none;
  background: transparent;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed  .fc-toolbar .fc-next-button span::before {
  content: "\f054";
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-prev-button {
  margin-right: 10px;
  border-radius: 3px;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-next-button {
  border-radius: 3px;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-toolbar .fc-prev-button span::before {
  content: "\f053";
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-toolbar h2 {
  color: #353F47;
  font-size: 16px;
  letter-spacing: 2px;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-dayGrid-view .fc-widget-content .fc-day-grid .fc-day-top .fc-day-number {
  float:none;
  padding-left: 10px;
  height: 16px;
  width: 24px;
  color: #312F2F;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.36px;
  line-height: 16px;
  font-weight: 400;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-content {
  font-weight: 500;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-dayGrid-view .fc-widget-content .fc-day-grid .fc-day-top.fc-sun .fc-day-number,
p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-dayGrid-view .fc-widget-content .fc-day-grid .fc-day-top.fc-sat .fc-day-number {
  color: #64BAA2;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-top {
  padding: 10px 0px 0px 0px;
  text-align:left;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-header span {
  font-weight: 400;
  letter-spacing: 2px;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed th.fc-day-header {
  color: #818487;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 10px;
  font-family: 'Roboto';
  background-color: transparent;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-event-container .fc-event {
  border-left-color: $calendar-default;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-event-container .fc-event.COMPLETE {
  border-left-color: $status-completed;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-event-container .fc-event.ON_TRACK {
  border-left-color: $status-on-track;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-event-container .fc-event.OVERDUE {
  border-left-color: $status-overdue;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-event-container .fc-event.ON_HOLD {
  border-left-color: $status-on-hold;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-event-container .fc-event.LATE {
  border-left-color: $status-overdue;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-event-container .fc-content .additional-title {
  display:block;
  color: #64BAA2;
  font-size: 10px;
  font-weight: normal;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-body .fc-row.fc-week .fc-day.ui-widget-content {
  border: 1px solid #DEDEDE;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-body .fc-row.fc-week .fc-day.ui-widget-content:not(:last-child) {
  border-right:none;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-body .fc-row.fc-week:not(:last-child) .fc-day.ui-widget-content {
  border-bottom:none;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-body .fc-row.fc-week .fc-day.fc-sun:not(.fc-other-month),
p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-body .fc-row.fc-week .fc-day.fc-sat:not(.fc-other-month) {
  background-color:#f0f8f5;
}
p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-body .fc-row.fc-week .fc-day.fc-past.fc-other-month {
  background: repeating-linear-gradient( 55deg, #fff, #DEDEDE 1px, #fff 1px, #fff 5px );
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-body .fc-row.fc-week .fc-day-top.fc-other-month .fc-day-number {
  color: #AAADB9;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-body .fc-row.fc-week .fc-day-top {
  border: none;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-month-view table .ui-widget-content .fc-day-grid .fc-bg table  {
  border-collapse: initial;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc td,
p-fullcalendar .pm-calendar.fc.fc-unthemed .fc th{
  border:none;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-grid .fc-day {
  background: white;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc th{
  padding-bottom: 20px;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed td.fc-event-container[colspan]:not([colspan='1']) a {
  background-color: #fff;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-toolbar.fc-header-toolbar {
  display: none;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-view-container .fc-body > tr > td.fc-widget-content,
p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-grid .fc-row.fc-week:first-child .fc-day.fc-widget-content {
  border-top: none;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed td.fc-day.ui-widget-content:hover {
  border: 2px solid #64BAA2;
}

p-fullcalendar .fc-head .fc-head-container.fc-widget-header {
  border: none;
}

p-fullcalendar .fc-head .fc-head-container.fc-widget-header .fc-row.fc-widget-header {
  border:none;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-view-container .fc-view > table tbody {
  border: 1px solid #ddd;
  border-top:none;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-grid .fc-day.fc-today,
p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-grid .fc-day {
  height: 190px;
  /* using percentages is causing style issues in IE and FIREFOX,
  height has to be declared explicitly whenever changes in contentHeight in the ts is made */
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-grid .fc-day.fc-today {
  position: relative;
  display: flex;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-grid .fc-row.fc-week:first-child .fc-day.fc-widget-content.fc-today,
p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-day-grid .fc-day.fc-today {
  border: 2px solid #64BAA2;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-view-container .fc-view > table > tbody > tr > td {
  border: none;
}

p-fullcalendar .pm-calendar.fc.fc-unthemed .fc-row table {
  border-collapse: collapse;
}

p-fullcalendar .pm-calendar .fc-day-grid-container {
  overflow: hidden !important;
}

