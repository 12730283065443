/** Prime Dialog */
// Prime currently sets this on the element to 1002, we need it universally higher to appear above the navbar.
// Adding important in lue of setting this on every dialog.
p-dialog .ui-dialog {
  z-index: 999998 !important; // Note: Navigation bar is 999997. We want this to be on top.
  min-width: 630px !important;
  border-radius: 4px;
  padding: 0rem 1rem 0rem 1rem;
}

.dialog-large p-dialog .ui-dialog {
  width: 800px !important;
  top: 0 !important;
  height: fit-content !important;
  max-height: 100% !important;
  overflow-y: scroll !important;
}

p-dialog .ui-dialog .ui-dialog-content {
  padding: 0 1em;
  overflow-x: hidden;
}

p-dialog .ui-dialog .ui-dialog-titlebar {
  padding: 1em;
  border: none;
}

span.ui-dialog-title {
  border-bottom: 1px solid #c3c3c3;
  width: 100%;
  display: inline-block;
  padding-bottom: 10px;
}

p-dialog .ui-dialog-footer {
  border: none;
  border-top: 1px solid #c3c3c3 !important;
  padding: 0 0px 20px 20px;
  margin: 0em 1em 0em 1em;
}

p-dialog .dialog-border {
  border-bottom: 1px solid #c3c3c3;
}

p-dialog .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  font-weight: 400;
  font-size: 24px;
  color: #15A598;
}

p-dialog .ui-button-success,
p-dialog .ui-button-cancel {
  padding: 10px 60px 10px 60px;
  margin-top: 20px;
}

p-dialog .ui-button-cancel {
  background: #c3c3c3 !important;
  color: #ffffff !important;
}

p-dialog .ui-button-success .fa,
p-dialog .ui-button-cancel .fa {
  padding-left: 65px;
}

p-dialog .ui-clickable.fa {
  font-size: small;
}

p-dialog .ui-dialog .ui-dialog-titlebar-close span {
  margin-top: 0;
  margin-left: -30px;
  position: absolute;
  font-size: 20pt;
  color: #c3c3c3;
}

p-dialog .ui-dialog .ui-dialog-titlebar-close span:hover {
  color: #9D9D9D;
}
