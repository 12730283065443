@import "custom_variables.scss";
.spacer {
  padding-bottom: 50px;
}

.div-settings-title span { /* settings heading title */
  font-size: 18pt;
  letter-spacing: 1px;
  margin-top: 20px;
}

.div-settings-title { /* settings heading title top margin*/
  margin-top: 20px;
}

.prof-img-container {
  margin-top: 40px;
}

.prof-img-container img { /* image size */
  width: 150px;
}

.current-settings {
  margin-top: 20px;
  margin-bottom: 60px;
}

.current-settings span, span.profile-name {
  color: #BCFFF0;
}

/* email */
span.user-email {
  color: #D4D2D2;
  text-transform: lowercase;
  font-size: 10.5pt;
}

.div-email {
  margin-top: -50px;
}

.border-list {
  border-bottom: 1px solid #61a394;
  max-width: 250px;
}

.col-sm-12.border-list span:hover {
  color: #BCFFF0 !important;
  cursor: default;
}

.ocn-nav-link{
  color: white;
  font-weight: 300;
  padding: 0;
}

.ocn-nav-link:hover{
  color: #BCFFF0;
}

/* Off-Canvas-Nav buttons */
.anchor-button{
  font-size: 12pt;
  text-align: center;
  line-height: 25px;
  letter-spacing: 1px;
  color: $white;
  border: 1px solid $white;
  border-radius: 20px;
  width: 100%;
  max-width: 250px;
  padding: 5px 10px;
  display: block;
  margin: 0 auto;
  text-decoration: none;
}

a.anchor-button:hover {
  background: $white;
  color: $black;
  text-decoration: none;
}

.button-list{
  list-style: none;
}

.back-anchor{
  font-size: 10.5pt;
  font-weight: 300;
  color: $white;
  margin-top: 10px;
}

a.back-anchor i {
  padding-right: 5px;
}

/* Off-Canvas label format */
.sidebar-offcanvas .off-canvas-divs {
  min-width: 100%;
}
.sidebar-offcanvas .row-educ-level {
  display: inline-block;
  margin-right: 15px;
}
.sidebar-offcanvas .row-educ-level .tip-button { // off-canvas vendor profile tip-button margin
  margin-bottom: 15px !important;
}
.sidebar-offcanvas .div-educ-level {
  display: initial;
}
.sidebar-offcanvas .row-phone {
  display: -webkit-inline-box;
}
.company-info .off-canvas-divs {
  text-align: left;
}
.company-info .off-canvas-divs label {
  font-size: 12pt;
  text-transform: capitalize;
}
/* remove extra padding */
.form-element.off-canvas-divs {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* remove padding on off-canvas change password and image list */
ul.button-list.user-profile-btn {
  padding: 0;
}

/* remove left-right padding - offcanvas save and cancel */
.off-canvas-btn .btn{
  padding-left: 0;
  padding-right: 0;
}

.off-canvas-btn {
  margin-top: 40px;
}

.div-profile-image { /* off canvas settings profile image */
  margin-top: 100px;
}

//------------- off-canvas toggle image & buttons ------------------//

.toggle-image .toggle-close {
  margin-left: 50px !important;
  background: transparent !important;
  color: $white !important;
  border: none;
  font-size: 22.5pt;
  font-weight: 700;
}

button.toggle-close {
  transition: all .2s ease-in-out;
  outline: 0;
  margin-top: -20px;
  float: right;
}

/* off canvas hover */
button.btn.btn-outline-secondary.toggle-image:hover button.toggle-close {
  background: transparent !important;
  box-shadow: none !important;
  outline: 0;
  transition: none !important;
}

.sidebar-offcanvas {
  position: absolute;
  top: 0;
  background: linear-gradient(180deg, #3EB89C 0%, #11332B 100%) !important;
  font-size: 18pt;
  text-align: center;
  color: $white;
  height: 100%;
  width: 25%; /* 6 columns */
  z-index: 9999;
}

.offcanvas-toggle-image .toggle-image {
  background-size: 65px !important;
  background-position-y: 10px;
  margin-top: -80px !important;
}

.navbar .dropdown-menu {
  margin-top: 60px;
}

.lg-search-bar {
  margin: -50px 0 0 70px;
}

button.btn.btn-outline-secondary.toggle-image {
  background-size: 50px !important;
}

.offcanvas-toggle-image.dropdown {
  height: 57px;
}

button.btn.btn-outline-secondary.toggle-image {
  background-repeat: no-repeat;
  height: 80px;
  border: none;
  position: fixed;
  z-index: 4 !important;
  margin-top: -20px !important;
}

.right-nav-items {
  margin-top: 22px;
  width: 100%;
  float: right;
  margin-left: 40px;
}

.row.right-nav-items img {
  width: 25px;
  margin-top: 10px;
}

span.messages-nav,
span.alert-nav {
  font-family: $roboto;
  color: $white;
  position: absolute;
  text-align: center;
  display: inline-block;
  min-width: 10px;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 13px;
  text-shadow: none;
  line-height: 11px;
  background-color: #f95372;
  top: 3px;
  left: 10px;
  font-size: 11px;
}

.message-pop-circle {
  border: 1px solid #f95372;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  position: absolute;
  top: -10px; //
  left: -5px;
  -webkit-animation: pulsate 8s ease-out;
  animation: pulsate 8s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
}

.alert-pop-circle {
  border: 1px solid #00abff;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  position: absolute;
  top: -18px;
  left: 2px;
  -webkit-animation: pulsate 8s ease-out;
  animation: pulsate 8s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
}

@-webkit-keyframes pulsate {
  30% {-webkit-transform: scale(.1)}
  35% {opacity: 1.0;}
  40% {-webkit-transform: scale(1.2); opacity: 0.0;}
}

span.messages-nav {
  background: #c52d4a;
}
span.alert-nav {
  background: #1f94ce;
}

button.btn.btn-outline-secondary.toggle-image:focus { /* toggle-image remove outline */
  outline: none !important;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button.btn.btn-outline-secondary.toggle-image:hover { /* remove background on hover */
  background-color: transparent;
}

// dropdown menu
.right-nav-menu {
  left: initial !important;
  margin-top: -10px;
  padding-bottom: 0;
  top: 0;
  position: fixed;
  width: 300px;
  box-shadow: 0 10px 20px rgba(160,160,160,.5);
}

.show > .dropdown-menu.right-nav-menu {
  width: 230px !important;
  transform: none !important;
  top: 75px !important;
  left: -30px !important;
  border-radius: 0 0 5px 5px;
}

@media (max-width: 630px) {

  .show > .dropdown-menu.right-nav-menu {
    top: 20px !important;
  }
}

.profile-img {
  width: 50px;
  float: left;
  padding: 0 10px;
}

.prof-name {
  color: $green;
  font-weight: 500;
  padding-left: 10px;
}

.prof-top-name {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  width: 180px;
  padding: 5px 30px 0 55px;
  color: $white;
  top: 25px;
  left: 0;
  z-index: 2;
}

.profile-img.temp-icon {
  width: 50px;
  height: 50px;
  float: left;
  padding: 0 10px;
  line-height: 50px;
}

.profile-toggler {
  position: absolute;
  left: 80px;
  top: 30px;
  font-size: 12pt;
}

.prof-email {
  color: $green;
  padding-left: 10px;
}

.offcanvas-toggle-image:hover .right-nav-menu {
  display: block;
}

.right-nav-menu {
  display: none;
}

li.separator {
  border-bottom: 1px solid $table-gray;
  margin: 5px 7px 6px;
  padding-top: 1px;
}

.right-nav-menu li.nav-item {
  padding: 0 10px;
}

.right-nav-menu li.nav-item:hover {
  margin-left: 0;
}

.right-nav-menu li.nav-item a {
  color: #000;
  text-decoration: none;
  display: inline-block;
  width: 100%;
}

.right-nav-menu li.nav-item a.disabled,
.right-nav-menu li.nav-item:hover a.disabled{
  color: #cecece;
}

.right-nav-menu li.nav-item:hover a {
  color: $green;
}

.right-nav-menu .nav-item-span {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  height: 25px;
}
