@import "font-style.scss";
@import "offcanvas.scss";
@import "custom_variables.scss";
@import "sidebar.scss";
@import "app-sidenav.scss";
@import "responsive.scss";
@import "invoices.scss";
@import "estimates.scss";
@import "tasks.scss";
@import "projects.scss";
@import "shared.scss";
@import "dashboard.scss";
@import "settings.scss";
@import "billing.scss";
@import "vendor.scss";
@import "application-preferences.scss";
@import "system-configuration.scss";
@import "onboarding.scss";
@import "forecast.scss";
@import "forms.global.scss";
@import "status.scss";
@import "card.scss";
@import "icons.scss";
@import "buttons.scss";
@import "user-initials-color.scss";
@import "messaging.scss";

// Module specific global overrides
@import "../../src/app/shared/data-filter/header-filters/header-filters.global";
@import "../../src/app/shared/components/task-action/task-action-split-task/task-action-split-task.component.global";
@import "../../src/app/shared/components/task-action/task-action.component.global";
@import "../../src/app/shared/data-filter/check-list/check-list.component.global";
@import "../../src/app/shared/components/service-request/service-info/service-info.component.global";
@import "../app/projects/details/my-tasks/task-item/task-item.component.global";
@import "../app/projects/details/project-finances/components/create-additional-invoice/create-additional-invoice.component.global.scss";
@import "../app/settings-v2/settings.component.global";
@import "../app/estimate/estimate.component.global";
@import "../app/onboarding/onboarding.component.global";
@import "../app/shared/components/trimmed-text/trimmed-text.component.global";
@import "../app/finances/estimate/approved-work.component.global";

// Settings-v2 controls that need to be used on a global-scope.
// This is a temporary fix, we will need to properly address this in the future.
@import "../app/settings-v2/components/input-country-dropdown/input-country-dropdown.component.global";

html, body {
  background: $background-body;
  font-size: $font-size-default;
  font-family: $font-family-default;
  font-weight: $font-weight-default;
  color: $gray-dark;
}

main {
  padding-bottom: $padding-bottom-main
}

.container-fluid {
  height: initial !important;
  min-height: 700px;
}

// order header
h2 {
  color: $green;
}

.text-body-2 {
  color: $gray-dark; // This should always match what is set on the html, body above.
}

// buttons
.btn-cta-primary,
button.btn.btn-primary.btn-cta-primary:active { // Save 'green' buttton
  margin: $margin-btn;
  padding: $padding-btn;
  width: $width-btn;
  height: $height-btn;
  background: $btn-cta-primary;
  border-radius: $border-radius-30;
  border: $border-btn-cta-primary;
  text-transform: $uppercase;
  font-size: $font-size-btn;
  text-align: $center;
  line-height: $line-height-25;
  letter-spacing: $letter-spacing-1;
  color: $color-btn-cta-primary;
}

.btn-cta-primary:active,
.btn-cta-primary-solo:active {
  background: $btn-cta-primary;
  border: $border-btn-cta-primary;
  color: $color-btn-cta-primary;
}

.btn-cta-primary:hover,
.btn-cta-primary-solo:hover {
  opacity: $opacity;
  border: $border-btn-cta-primary;
}

// disabled primary button
.btn-primary.disabled, .btn-primary:disabled {
  border: $border-btn-cta-primary;
  background: $btn-cta-primary;
  opacity: 0.65;
  color: $white;
}

.btn-save {
  text-align: $center;
}

.empty-button {
  cursor: pointer;
  color: $table-gray;
  font-size: 14px !important;
  background: transparent;
  border: none !important;
  opacity: .5;
}

.empty-button:hover {
  color: $table-gray;
  background: transparent;
  border: none;
  opacity: 1;
  box-shadow: none !important;
}

.empty-button:active, .empty-button:focus {
  color: $green;
  background: transparent;
  border: none;
  opacity: 1;
  box-shadow: none !important;
}

.ui-cell-data .dropdown-btn .fa {
  font-size: 13px;
}

.btn-cta-secondary, // cancel button
label.input-uploader,
input.input-uploader + label, /* uploader button global setting */
input.input-uploader {
  margin: $margin-btn;
  padding: $padding-btn;
  width: $width-auto;
  min-width: $width-full;
  height: $height-btn;
  text-transform: $uppercase;
  border-radius: $border-radius-30;
  font-size: $font-size-btn;
  font-weight: $font-weight-default;
  text-align: $center;
  line-height: $line-height-25;
  letter-spacing: $letter-spacing-1;
  background-color: $white;
  color: #747474 !important;
  border: solid 1px #9b9b9b;
}

.btn-cta-primary-solo {
  margin: $margin-btn;
  padding: $padding-btn-solo;
  width: $width-btn-solo;
  height: $height-btn;
  background: $btn-cta-primary;
  border-radius: $border-radius-30;
  border: $border-btn-cta-primary;
  text-transform: $uppercase;
  font-size: $font-size-btn;
  text-align: $center;
  line-height: $line-height-25;
  letter-spacing: $letter-spacing-1;
  color: $color-btn-cta-primary;
}

.btn-cta-secondary-solo {
  margin: $margin-btn;
  padding: $padding-btn-solo;
  width: $width-btn-solo;
  height: $height-btn;
  text-transform: $uppercase;
  border-radius: $border-radius-30;
  font-size: $font-size-btn;
  font-weight: $font-weight-default;
  text-align: $center;
  line-height: $line-height-25;
  letter-spacing: $letter-spacing-1;
  background-color: $white;
  color: #747474 !important;
  border: solid 1px #9b9b9b;
  box-shadow: none !important;
  outline: none !important;
}

.button-3 {
  color: $green !important;
  border: $border-btn-cta-primary;
}

.btn-ns:hover,
.btn-cta-secondary:hover,
.btn-cta-secondary-solo:hover,
label.input-uploader:hover,
input.input-uploader + label:hover {
  background-color: $gray-light !important;
  border: $border-btn-cta-secondary;
  color: $green !important;
}

// turnaround time primary button
.order-btns, .order-par {
  margin-left: $margin-left-25;
  margin-top: 40px;
}

.btn-cta-secondary.delivery-btn { // order page 'add another' button
  margin: $margin-40 !important;
}

.btn-secondary {
  background-color: $white;
  color: #333333;
}

.btn-secondary:hover {
  color: #fff;
  background-color: $green;
  border-color: #6c757d;
}

// small button - dashboard & messages

.cta-primary-small {
  background-color: $green;
  border: 1px solid $green;
  color: $white;
  font-size: 12px;
  min-width: 100px;
  box-shadow: none;
  outline: none;
}

.cta-primary-small:hover {
  opacity: 0.7;
}

.cta-secondary-small {
  background-color: $white;
  border: 1px solid $gray-dark;
  color: $gray-dark;
  font-size: 12px;
  min-width: 100px;
  box-shadow: none !important;
  outline: none !important;
}

.cta-secondary-small:hover {
  color: $green;
  border-color: $green;
}

// ------------------------------------//

.btn-container {
  float: right;
}

.btn-container .btn-townip {
  font-size: 12pt;
}

.btn-townip {
  box-shadow: none !important;
  outline: none !important;
}

.form-delivery {
  margin-top: $margin-top-20;
  margin-left: $input-margin-left;
}

.hide-item {
  display: none;
}

.no-border {
  border: none !important;
}

.light-border {
  border: 1px solid $gray-light;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.padding-0 {
  padding: 0;
}

.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}

.padding-15 {
  padding: 15px;
}

.padding-20 {
  padding: 20px;
}

.padding-25 {
  padding: 25px;
}

.padding-30 {
  padding: 30px;
}

.pad-left-card {
  padding-left: 75px;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-25 {
  padding-left: 25px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-left-45 {
  padding-left: 45px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-15 {
  padding-right: 15px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-25 {
  padding-right: 25px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-right-90 {
  padding-right: 90px;
}

.padding-right-100 {
  padding-right: 100px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.margin-40 {
  margin: 40px;
}

.margin-left-5 {
  margin-left: $margin-left-5;
}

.margin-left-40 {
  margin-left: $margin-left-40;
}

.margin-left-45 {
  margin-left: $margin-left-45;
}

.margin-left-20 {
  margin-left: $margin-left-20;
}

.margin-left-25 {
  margin-left: $margin-left-25;
}

.margin-left-15 {
  margin-left: $margin-left-15;
}

.margin-left-10 {
  margin-left: $margin-left-10;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-top-20 {
  margin-top: $margin-top-20;
}

.margin-top-25 {
  margin-top: $margin-top-25;
}

.margin-top-30 {
  margin-top: $margin-top-30;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-15 {
  margin-top: $margin-top-15;
}

.margin-top-5 {
  margin-top: $margin-top-5;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-25 {
  margin-right: 25px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-bottom-5 {
  margin-bottom: $margin-bottom-5;
}

.margin-bottom-10 {
  margin-bottom: $margin-bottom-10;
}

.margin-bottom-15 {
  margin-bottom: $margin-bottom-15;
}

.margin-bottom-20 {
  margin-bottom: $margin-bottom-20;
}

.margin-bottom-25 {
  margin-bottom: $margin-bottom-25;
}

.margin-bottom-30 {
  margin-bottom: $margin-bottom-30;
}

.margin-bottom-40 {
  margin-bottom: $margin-bottom-40;
}

.margin-bottom-150 {
  margin-bottom: 150px;
}

.no-margin {
  margin: 0 !important;
}

.margin-center {
  margin: 0 auto;
}

.right-align {
  text-align: $right-align;
}

.center-align {
  text-align: center;
}

// width
.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.auto-width {
  width: auto;
  white-space: nowrap;
}

.w-0 {
  width: 0;
}

// fa close button
i.fa.fa-minus {
  color: #C0392B;
}

.remove-btn:hover {
  background: #C0392B;
}

.remove-btn:hover i.fa.fa-minus {
  color: $white;
}

button.btn.btn-outline-secondary.remove-btn {
  border: none;
  color: #C0392B;
}

/* flag */
/* flag close buttons */

// buttons
.btn-group.tip-button-group {
  width: $full-width;
}

.modal-button {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.ui-select-match { // select input
  width: $full-width;
}

.modal-dialog.modal-lg h2 { // modal header
  color: $gray-dark;
}

.modal-email {
  color: $email-text;
}

// Removed this @ 5.0 because it conflicts with modal contents
//.modal-body div {
//  line-height: $line-height-sm;
//}

.modal-details {
  margin-top: $modal-container-margin;
}

.project-button-section {
  margin-top: $margin-top-20;
  margin-bottom: $margin-bottom-20;
}

.project-button-section.validation-country button {
  margin-left: 0 !important;
}

input.checkbox-project {
  vertical-align: $vertical-align-middle;
}

.modal-project-button {
  margin: $margin-default;
}

.validation-country .tip-button {
  margin: 0;
}

.dropdown-padding .form-input {
  margin-left: 45px;
}

span.ui-select-match { // fixed application preference input fields height */
  margin-left: 0 !important;
}

// ================  pagination ==================//

.page-item.active .page-link,
.page-item .page-link {
  background: transparent;
  border: none;
}

.page-item .page-link {
  color: #9B9B9B;
}

.page-item.active .page-link {
  color: $green;
}

// settings sort by button
.sort-by .dropdown-toggle::after {
  float: right;
  margin-top: 5px;
  font-size: 22.5pt;
}

// change dropdown menu background
.ui-select-choices-row.active > a,
a.dropdown-item:hover,
a.dropdown-item.active{
  background-color: transparent !important;
  cursor: default;
  color: $green !important;
  transition: color 280ms;
}

// vendor row
.vendor-row {
  background-color: $vendor-row-bg;
  padding: 8px 0;
  margin-bottom: 5px;
  margin-left: 25px;
}

.vendor-section .form-group input {
  height: 35px;
}

.row.form-drop-d.vendor-row.col-div {
  margin-left: 15px !important;
  padding-left: 5px;
}

// onboard services row header
.div-header-container {
  display: inherit;
  padding: 30px;
}

.div-header-container h4 {
  padding: 0 30px;
}

// vendor column title
.settings-card-title {
  color: #9B9B9B !important;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 7.5pt;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.pad-left-title {
  padding-left: 55px !important;
}

// vendor create account button
.btn-create {
  margin-left: 15px !important;
}

.terms {
  margin-left: 20px !important;
  margin-top: -10px;
  font-size: 9pt;
  font-weight: 300;
  color: #9b9b9b;
}

button.btn.btn-outline-secondary.toggle-image {
  z-index: 3;
}

/* footer section formatting */
footer {
  color: $gray-dark !important;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 200px;
  min-height: 115px;
}

/* fix the input search height issue on focus */
input.form-control.ui-select-search {
  line-height: 2.5 !important;
}

.navbar {
  position: fixed;
  background: rgb(66, 189, 161);
  /*
  NOTE ON z-index:
  Do not set on anything lower than 1030.
  This was adjusted so that our controls
  (dropdown, autocomplete, calendar etc. bootstrap, prime, etc) do not overlap higher
  than the top menu. The side nav menu has a z-index of 1031 to make sure it is always
  higher than the top menu.
  */
  // z-index: 1030; // Move to #townip-top-menu
  width: 100%;
  /*left: 0;*/
  top: 0;
}

.navbar-div {
  text-align: center;
  max-width: 100px;
  margin-right: 60px !important;
}

.navbar-title {
  font-weight: 300;
  color: $white;
  font-size: 9pt;
  margin-top: -35px;
  text-align: center;
  display: block;
}

.navbar-light .navbar-brand, .navbar-light .navbar-toggler {
  color: $white;
}

.navbar a.nav-link {
  color: $blue-accent;
  font-size: 7pt;
}


.navbar a.nav-link:hover {
  color: $white;
}

.navbar-light .navbar-toggler {
  color: $white;
  background: $white;
  left: 75%;
  margin-top: 20px;
  margin-left: 0;
}

button.navbar-toggler.navbar-toggler-right {
  position: relative;
}

// header search bar
input.search-box-navbar {
  border-radius: 3px;
  color: $white;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #39a48c;
  border: 0;
  padding: 12px 20px 12px 60px;
  max-width: 555px;
  width: 100%;
  height: 44px;
  font-weight: 300;
  font-size: 10pt;
  outline: none;
  box-shadow: $default-shadow;
}

/* .nav-box {
  height: 100%;
  line-height: 25px;
  margin-top: -55px;
  margin-left: 60px;
} */

.nav-box {
  height: 100%;
  line-height: 0;
  margin-top: 15px;
  margin-left: 0;
  width: 100%;
}

.nav-box span.fa-search-input {
  margin-top: 10px;
  font-family: roboto;
  color: #9b9b9b;
}

.toolbar-search {
  display: grid;
  margin-left: 20px;
  margin-top: -35px !important;
  font-size: 18pt !important;
  color: $white;
  width: 20px;
}

input.search-box-navbar::-webkit-input-placeholder {
  color: white;
}

input.search-box-navbar::-moz-placeholder {
  color: white;
}

/*
input.search-box-navbar::-ms-placeholder {
  color: white;
}
input.search-box-navbar::placeholder {
  color: white;
}
​*/

.div-container {
  // margin: 50px 0 0 50px;
  padding: 10px;
  min-height: 800px;
}

.full-height {
  height: 100%;
}

.absolute {
  position: $absolute;
}

.top-border {
  border-top: 1px solid #9b9b9b;
}

i.fa.fa-asterisk { // required
  font-size: 7.5pt;
}

.admin-icon.fa-certificate {
  margin-right: 5px;
  color: $green;
}

.uppercase {
  text-transform: $uppercase;
}

// New button styles based on the updated design
.btn-townip {
  font-family: $font-family-default;
  font-size: 9pt;
  padding: 5px 25px;
  border-radius: 20px;
  text-transform: uppercase;
}

.btn-townip:hover {
  cursor: pointer;
}

.btn-townip-disabled:hover {
  cursor: not-allowed;
}

.btn-townip:active {
  border-radius: 20px;
}

.btn-townip.btn-townip-primary {
  background-color: $white;
  border: 1px solid $gray-dark;
  color: $gray-dark;
}

.btn-townip.btn-townip-primary:hover {
  border: 1px solid $green;
  color: $green;
}

.btn-townip.btn-townip-secondary {
  background-color: $green;
  border: 1px solid $green;
  color: $white;
}

.btn-townip.btn-townip-secondary:hover {
  //background-color: $green;
}

.btn-townip.btn-townip-fluid {
  width: 100%;
}

// Circular Responsive Images
.img-townip-responsive-profile-image {
  width: 100% !important;
  max-width: 40px;
  border-radius: 100%;
}

// --------------------- TownIP Common Forms ---------------------
// Append the "townip-form-common" class to a form container
// ---------------------------------------------------------------
.form-element label {
  font-family: $font-family-default;
  font-size: 10pt !important;
  font-weight: 300;
  text-align: left;
  color: #9B9B9B;
  text-transform: uppercase !important;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.form-label {
  font-family: $font-family-default;
  font-size: 10pt;
  font-weight: 300;
  text-align: left;
  color: #9B9B9B !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.townip-form-common .form-error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2.5rem;
}

.townip-form-common .form-error-container.phone-numbers {
  padding-top: 4rem;
}

.townip-form-common .form-error,
.townip-form-common .form-success {
  margin-top: 0;
}

.townip-form-common .form-element.has-error input {
  border: 1px solid #C0392B;
}

.townip-form-common .form-element.has-error label {
  color: #C0392B;
}

.townip-form-common .form-element .form-success,
.townip-form-common .form-element .form-error {
  position: absolute;
  right: 2.0rem;
  top: 3.5rem;
}

.townip-form-common .btn-phone-numbers {
  position: absolute;
  bottom: 0.8rem;
  right: 1.5rem;
}

.townip-form-common .input-phone-number {
  padding-right: 5rem;
}

.townip-form-common .tooltip-inner {
  border: 1px solid #AD0D00;
  color: #AD0D00;
  max-width: 300px;
  font-weight: 500;
}

.townip-form-common .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before,
.townip-form-common .tooltip.tooltip-top .tooltip-inner::before {
  border-top-color: #AD0D00;
}

.ui-widget,
.ui-widget .ui-widget,
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: $font-family-default;
}

// Overriding the default font for PrimeFaces/Prime UI so we don't have to override per component
body .ui-widget, body .ui-widget .ui-widget, body .ui-widget input, body .ui-widget select, body .ui-widget textarea, body .ui-widget button {
  font-family: $font-family-default;
}

// Overriding bootstrap selected table border and background color
body .ui-datatable .ui-datatable-data tr.ui-datatable-even.ui-state-highlight,
body .ui-datatable .ui-datatable-data tr.ui-datatable-odd.ui-state-highlight {
  border: transparent;
  background: transparent;
}


// Bootstrap Tabs Override for Detail-View Tabs
.townip-detail-nav-tabs .nav-link {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  color: #9b9b9b;

}

.townip-detail-nav-tabs .nav-tabs .nav-link:hover {
  background-color: transparent !important; //test
}

.townip-detail-nav-tabs .nav-tabs .nav-link {
  border-width: 0 0 3px 0;
  padding: 1rem;
}

.townip-detail-nav-tabs .nav-tabs .nav-link.active,
.townip-detail-nav-tabs .nav-tabs .nav-link:hover,
.townip-detail-nav-tabs .nav-tabs .nav-link:active,
.townip-detail-nav-tabs .nav-tabs .nav-link:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: $green;
  border-bottom-width: 3px;
  background-color: transparent !important;
}

.nav-link.active .side-nav-svg path,
.nav-link.active .side-nav-svg polyline,
.nav-link:hover .side-nav-svg path,
.nav-link:hover .side-nav-svg polyline{
  stroke: white !important;
  -webkit-transition: all .5s ease-out;
  transition: all .5s ease-out;
}

.nav-link .side-nav-svg path.active-fill-white,
.nav-link .side-nav-svg g.active-fill-white,
.nav-link .side-nav-svg path.active-fill-white,
.nav-link .side-nav-svg g.active-fill-white {
  fill: $blue-accent;
}

.nav-link:hover .side-nav-svg path.active-fill-white,
.nav-link:hover .side-nav-svg g.active-fill-white,
.nav-link.active .side-nav-svg path.active-fill-white,
.nav-link.active .side-nav-svg g.active-fill-white {
  fill: white !important;
}

.townip-detail-nav-tabs-content {
  padding-top: 25px;
  background-color: #f8fbfb;
}

.townip-auto-margins {
  margin-left: auto;
  margin-right: auto;
}

.townip-text-blue {
  color: $blue;
}

.townip-text-green {
  color: $green;
}

.townip-text-green2 {
  color: $green2;
}

dt {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: normal;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

hr {
  width: 100%;
}

.fa-button {
  cursor: pointer;
}

.flex-grow {
  flex-grow: 1;
}

.flex-nogrow {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-noshrink {
  flex-shrink: 0;
}

.height-inherit {
  height: inherit;
}

.block-panel-header {
  font-size: 1.25rem;
  color: $green;
  font-weight: 400;
  line-height: 1.1;
  margin: 0;
}

.block-panel-button {
  font-size: 1.8rem;
  color: $green;
}

// Adjusting the color prime color picker style for task calendar
townip-task-calendar .ui-colorpicker-preview {
  height: 25px;
}

// Messaging Control Styles Override
townip-messages .filter-dropdown.ui-dropdown {
  width: 100%;
}

townip-message-thread .message-thread .ql-editor {
  overflow-x: hidden !important;
}

townip-language-settings .card-body,
townip-country-settings .card-body {
  padding: 0 !important;
}

// User-chips override
townip-user-chips .dropdown {
  padding: 0px 10px 10px 10px
}

townip-user-chips .dropdown-item {
  padding: 0;
  line-height: 13px;
}

townip-user-chips .dropdown-item.active {
  background-color: transparent;
  color: #212529;
}

townip-user-chips .dropdown-item.active .user-name {
  color: #FFFFFF;
}

// Making sure the custom toast messages, especially with those messageBody that comes from the p-editor,
// Will not have a bottom margin
.customtoast .toast-message *:last-child {
  margin-bottom: 0;
}

.toast-message a {
  color: #01B2A9 !important;
}

/* firefox browser styling */
@-moz-document url-prefix() {
  .side-menu .navbar {
    overflow-y: inherit;
  }

  .side-menu-container::-webkit-scrollbar {
    width: 0;
  }

  .div-container .sort-by .dropdown-toggle::after {
    margin-top: -12px !important;
  }

  .side-menu .navbar {
    overflow-x: hidden;
  }

  /* vendor settings */
  .settings-parent-container .accordion-heading-div .fa.pull-left {
    margin-top: -20px;
  }

  .settings-parent-container .accordion-heading-div .flag-btn-img {
    margin-left: 20px;
  }

  /* vendor tip clear button */
  .vendor-section townip-onboard-vendor-services .tip-clear-button {
    margin-top: -28px;
  }

  townip-dashboard-header .todo-entries {
    overflow-x: hidden !important;
  }

  .brand-img {
    margin-bottom: 0 !important;
  }
}

/* For Task, Project, Estimates Overview Pages */
townip-vendor-billing,
townip-projects-overview,
townip-estimates-overview,
townip-invoices-overview,
townip-task-bulk-assign {
  min-width: 1200px;
  display: block;
  margin: 0;
  background-color: #F9FAFA;
}

townip-estimates-overview {
  margin: 0;
}

.overview-pages-header {
  background-color: $white;
  padding: 10px 15px;
}

.overview-pages-header h1 {
  color: $green;
  font-size: 1.15rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
}

.overview-pages-header h1 .sub-header {
  font-weight: normal;
  text-transform: initial;
  color: darkgray;
}

.overview-pages-header .overview-status .btn-ov-stat {
  font-size: 0.9rem;
  border-color: #E1E5E6;
  color: #E1E5E6; // Test
}

.overview-pages-header .overview-status .btn-ov-stat:hover {
  background-color: #01B2A9;
  border-color: $green;
}

.overview-pages-header .overview-status .btn-ov-stat.active,
.overview-pages-header .overview-status .btn-ov-stat:active {
  background-color: #E9FFFE;
  border-color: $green;
  color: $green;
}

.overview-filter-divider {
  margin-top: 0;
  margin-bottom: 0;
  border-color: #CCCCCC;
}

.overview-btns-container {
  padding: 0 5px 18px;
  background: #F8FBFB;
}

/* End - For Task, Project, Estimates Overview Pages */

.client-info-tooltip .tooltip-inner {
  background-color: #FFFFFF;
  border: none;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  max-width: 375px;
}

.partial-tooltip .tooltip-inner {
  text-align: left;
  padding: 0;
}

.legal-translation-progress-tooltip.tooltip.show,
.client-info-tooltip.tooltip.show {
  opacity: 1;
}

.client-info-tooltip.tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #FFFFFF;
}

.client-info-tooltip.supplemental-email {
  min-width: 200px;
}

.toolbar-tooltip .tooltip-inner {
  background-color: rgba(0,0,0,0.8);
  min-width: 100px;
  color: white;
  font-size: 0.9rem;
}

.toolbar-tooltip .tooltip-arrow {
  display: none;
}

.form-control.custom-control {
  padding: 0;
  border: none;
  display: block;
}

.hide-selection .ui-chkbox {
  display: none;
}

.light-bg {
  background-color: #fafafa;
}

.light-bg-accent {
  background-color: #F8FBFB;
}

.default-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.custom-container {
  width: 1200px;
}

@media screen and (min-width: 1450px) {
  .custom-container {
    margin-left: 100px;
  }
}

@media screen and (min-width: 1800px) {
  .custom-container {
    margin-left: 200px;
  }
}

@media screen and (min-width: 2100px) {
  .custom-container {
    margin-left: 300px;
  }
}

@media screen and (min-width: 2800px) {
  .custom-container {
    margin-left: 400px;
  }
}


@media screen and (min-width: 3500px) {
  .custom-container {
    margin-left: 500px;
  }
}

@media screen and (min-width: 4000px) {
  .custom-container {
    margin-left: 600px;
  }
}

// The new table filter button for the filter toggle
.btn.btn-link.table-filter-toggle {
  font-size: 30px;
}

// NOTE: Fill content height is used if you want a full height without the toolbar
.fill-content-height {
  height: calc(100vh - #{$toolbar-height})
}

// --- CUSTOM badges (COLOR ONLY) -----
.badge-annuity-for-review {
  background-color: #004085;
  color: white;
}
