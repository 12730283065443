.ui-fieldset, 
.ui-fieldset .ui-fieldset-legend {
    padding: 0.5em 1em;
}

.ui-fieldset-toggleable .ui-fieldset-legend {
    padding: 0;
}

.ui-fieldset-toggleable .ui-fieldset-legend a {
    padding: 0.5em 1em;
    cursor:pointer;
    white-space: nowrap;
    display: block;
}

.ui-fieldset .ui-fieldset-toggler {
    margin-right: .1em;
    display: inline-block;
    vertical-align: middle;
}

.ui-fieldset .ui-fieldset-legend-text {
    vertical-align: middle;
}

.ui-fieldset .ui-fieldset-content-wrapper-overflown {
    overflow: hidden;
} 