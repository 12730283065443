// Messaging Scope Styling
.ui-widget button.btn-reply-scope {
  font-size: 10px;
}

.btn-reply-scope {
  border-radius: 2px;
  font-weight: 300;
  padding: 3px 9px 2px;
  color: #FFFFFF;
  font-size: 10px;
}

.btn-reply-scope:hover {
  color: #FFFFFF;
}

.btn-reply-scope.ESTIMATE {
  background-color: #498776;
}

.btn-reply-scope.PROJECT {
  background-color: #25483D;
}

.btn-reply-scope.TASK {
  background-color: #173368;
}

.btn-reply-scope.INVOICE {
  background-color: #9A9898;
}

.message-content .delivery-schedule-table {
  border: 2px solid black;
  border-collapse: collapse;
  font-size: 12px;
  min-width: 50%;
}

.message-content .delivery-schedule-table tr {
  border: 1px solid black;
  font-size: 12px;
}

.message-content .delivery-schedule-table th {
  padding: 5px;
  text-align: left;
  border: 1px solid black;
  font-size: 12px;
}

.message-content .delivery-schedule-table td {
  padding: 5px;
  text-align: left;
  border: 1px solid black;
  font-size: 12px;
}
