@import "../custom_variables";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.advanced-datepicker .form-control {
  border: $input-border-width solid $input-border-color;
  height: 40px !important;
}

.advanced-datepicker .form-control:focus {
  @include form-control-focus();
}

// -- For adv date range + standalone + table
.advanced-datepicker.adv-date-range-left .ui-inputgroup > input.ui-inputtext,
.standard-table.ui-table .ui-table-thead > tr > th .advanced-datepicker.adv-date-range-left .ui-inputgroup > input.ui-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
// -- For adv date range + standalone + table
.advanced-datepicker.adv-date-range-right .ui-inputgroup > input.ui-inputtext,
.standard-table.ui-table .ui-table-thead > tr > th .advanced-datepicker.adv-date-range-right .ui-inputgroup > input.ui-inputtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-color: #efefef;
}

.advanced-datepicker.ng-invalid.ng-touched .form-control {
  border-color: $danger;
  box-shadow: 0 0 0 $input-focus-width rgba($danger, .25);
}

townip-advanced-datepicker.ng-invalid.ng-touched .advanced-datepicker .form-control,
townip-advanced-datepicker.ng-invalid.ng-touched .advanced-datepicker .form-control.ui-inputtext:enabled:hover:not(.ui-state-error),
townip-advanced-datepicker.ng-invalid.ng-touched .advanced-datepicker > .ui-inputgroup .ui-inputtext,
townip-advanced-datepicker.ng-invalid.ng-touched .advanced-datepicker > .ui-inputgroup .ui-inputgroup-addon {
  border-color: $danger;
}

townip-advanced-datepicker.ng-invalid.ng-touched .advanced-datepicker .form-control.ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-color: $danger;
  box-shadow: 0 0 0 $input-focus-width rgba($danger, .25);
}
