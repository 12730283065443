@import "./color-sequence";

@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;

}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

$primary:  #00B1A8 !default; //Townip default green color


// For overriding bootstrap theme colors
$theme-colors: (
  primary: $primary
);


@mixin _assert-starts-at-zero($map) {
  $values: map-values($map);
  $first-value: nth($values, 1);
  @if $first-value != 0 {
    @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  }
}

// Townip variable structure
// colors
$white:  #fff !default;
$black:  #000 !default;
$green:  map-get($theme-colors, "primary") !default; //Townip default green color
$green2: #308E78;
$green-light: #f8fbfb;
$green-accent: #CDECEB;
$gray-nav: #F6F6F6;
$gray-muted:                #9B9B9B;
$gray-prime:                #d6d6d6;
$gray-dark:                 #4A4A4A !default;
$gray:                      #464a4c !default;
$table-gray:                #C3C3C3 !default;
$filter-gray:               #E2E4E7 !default;
$gray-light:                #eeeeee !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;
$gray-lightest2:            #f9fafa !default;
$gray-status:               #cccccc;
$email-text:                #9B9B9B;
$red-text:                  #f44337;
$blue-accent:               #34495e;
$blue:                      #5D8BC2;

// user initials color
$color-a: #B8E986;
$color-b: #B979F1;
$color-c: #F0C39B;
$color-d: #F8E71C;
$color-e: #8B572A;
$color-f: #01B2A9;
$color-g: #98E8E1;
$color-h: #9B9B9B;
$color-i: #4A90E2;
$color-j: #F5A623;
$color-k: #C65588;
$color-l: #50E3C2;
$color-m: #E96777;
$color-n: #7ED321;
$color-o: #65C38C;
$color-p: #72D1FF;
$color-q: #000000;
$color-r: #FF8DBD;
$color-s: #DE4A1A;
$color-t: #B4ADFF;
$color-u: #F5A623;
$color-v: #B979F1;
$color-w: #8B572A;
$color-x: #B8E986;
$color-y: #4A90E2;
$color-z: #F0C39B;

// ** Button colors //
$primary-normal:              $green;
$primary-hover:               #6CC9B3;
$primary-active:              $green;

$secondary-normal:               #C3C3C3;
$secondary-hover:                #B7B7B7;
$secondary-active:               #ADADAD;

$light-normal:               #f8f9fa;
$light-hover:                #e2e6ea;
$light-active:               #dae0e5;

$dark-normal:               #343a40;
$dark-hover:                #23272b;
$dark-active:               #1d2124;
// Button colors ** //

// font-weight
$display1-weight:     normal !default;
$display2-weight:     300 !default;
//line-height
//$line-height-lg:         (4 / 3) !default; // Deprecation from Angular 12, division by slash is not supported anymore
$line-height-lg:         1.3 !default;
$line-height-sm:         1.5 !default;

/* html & body */

$background-body: #F8FBFB; // Do not change this to white, Contact @dpili.
$font-size-default: 14px;
$font-family-default: Roboto, sans-serif;
$font-weight-default: normal;
$color-body: $gray-dark;
$overflow-x-hidden: hidden;  /* Prevent scroll on narrow devices */
$arvo: 'Arvo-Regular';
$roboto: $font-family-default;

// text alignment
$center: center;
$justify: justify;
$left: left;
$right: right;

$vertical-align-middle: middle;
$vertical-align-bottom: bottom;
$vertical-align-top: top;
$vertical-align-baseline: baseline;
$vertical-align-inherit: inherit;

/* primary & secondary buttons*/
$margin-btn: 20px 0;
$padding-btn: 0 40px;
$uppercase: uppercase;
$font-size-btn: 12pt;
$height-btn: 50px;
$width-btn: 100%;
$width-auto: auto;
$width-full: 100%;
$height-btn: 50px;
$letter-spacing-1: 1px;
$line-height-25: 25px;
$border-radius-20: 20px;
$border-radius-30: 30px;
$letter-spacing-1: 1px;
$font-weight-normal: normal;

// solo button
$padding-btn-solo: 0 25px;
$width-btn-solo: auto;

// vendor
$vendor-row-bg: #EAF6F3;

// Primary button variable assignment
$btn-cta-primary: $green;
$border-btn-cta-primary: 1px solid $green !default;
$color-btn-cta-primary: $white;
$opacity: 0.8; // primary button hover

// Secondary button variable assignment
$border-btn-cta-secondary: 1px solid $green;
$transparent: transparent;
$color-btn-cta-secondary: #9B9B9B;
$border-gray: 1px solid #747474;
$border-green: 1px solid $green;

// Text Alignment
$right-align: right;
$center-align: center;

// left margin variables
$margin-left-5: 5px;
$margin-left-10: 10px;
$margin-left-15: 15px;
$margin-left-20: 20px;
$margin-left-25: 25px;
$margin-left-40: 40px;
$margin-left-45: 45px;

// right margin variables
$margin-right-5: 5px;
$margin-right-10: 10px;
$margin-right-15: 15px;
$margin-right-20: 20px;
$margin-right-25: 25px;
$margin-right-40: 40px;
$margin-right-45: 45px;

$margin-top-5: 5px;
$margin-top-10: 10px;
$margin-top-15: 15px;
$margin-top-20: 20px;
$margin-top-25: 25px;
$margin-top-30: 30px;
$margin-top-35: 35px;
$margin-top-40: 40px;
$margin-top-45: 45px;

$margin-bottom-5: 5px;
$margin-bottom-10: 10px;
$margin-bottom-15: 15px;
$margin-bottom-20: 20px;
$margin-bottom-25: 25px;
$margin-bottom-30: 30px;
$margin-bottom-40: 40px;
$margin-default: 0 !important;

$margin-40: 40px;
// Links
// Style anchor elements.

$link-color:            $gray-dark !default;
$link-decoration:       none !default;
$link-hover-color:      $green !default;
$link-hover-decoration: underline !default;

// Spacings
$button-margin-top: 30px;
$input-margin-left: 45px;
$modal-container-margin: 40px;

$padding-right-input: 20px;
$padding-bottom-main: 240px;

$collapsed-margin: 60px;
$expanded-margin: 240px;
$neg-expanded-margin: -1*$expanded-margin;
$calc-width-expanded: calc(100% - 240px);
$calc-width-collapsed: calc(100% - 60px);
$toolbar-height: 64px;

// Positions
$absolute: absolute;
$relative: relative;

// width - height
$full-width: 100%;
// Grid breakpoints

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");

$block-panel-header-bg: #f8fffd;

// Status colors, gonna need to add the other standard colors
$status-overdue: red;
$status-needs-action: rgb(255, 160, 0);
$status-in-progress: #598BC9;
$status-on-hold:  rgb(255, 160, 0);
$status-cancelled: #EC99A1;
$status-pending: #598BC9;
$status-active: $green;
$status-on-track: rgb(46, 161, 82);
$status-completed: rgb(109, 125, 158);
$status-ready-to-start: $green;

/* Shadows */
$default-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
$hover-shadow: 0 3px 8px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.08);
$active-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.14), 0 0 5px 1px rgba(0, 0, 0, 0.12) !important;
$modal-shadow: 0 6px 15px 0 rgba(0,0,0,0.21);

/* Transitions */
$all-transition: all 280ms;

/* Icons */
$font-awesome: 'FontAwesome';
$chevron-down: '\f078';
$chevron-up: '\f077';
$fa-first: '\f100';
$fa-prev: '\f104';
$fa-next: '\f105';
$fa-last: '\f101';
$fa-search: '\f002';

/** Mixins */
@mixin transform($degs: 0deg) {
  -webkit-transform: rotate($degs);
  -moz-transform: rotate($degs);
  -o-transform: rotate($degs);
  -ms-transform: rotate($degs);
  transform: rotate($degs);
}

@mixin transition($element: all, $time: 280ms) {
  -moz-transition: $element $time;
  -o-transition: $element $time;
  -webkit-transition: $element $time;
  transition: $element $time;
}

@mixin trans-function {
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

@mixin max-height {
  height: 100%;
}

@mixin bs-input {
  border: 2px solid #cacaca;
  border-radius: 2px;
  padding: .8rem 1rem;
  line-height: 1.5;
  font-size: 1rem;
}

@mixin standard-form-error-style {
  border-color: $form-feedback-invalid-color;
  border-width: 2px;
}

// The height total height of the main nav-bar.
// Used to compute directive-free min-height values for full-page wrappers.
$townip-nav-height: 64px;

// Colors used for the vendor tags in assignment and cards
$vendor-system-default: $primary;
$vendor-client-default: #FD7E14;
$vendor-client-preferred: #DE4A1A;
$vendor-client-test-vendor: #868E96;
$vendor-client-vendor: #007BFF;
$vendor-client-vendor-preferred: #0033FF;
$vendor-client-vendor-default: #2200FF;

$enable-deprecation-messages: false;
$input-focus-border-color: $primary-hover;
$action-header-bg: #19586F;

// NOTE - It is necessary that these two are always below everything else
// This is because we are changing the default values above before bootstrap pre-processes the styles
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
