.ui-tabview {
    padding: .25em; 
}

.ui-tabview .ui-tabview-nav { 
    margin: 0;
}

.ui-tabview .ui-tabview-nav li { 
    list-style: none; 
    float: left; 
    position: relative; 
    margin: 0 .125em 1px 0;  
    padding: 0; 
    white-space: nowrap; 
}

.ui-tabview .ui-tabview-nav li a { 
    float: left; 
    padding: .5em 1em; 
    text-decoration: none; 
}

.ui-tabview .ui-tabview-nav li.ui-tabview-selected a, 
.ui-tabview .ui-tabview-nav li.ui-state-disabled a, 
.ui-tabview .ui-tabview-nav li.ui-state-processing a { 
    cursor: text; 
}

.ui-tabview .ui-tabview-nav li a, 
.ui-tabview.ui-tabview-collapsible .ui-tabview-nav li.ui-tabview-selected a { 
    cursor: pointer; 
}

.ui-tabview .ui-tabview-panel { 
    border-width: 0; 
    padding: 1em; 
    background: none; 
}

.ui-tabview .ui-tabview-nav li { 
    display: block; 
}

.ui-tabview .ui-tabview-nav li .ui-tabview-left-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.ui-tabview .ui-tabview-nav li .ui-tabview-right-icon {
    margin-left: .25em;
    vertical-align: middle;
}

.ui-tabview .ui-tabview-title {
    vertical-align: middle;
}

.ui-tabview .ui-tabview-nav li .ui-tabview-close { 
    margin: 0.5em 0.3em 0 0; 
    cursor: pointer; 
}

/* per orientation settings */
/* top and bottom */
.ui-tabview.ui-tabview-top > .ui-tabview-nav li { 
    border-bottom: 0;
    top: 1px; 
}

.ui-tabview.ui-tabview-top > .ui-tabview-nav { 
    padding: .2em .2em 0; 
}

.ui-tabview.ui-tabview-bottom > .ui-tabview-nav { 
    padding: 0 .2em .2em; 
}

.ui-tabview.ui-tabview-bottom > .ui-tabview-nav li { 
    border-top: 0;
}

/* left and right*/
.ui-tabview-left::after,
.ui-tabview-right::after {
    clear:both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}

.ui-tabview-left > .ui-tabview-nav {
    float:left;
    width: 25%;
    height: 300px;
    background-image: none;
    padding-top: 1px;
}

.ui-tabview-left > .ui-tabview-panels {
    float:right;
    width: 75%;
}

.ui-tabview.ui-tabview-left > .ui-tabview-nav li,
.ui-tabview.ui-tabview-right > .ui-tabview-nav li{
    display: block;
    float: right;
    white-space: normal;
    width: 99%;
}

.ui-tabview.ui-tabview-left > .ui-tabview-nav li {
    margin: 0 0 1px 0;
    border-right:0 none;
}

.ui-tabview.ui-tabview-right > .ui-tabview-nav {
    float:right;
    width: 25%;
    height: 300px;
    background-image: none;
    padding-top: 1px;
}

.ui-tabview.ui-tabview-right > .ui-tabview-panels {
    float:left;
    width: 75%;
}

.ui-tabview.ui-tabview-right > .ui-tabview-nav li {
    margin: 0 0 1px 0;
    border-left:0 none;
}

/* RTL */
.ui-rtl .ui-tabview .ui-tabview-nav li {
    float: right;
}
