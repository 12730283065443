@import "../custom_variables";

p-spinner .ui-spinner-up,
p-spinner .ui-spinner-down,
p-spinner .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:focus,
p-spinner .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:hover,
.forecast-container .ui-spinner-down {
  background-color: $green;
}

p-spinner input.route-fee {
  height: 50px;
}

/* advanced-datepicker styles - START */

.advanced-datepicker .advanced-datepicker-spinner {
  width: 60px;
}

.advanced-datepicker .advanced-datepicker-spinner .ui-spinner-input {
  padding-right: 1.5em;
  padding-left: 0.5em;
}

.advanced-datepicker .advanced-datepicker-spinner .ui-spinner .ui-spinner-button,
.advanced-datepicker .advanced-datepicker-spinner .ui-spinner:not(.ui-state-disabled) .ui-spinner-button {
  background: transparent;
  color: $green;
  border: 0;
  width: 2.0em;
  padding: 0;
  cursor: pointer;
}

.advanced-datepicker .advanced-datepicker-spinner .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:focus,
.advanced-datepicker .advanced-datepicker-spinner .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:hover,
.advanced-datepicker .advanced-datepicker-spinner .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:active {
  background: transparent;
  color: $green;
  opacity: 0.8;
  box-shadow: none;
}

.advanced-datepicker .advanced-datepicker-spinner .ui-spinner .ui-spinner-button.ui-state-disabled:hover,
.advanced-datepicker .advanced-datepicker-spinner .ui-spinner.ui-state-disabled .ui-spinner-button:hover {
  opacity: 0.35;
  cursor: not-allowed;
}

.advanced-datepicker .advanced-datepicker-spinner .ui-spinner-button .ui-spinner-button-icon {
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  margin: 0;
  display: block;
}

.advanced-datepicker .advanced-datepicker-spinner .ui-spinner-button.ui-spinner-up .ui-spinner-button-icon {
  top: 0.4em;
}
.advanced-datepicker .advanced-datepicker-spinner .ui-spinner-button.ui-spinner-down .ui-spinner-button-icon {
  bottom: 0.3em;
}

/* advanced-datepicker styles - END */
