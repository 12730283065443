@import "custom_variables.scss";

.user-body,
.user-h1,
.user-h2,
.user-h3,
.company-title {
  font-weight: 400;
}

.system-body,
.system-h1,
.system-h2,
.system-h3 {
  font-family: $roboto;
  font-weight: 400;
}

.green-text {
  color: $green;
}

.user-body {
  font-size: 13pt;
}
.system-body {
  font-size: 11pt;
}

.user-h1 {
  font-size: 26pt;
}
.system-h1 {
  font-size: 24pt;
}

.user-h2 {
  font-size: 18pt;
}
.system-h2 {
  font-size: 16pt;
}

.user-h3 {
  font-size: 16pt;
}
.system-h3 {
  font-size: 14pt !important;
}

.system-h4 {
  font-size: 28px;
  color: $green;
  margin-bottom: 20px;
  display: -webkit-inline-box;
}

.accordion-child-div h4 {
  font-size: 11pt;
}

.font-roboto {
  font-family: $roboto;
}
townip-memo-card-content,
townip-user-content,
.count-label {
  font-size: 11pt !important;
}

townip-user-name {
  font-size: 13pt;
}

.detail.project-client.trim,
.col-company-username {
  font-size: 13pt !important;
}

//============== Estimate Page =================//

.step-number {
  font-size: 14.5pt;
}

.form-question {
  font-size: 14.5pt;
}

//End of Estimate Page//

//============== Project and Tasks Pages ==============//
.ui-datatable table {
  font-size: 10pt;
  font-weight: 400;
}

.pd-container,
.message-thread p,
townip-message-content,
townip-note-content {
  font-size: 11pt;
  font-family: $roboto;
}

.project-num-header,
.project-title-head {
  font-size: 9pt;
}
.project-number {
  font-size: 13.5pt;
}

//project and tasks table multi-select
.project-view-dropdown label.ui-multiselect-label.ui-corner-all,
.task-view-dropdown label.ui-multiselect-label.ui-corner-all{
  font-size: 7pt;
}

//End of Project and Tasks Pages//


//===== Settings Page =====//
.settings-title {
  font-size: 22pt;
}

//===== Dashboard ====//
.memo-title {
  text-transform: uppercase;
}

//===== Vendor Page =====//

.name {
  font-weight: 400;
  color: $green;
}
