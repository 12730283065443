@import '../../../../stylesheet/custom_variables.scss';

townip-task-action .btn:hover {
  box-shadow: $active-shadow;
}

townip-task-action .btn-gray,
.btn-gray {
  color: #fff !important;
  background-color: #C3C3C3;
}

townip-task-action .ui-fileupload .ui-fileupload-buttonbar,
townip-task-action .ui-fileupload .ui-fileupload-content {
  border-style: dashed;
}

townip-task-action .main-actions button.btn,
.popup .main-actions button.btn{
  padding: 1.0rem 3.5rem;
  min-width: 200px;
}
