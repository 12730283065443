@import "custom_variables";

// TODO: Move spinner styling go GLOBAL for coloring if that is the main object

// Start of p-spinner styling
//.forecast-container .ui-spinner-up, .forecast-container .ui-spinner-down {
//  background-color: $green;
//}

// Do not modify spinner globally unless it is the main objective, Esp, height and color.
// TO SIZE the spinner, wrap it on a parent container and adjust the font-size to get the desired sizing.
// The size of the spinner is relative to the font-size of the container.
// <div style="font-size: 24px"><p-spinner></pspinner></div>

//.forecast-container .ui-spinner .ui-inputtext {
//  height: 25px;
//  color: $green;
//}

//.forecast-container .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:active,
//.forecast-container .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:hover,
//.forecast-container .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:focus {
//  background-color: #39A48C !important;
//}

// End of p-spinner styling

// Start of p-chips styling
.forecast-container .ui-chips-token {
  background-color: #E1E5E6;
  padding: 0 10px 0 5px;
}

.forecast-container .edit-body .ui-chips-token {
  margin-top: 5px;
}

.forecast-container .ui-chips-token .ui-chips-token-label {
  margin-right: 1em !important;
}

.forecast-container .ui-chips-token-label {
  font-weight: 500;
  font-size: 0.9rem;
}

.forecast-container townip-report-forecast-summary-details .ui-chips > ul.ui-inputtext {
  height: 128px;
  overflow-y: auto;
}
// End of p-chips styling
