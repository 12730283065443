@import '../custom_variables.scss';

// Hide second scroll bar on townip-multiple-country-selector component (This is happneing when the zoom rate is low)
townip-multiple-country-selector p-scrollPanel .ui-scrollpanel .ui-scrollpanel-wrapper .ui-scrollpanel-content {
  width: calc(100% + 30px);
}

townip-terms-of-service-modal p-scrollPanel .ui-scrollpanel,
townip-terms-of-service p-scrollPanel .ui-scrollpanel {
  padding-bottom: 25px;
  padding-right: 20px;
}

townip-terms-of-service p-scrollPanel .ui-scrollpanel .ui-scrollpanel-content {
  width: calc(100% + 28px);
}

//prevent duplicate scroll bar on zoom out in privacy modal for Chrome
townip-terms-of-service-modal p-scrollPanel .ui-scrollpanel-content::-webkit-scrollbar {
  display: none;
}

//prevent duplicate scroll bar on zoom out in privacy modal for Firefox and IE
townip-terms-of-service-modal p-scrollPanel .ui-scrollpanel-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ui-scrollpanel.borderless-scrollpanel {
  border: none;
  border-radius: 0;
  background: none;
}

// Scroll panel is dynamically initializing its own height (e.g. height: calc(100% + 18px)),
// And it's causing display issue in PM estimate view. Putting a fix height value of 100% solve the issue.
townip-approved-work p-scrollPanel .ui-scrollpanel .ui-scrollpanel-wrapper .ui-scrollpanel-content {
  height: 100%;
}
townip-bulk-assign .ui-scrollpanel-content::-webkit-scrollbar {
  display: none;
}

// Dashboard specific styling
p-scrollPanel .ui-scrollpanel.dashboard-panel .ui-scrollpanel-wrapper .ui-scrollpanel-content {
  padding: 0;
}

p-scrollPanel .ui-scrollpanel.dashboard-panel.ui-widget-content {
  border: none;
}

// Message Recipient scroll panel styling
p-scrollPanel .ui-scrollpanel.message-recipients {
  border: none;
}

p-scrollPanel .calendar-week-scrollpanel.ui-scrollpanel.ui-widget.ui-widget-content {
  background: transparent;
  border: none;
  height: 70vh;
}

p-scrollPanel .calendar-week-scrollpanel .ui-scrollpanel-content {
  padding: 0;
}

// Message list Styling
p-scrollPanel .ui-scrollpanel.thread-panel .ui-scrollpanel-wrapper,
p-scrollPanel .ui-scrollpanel.new-message .ui-scrollpanel-wrapper {
  z-index: auto;
}

p-scrollPanel .ui-scrollpanel.thread-panel .ui-scrollpanel-content {
  padding: 0;
}

// Use this for scrollpanels that do not need background colors and no borders
.ui-scrollpanel.clear-scrollpanel {
  border: none;
  padding: 0;
  background: none;
}

// Styling for Vendor Pricing
p-scrollPanel .ui-scrollpanel.service-content .ui-scrollpanel-content {
  padding: 0;
  overflow: scroll;
}

.ui-scrollpanel.pm-dashboard-panel {
  height: calc(100vh - #{$toolbar-height});
  width: 100%;
}

// Vendor List Scroll Panel Styling
.ui-scrollpanel.vendor-services .ui-scrollpanel-bar-x {
  display: block; // Display the horizontal scrollbar
}
