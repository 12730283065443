@import './custom_variables';

#dashboard-area {
  background-color: white;
}

#dashboard-area section {
  padding: 10px;
}

#dashboard-area .section-header {
  padding: 0 10px;
}

#dashboard-area section.widget-container-middle {
  padding: 20px;
}

.kpi-text {
  font-size: 36pt;
  font-weight: 400;
}

#messaging-area {
  background-color: $white;
  border-left: solid 1px #eeeeee;
}

.widget-container {
  display: flex;
  flex-wrap: wrap;
}

.widget-container > .widget {
  height: 200px;
  display: flex;
  padding: 10px;
  text-decoration: none;
}

.widget-container > .widget.disabled {
  pointer-events: none;
}

.widget-container > .widget.chart {
  flex-grow: 1;
}

.widget-container > .widget.project {
  height: auto;
}

.widget-container.full-column > .widget {
  width: 100%;
}

.widget-container.four-columns > .widget {
  width: 25%;
}

// memoes this week

button.btn.btn-link.dropdown-toggle.btn-memo {
  padding: 0 0 5px 0;
  color: $green;
}

.btn-memo .dropdown-menu {
  margin-left: -205px;
  width: 220px;
}

// dashboard debugger
townip-dashboard-debugger select {
  height: 35px;
  border: 1px solid #cecece;
  font-size: 12pt;
}

townip-dashboard-debugger select option{
  font-size: 12pt;
}

select > option:hover{
  box-shadow: 0 0 10px 100px $green inset;
  transition: all .2s ease-in-out;
}


/**
Four columns become 3 columns on screen sizes with < 1280px
This will make sure the widget sizes are always proportional
based on our left navigation and top header. Also taking into account the messaging area
on the right side pane
*/
@media screen and (max-width: 1280px) {
  .widget-container.four-columns > .widget {
    width: 33.33%;
  }
}

@media screen and (max-width: 550px) {
  .widget-container.four-columns > .widget {
    width: 50%;
  }
}

@media screen and (max-width: 400px) {
  .widget-container.four-columns > .widget {
    width: 100%;
  }
}


.widget-container.two-columns > .widget {
  width: 50%;
}

/**
Two columns becomes full width on < 1280, taking into account left nav, header, and the messaging area
 */
@media screen and (max-width: 1280px) {
  .widget-container.two-columns > .widget {
    width: 100%;
  }
}

.widget-container.three-columns > .widget {
  width: 33.33%;
}

/**
Threes columns becomes 1/2 width on < 1280, taking into account left nav, header, and the messaging area
 */
@media screen and (max-width: 1280px) {
  .widget-container.three-columns > .widget {
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .widget-container.three-columns > .widget {
    width: 100%;
  }
}
/* ---- */

@media screen and (max-width: 768px) {
  .am-container {
    margin: 20px 0 0 20px;
  }
}

// Calendar Styles
p-schedule h2 {
  font-weight: normal;
}

p-schedule .ui-button {
  background-color: $green;
}

p-schedule .ui-button:enabled:hover {
  background-color: $green2;
}

p-schedule .ui-state-highlight {
  background-color: $green2;
}

townip-side-pane-section {
  display: block;
  margin-bottom: 50px;
}

#messaging-area townip-side-pane-section .user-name.col-lg-6,
#messaging-area townip-side-pane-section .user-meta.col-lg-6 {
  flex: 0 0 100%;
  max-width: 100%;
}

#messaging-area townip-side-pane-section .metadata-left {
  text-align: right;
}

#messaging-area townip-side-pane-section .metadata-right {
  text-align: left;
}

#messaging-area townip-side-pane-section .user-content.compact {
  margin-left: 0;
}

#messaging-area townip-side-pane-section townip-user-meta {
  display: inherit;
}

#messaging-area townip-user-meta div:nth-child(1) { // date
  order: 2;
  margin-top: -25px;
}

#messaging-area townip-side-pane-section div:nth-child(2) { // company name
  order: 1;
  text-align: left;
}

#messaging-area townip-side-pane-section .grid.content-container {
  padding-left: 0;
}

// Memoes style
townip-memo-card-meta .metadata-left {
  text-align: left;
}

townip-memo-card-meta .metadata-right {
  text-align: right;
  font-size: 9pt;
}
//

townip-overview-widget,
townip-chart-widget {
  border: 1px solid #cecece;
  padding: 10px;
  width: 100%;
  height: 100%;
}

/* Pinned project container style as replacement to :host
 from project-widget.components.scss
 */

.widget.project townip-project-widget {
  border: solid 1px #cccccc;
  background-color: $white;
  width: 100%;
  display: flex;
}

.widget.project townip-project-widget:hover {
  cursor: pointer;
  border-color: $green;
}

.project-icon {
  display:flex;
  align-items: center;
  justify-content: center;
  color: $white;
  width: 33px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: $green;
}

.pin-icon {
  fill: #fff;
  height: 15px;
  width: auto;
}

.project-details {
  padding: 10px;
  flex-shrink: 1;
  flex-grow: 1;
  width: 80%;
}

.project-details .detail {
  padding: 2px 0;
}


.project-details .project-due-date {
  color: $white;
  text-decoration: underline;
}

.project-details .trim {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.widget-projects-extra {
  padding: 0 10px;
}

.widget-projects-extra .hero {
  font-family: Arvo-regular, serif;
  font-size: 4.5rem;
  line-height: 4.5rem;
}

.widget-projects-extra .sub-hero {
  width: 130px;
  word-wrap: break-word;
}

.widget-tasks-extra {

}

.widget-tasks-extra .hero {
  font-family: Arvo-regular, serif;
  font-size: 1.1rem;
}

.modal-open {
  padding-right: 0 !important;
}
