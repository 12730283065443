@import "../custom_variables";

p-inputSwitch .ui-inputswitch {
  background-color: transparent;
}

p-inputSwitch .ui-inputswitch .ui-inputswitch-slider {
  background-color: $secondary-normal;
}

p-inputSwitch .ui-inputswitch .ui-inputswitch-slider:before {
  background-color: white;
}

p-inputSwitch .ui-inputswitch.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider,
p-inputSwitch .ui-inputswitch.ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider,
p-inputSwitch .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
  background-color: $primary;
}
