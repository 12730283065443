@import 'custom_variables';

.initials-color-a, .initials-color-0 {
    background-color: $color-a;
}

.initials-color-b, .initials-color-1 {
    background-color: $color-b;
}

.initials-color-c, .initials-color-2 {
    background-color: $color-c;
}

.initials-color-d, .initials-color-3 {
    background-color: $color-d;
}

.initials-color-e, .initials-color-4 {
    background-color: $color-e;
}

.initials-color-f, .initials-color-5 {
    background-color: $color-f;
}

.initials-color-g, .initials-color-6 {
    background-color: $color-g;
}

.initials-color-h, .initials-color-7 {
    background-color: $color-h;
}

.initials-color-i, .initials-color-8 {
    background-color: $color-i;
}

.initials-color-j, .initials-color-9 {
    background-color: $color-j;
}

.initials-color-k {
    background-color: $color-k;
}

.initials-color-l {
    background-color: $color-l;
}

.initials-color-m {
    background-color: $color-m;
}

.initials-color-n {
    background-color: $color-n;
}

.initials-color-o {
    background-color: $color-o;
}

.initials-color-p {
    background-color: $color-p;
}

.initials-color-q {
    background-color: $color-q;
}

.initials-color-r {
    background-color: $color-r;
}

.initials-color-s {
    background-color: $color-s;
}

.initials-color-t {
    background-color: $color-t;
}

.initials-color-u {
    background-color: $color-u;
}

.initials-color-v {
    background-color: $color-v;
}

.initials-color-w {
    background-color: $color-w;
}

.initials-color-x {
    background-color: $color-x;
}

.initials-color-y {
    background-color: $color-y;
}

.initials-color-z {
    background-color: $color-z;
}
