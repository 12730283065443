@import "custom_variables";

.pb-container .modal-header,
.pb-container .modal-footer {
  border: none; /* remove modal borders */
}

.pb-container span.ui-cell-data button {
  border-radius: 15px !important;
}

.pb-container .modal-footer {
  -webkit-justify-content: initial; /* footer buttons align left */
}

.pb-container .modal-footer button {
  min-width: 130px; /* footer button sizes */
}

/* accordion body section border */
.pb-container .panel.card.panel-default {
  border: none;
}

/* accordion header section */
.pb-container .panel-heading.card-header {
  background: transparent;
  border: none;
}

.pb-container .modal-title {
  text-transform: uppercase;
  font-size: 11pt;
  letter-spacing: 1px;
}

.pb-container .accordion-heading-title {
  margin: -60px 0 20px 0;
}


.pb-container .panel-body.card-block {
  margin-left: 40px;
}

.pb-container .bg-blue {
  background: #F7FFFD;
  border-bottom: 3px solid $white;
}

.pb-container .bg-white {
  background: $white;
}

.create-bill-container input {
  width: 100px;
}

.create-bill-container .panel-heading.card-header,
.preview-bill-container .panel-heading.card-header {
  margin-left: 0;
}

.bill-sub-total {
  font-weight: bold;
}

.table.filing-bill-container td,
.table.language-pair-container td,
.create-bill-container td,
.other-service-container td {
  vertical-align: middle !important;
  font-size: 13px;
}

/* remove table border */
.create-bill-container .table thead th,
.create-bill-container .table td, .table th,
.filing-bill-container .table thead th,
.filing-bill-container .table td, .table th,
.language-pair-container .table thead th,
.language-pair-container .table td, .table th,
.other-service-container .table thead th,
.other-service-container .table td, .table th {
  border: none;
}

.create-bill-container th,
.filing-bill-container th,
.language-pair-container th,
.other-service-container th {
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10pt;
}

.create-bill-container input {
  width: 100px;
  padding: 5px;
}
.create-bill-container .accordion-heading-title,
.language-pair,
.modal-country-name {
  font-size: 14pt;
}


/* preview bill */
.preview-bill-container {
  border: 1px solid #979797;
}

.preview-bill-container .panel.card.panel-default,
.preview-bill-container {
  background: #fafafa;
}

.preview-bill-container .accordion-heading-title {
  margin: -50px 0 10px 0;
}

.preview-bill-container .light-text div {
  color: #9b9b9b;
  text-transform: capitalize;
  letter-spacing: 0;
}

.preview-bill-container h1 {
  margin-top: 20px;
  font-weight: bold;
}

.ts-container div,
.fs-container div {
  line-height: 30px;
}

.preview-bill-container .light-text span {
  text-transform: capitalize;
  letter-spacing: 0;
}

.preview-bill-container .flag-img {
  height: 22px;
}

.preview-bill-container .preview-bill-bottom .light-text {
  text-align: right;
}

.preview-bill-container .span-discount {
  color: #D0021B;
}

.preview-bill-container .preview-bill-bottom {
  padding-right: 55px;
}

.preview-bill-container .preview-bill-bottom div {
  line-height: 30px;
}

.preview-bill-container .span-bill-amount {
  font-weight: bold;
}

.preview-bill-container hr {
  border-top: 1px solid #9b9b9b;
  max-width: 500px;
}

.preview-bill-container .padding-10 {
  padding: 10px;
}

.preview-last-column {
  text-align: center;
}

.preview-last-column h3 {
  font-weight: 400;
  color: $black;
}
/* ----- */

/* Vendor Billing */
.bill-container .billing-btn {
  height: 50px;
  min-width: 200px;
  padding-top: 15px;
  border: 1px solid $green;
  border-radius: 6px;
  background-color: $white;
  color: $green;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 10px 0 20px 10px;
}

.bill-container .billing-btn:hover {
  background: $green;
  color: $white;
}

.bill-container .billing-btn.active {
  background: $green;
  color: $white;
}

.create-bill-container .accordion-heading-title {
  margin-left: 10px;
}

.vendor-billing-section .dropdown-toggle::after { /* remove caret */
  display: none;
}
