@import "../custom_variables";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

.townip-tristate-checkbox-false.ui-chkbox .ui-chkbox-box {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  background-color: #ffffff !important;
}

.townip-tristate-checkbox-false.ui-chkbox .ui-chkbox-box .ui-chkbox-icon:before {
  content: "";
}

.townip-tristate-checkbox-null.ui-chkbox .ui-chkbox-box {
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
  color: #fff;
}

.townip-tristate-checkbox-null.ui-chkbox .ui-chkbox-box .ui-chkbox-icon:before {
  content: "\e90f";
}

.ui-tristatechkbox.file-associate-checkbox.checkbox-false .ui-chkbox-box.ui-state-active {
  border: 2px solid $warning;
  color: $warning;
  background-color: transparent;
}

.ui-tristatechkbox.file-associate-checkbox.checkbox-false .ui-chkbox-icon.pi-times:before {
  content: "\e90f";
  margin-left: -1px;
}
