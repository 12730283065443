// Service entities
$calendar-translation-delivery: #007BFF;
$calendar-translation: #6610F2;
$calendar-proofreading: #FD7E14;
$calendar-quality-assurance: #FFC107;
$calendar-filing-confirmation: #64BAA2;
$calendar-filing-receipt: #17A2B8;
$calendar-filing-delivery: #868E96;
$calendar-default: #C7D3DF;
$calendar-overdue: #C7021D;
