.ui-dialog {
    position: fixed;
    padding: 0;
}

.ui-dynamicdialog {
    top: 50%;
    left: 50%;
}

.ui-dialog .ui-dialog-titlebar {
	padding: .5em .75em;
    position: relative;
    border: 0;
}

.ui-dialog .ui-dialog-content {
    position: relative;
    border: 0;
    padding: .5em .75em;
    background: none;
    zoom: 1;
}

.ui-dialog-resizable .ui-dialog-content {
    overflow: auto;
}

.ui-dialog .ui-resizable-se {
    width: 14px;
    height: 14px;
    right: 3px;
    bottom: 3px;
}
.ui-draggable .ui-dialog-titlebar {
    cursor: move;
}

.ui-dialog .ui-dialog-titlebar-icon {
    text-decoration: none;
    float: right;
    float: right;
    padding: .125em;
    cursor: pointer;
    border: 1px solid transparent;
}

.ui-dialog .ui-dialog-titlebar-icon span {
    display: block;
    margin: 0;
}

.ui-dialog-footer {
    padding: 1em;
    border-width: 1px 0 0 0;
    text-align: right;
}

.ui-dialog-mask {
    position: fixed;
    width: 100%;
    height: 100%;
}

.ui-dialog-maximized {
    -webkit-transition: left .3s, top .3s, width .3s,height .3s;
    transition: left .3s, top .3s, width .3s,height .3s;
}

/* ConfirmDialog */
.ui-confirmdialog {
    width: 30em;
    top: 50%;
    left: 50%;
}

.ui-confirmdialog.ui-dialog .ui-dialog-content {
    padding: 1em 2em;
}
.ui-confirmdialog .ui-dialog-content .ui-confirmdialog-icon {
    font-size: 1.5em;
    margin-right: .5em;
    position: relative;
    top: .2em;
}

.ui-dialog-footer .ui-button {
    margin-right: .25em;
}

/* Fluid */
.ui-fluid .ui-dialog-footer .ui-button {
    width: auto;
}

/* RTL */
.ui-rtl .ui-dialog .ui-dialog-titlebar-close  {
    float: left;
}

.ui-rtl .ui-dialog .ui-dialog-buttonpane button {
    text-align: right;
}

@media screen and (max-width: 40em) {
    .ui-confirmdialog {
        width: 90%;
    }
}