@import "../stylesheet/custom_variables";

/* modified style of table header*/

.invoices-container .ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
  height: 30px !important;
}

.invoices-container span.fa.fa-fw.fa-caret-down.ui-c {
  margin-top: 5px !important;
}

.invoices-container .ui-datatable .ui-datatable-data > tr > td:first-child {
  text-align: left !important;
  padding-left: 10px !important;
}
