/* You can add global styles to this file, and also import other style files */
// Bootstrap overrides
@import "stylesheet/custom_variables";

// Bootstrap
@import '../node_modules/bootstrap/scss/bootstrap';

// Prime components
@import 'thirdparty/primeng@7.1.3/src/assets/components/themes/bootstrap/theme.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/common/common.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/dropdown/dropdown.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/calendar/calendar.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/button/button.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/paginator/paginator.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/table/table.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/checkbox/checkbox.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/radiobutton/radiobutton.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/multiselect/multiselect.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/fileupload/fileupload.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/accordion/accordion.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/panel/panel.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/fieldset/fieldset.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/inputtext/inputtext.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/messages/messages.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/sidebar/sidebar.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/dialog/dialog.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/scrollpanel/scrollpanel.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/splitbutton/splitbutton.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/growl/growl.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/tabmenu/tabmenu.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/autocomplete/autocomplete.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/schedule/schedule.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/chips/chips.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/inputswitch/inputswitch.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/overlaypanel/overlaypanel.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/progressbar/progressbar.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/panelmenu/panelmenu.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/rating/rating.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/tabview/tabview.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/spinner/spinner.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/virtualscroller/virtualscroller.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/fullcalendar/fullcalendar.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/inputmask/inputmask.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/inputtextarea/inputtextarea.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/slider/slider.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/breadcrumb/breadcrumb.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/message/message.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/listbox/listbox.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/selectbutton/selectbutton.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/datatable/datatable.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/menu/menu.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/grid/grid.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/tooltip/tooltip.css';
@import 'thirdparty/primeng@7.1.3/src/app/components/contextmenu/contextmenu.css';


@import "stylesheet/theming/p-datatable";
@import "stylesheet/theming/p-paginator";
@import "stylesheet/theming/p-panel";
@import "stylesheet/theming/p-radioButton";
@import "stylesheet/theming/p-slider";
@import "stylesheet/theming/p-calendar.scss";
@import "stylesheet/theming/p-fullcalendar.scss";
@import "stylesheet/theming/p-checkbox.scss";
@import "stylesheet/theming/p-accordion";
@import "stylesheet/theming/p-dropdown";
@import "stylesheet/theming/p-multiselect";
@import "stylesheet/theming/p-chips";
@import "stylesheet/theming/p-autocomplete";
@import "stylesheet/theming/p-growl";
@import "stylesheet/theming/prime-shared";
@import "stylesheet/theming/p-confirmdialog";
@import "stylesheet/theming/p-dialog";
@import "stylesheet/theming/p-chart";
@import "stylesheet/theming/p-table";
@import "stylesheet/theming/p-spinner";
@import "stylesheet/theming/p-inputswitch";
@import "stylesheet/theming/p-scrollpanel";
@import "stylesheet/theming/p-overlaypanel";
@import "stylesheet/theming/bootstrap-modal";
@import "stylesheet/theming/bootstrap-accordion";
@import "stylesheet/theming/tag-input";
@import "stylesheet/theming/p-tristatecheckbox";
@import "stylesheet/theming/p-editor";
@import "stylesheet/theming/p-rating";
@import "stylesheet/theming/p-accordiontab";
@import "stylesheet/theming/p-virualscroller";
@import "stylesheet/theming/p-fileupload";
@import "stylesheet/theming/p-messages";
@import "stylesheet/theming/mark";
@import "stylesheet/theming/p-inputMask";
@import "stylesheet/theming/townip-advanced-datepicker";
@import "stylesheet/theming/townip-date-only-picker";
@import "stylesheet/theming/bootstrap-tooltip";
@import "stylesheet/theming/tinymce";
@import "stylesheet/theming/cdk-virtual-scroll-viewport";
@import "stylesheet/theming/bootstrap-popover";
@import "stylesheet/theming/tables";
@import "stylesheet/flags-sm";

/** Page Layout */
html {
  height: 100%;
}

body {
  min-height: 100%;
}

.disable-actions {
  pointer-events: none;
  opacity: 0.5;
}

/* townip dashboard */
townip-dashboard {
  overflow: hidden;
}

.layout-wrapper .layout-main .townip-top-menu-wrapper {
  height: $toolbar-height;
  background-color: $green;
  position: fixed;
  width: $calc-width-collapsed;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 999997;

  // Remove transition animation in Top Menu bar for IE and Firefox, because it is not working properly
  // Transition default to NONE (for IE and Firefox)
  transition: none;
  -moz-transition: none !important;
  -o-transition: none !important;
  -webkit-transition: width 0.3s;
}

.layout-wrapper.app-expanded .layout-main .townip-top-menu-wrapper {
  width: $calc-width-expanded;
}

/** end page layout */

/** End Page layout */

main.col-sm-11 {
  max-width: 87%;
}

.dots {
  color: #ebebeb;
}

input.ui-autocomplete-input.ui-inputtext {
  $white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 0;
}

span.btn-secondary {
  line-height: 2.5;
  font-size: 16px;
}

.circle {
  float: left;
  border: 6px solid #ebebeb;
  border-radius: 6px;
  width: 0;
  margin-left: 8px;
  cursor: default;
}

.circle.current, circle:hover {
  border-color: #d6d6d6;
}

.ui-inputtext {
  width: 100%;
}

.townip-cal .ui-inputtext {
  font-size: 1rem !important;
  line-height: 1 !important;
}

.townip-cal .ui-button {
  background-color: $green;
}

.ui-fileupload-files {
  width: 100% !important;
}

.well.my-drop-zone {
  max-width: 75%;
}

.ui-autocomplete-list-item {
  overflow: hidden;
  $white-space: nowrap;
  text-overflow: ellipsis;
}

.fa-button-outline {
  padding: 2px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  float: right;
}

.address-fa {
  position: relative;
  top: 30px;
  float: right;
  color: $green;
  margin-right: 20px;
}

.ui-autocomplete {
  margin: 1rem;
}

.country-label {
  font-size: 10px;
  color: #1d1e1f;
  padding-top: 5px;
  text-align: center;
}

.flag-img {
  height: 31px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.flag-btn-img {
  height: 18px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.ui-chips {
  height: 14px;
  box-shadow: $default-shadow;
}

.ui-chips .ui-widget {
  height: fit-content;
}

.flag-tgl-img {
  height: 18px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}

.flag-btn {
  border: none;
  width: 100%;
  padding: 5px 0;
  margin: 5px;
}

.fee-cell {
  text-align: right;
}

.fee-cell::before {
  content: "$";
}

.fee-cell::after {
  content: ".00";
}

label.btn.btn-outline-secondary.tip-button {
  font-size: 14pt !important;
  text-transform: capitalize !important;
}

/* form elements DIV top margin - settings pages*/
/* div.form-element, */
.div-info {
  margin-top: 20px !important;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  padding: .3rem .5rem;
}

textarea {
  resize: none;
}

.form-error {
  color: #C0392B;
  margin-top: 60px;
}

.form-success {
  color: $green;
  margin-top: 60px;
}

.form-success {
  color: $green;
  margin-top: 60px;
}

.div-country-doc {
  margin-top: 10px !important;
}

/* Buttons - Application preferences*/
.btn-link {
  color: $green;
}

.fa-button-primary {
  background-color: $white;
  border: none;
  color: $green;
  margin-top: 55px;
  padding: 3px 6px;
}

.fa-button-primary:hover {
  background-color: $green;
  border: none;
  color: $white;
}

.text-button {
  background-color: $white;
  border: none;
  color: $green;
  border-bottom: 1px solid $green;
  margin-top: 4rem;
  cursor: pointer;
}

.text-button:hover {
  background-color: $green;
  border: none;
  color: $white;
  border-bottom: 1px solid $white;
  margin-top: 4rem;
}

//noinspection CssOverwrittenProperties
.text-break-word {
  // Keep both word-break for cross-browser compatibility
  word-break: break-all;
  word-break: break-word;
}

.text-bold {
  font-weight: bold;
}

.text-bold-medium {
  font-weight: 400;
}

.text-bold-500 {
  font-weight: 500;
}


.text-bold-700 {
  font-weight: 700;
}

a.text-primary,
.text-primary {
  color: $primary !important;
}

a.text-primary:focus,
a.text-primary:hover,
.text-primary:focus,
.text-primary:hover {
  color: $primary-hover !important;
}

.bg-primary {
  background-color: $primary !important;
}

/* Buttons - Application preferences */
.clear-text-button {
  background-color: transparent;
  border: none;
  color: $green;
  cursor: pointer;
}

.clear-text-button:hover {
  color: #308b76;
}

.clear-text-button:focus {
  outline: none;
  box-shadow: none;
}

.project-header-container .ui-togglebutton.ui-button.ui-state-focus {
  box-shadow: 0 0 5px $green;
}

.project-header-container .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  color: $green;
  border-color: $green;
  background-color: transparent !important;
}

.project-header-container .ui-state-active,
.project-header-container .ui-state-active:hover,
.project-header-container .ui-state-active:active,
.project-header-container .ui-state-active:focus {
  color: white !important;
  background-color: $green !important;
  border-color: $green !important;
}

.project-header-container .ui-button {
  background-color: transparent;
  color: $email-text;
  border-color: $email-text;
}

.make-default-btn {
  font-size: 12px;
  float: right;
  margin-top: 0 !important;
}

.make-default-btn.address-box {
  position: absolute;
  left: 70%;
  margin-top: 20px !important;
  z-index: 9;
}

.clear-box { /* clear button */
  position: absolute;
  left: 90%;
  margin-top: 20px;
  z-index: 9;
  padding: 2px 5px !important;
}

/* Menu Steps */
.active-step {
  color: $green !important;
}

.step-title {
  color: $email-text;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  margin-left: 10px;
}

/* icons - application preferences */

.accordion-child-div .btn-cta-secondary, /* buttons width long text character inside */
.row.span-title .btn-cta-secondary {
  width: auto;
}

.accordion-heading-clear {
  position: relative;
  padding-left: 0;
  margin-top: -10px;
  margin-left: 0;
  padding-right: 50px;
}

.accordion-heading-clear .tip-btn {
  float: right;
}

.align-left {
  text-align: left;
}

.accordion-header-button {
  display: inline-block;
  float: right;
  margin-top: -45px;
  padding-right: 17px;
}

.btn-remove-service {
  padding-right: 48px;
}

.btn-remove-service .tip-btn {
  float: right;
  padding: .3rem .5rem;
}

.add-service-container {
  padding-right: 33px;
  margin-top: 0 !important /* add service aligned with the dropdown sel-proficiency */
}

.ui-select-placeholder,
a.dropdown-item,
input.form-control.ui-select-search {
  font-family: $font-family-default !important;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  text-align: left;
}

input.search-box {
  width: 100%;
}

.dropdown-menu {
  min-width: 1rem;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.21);
  border: none;
}

.destination-container .panel-body.card-block {
  background-color: #EAF6F3;
}

.remove-service-btn {
  /* margin-right: 30px; */
  float: right;
}

.block-input input {
  padding-left: 10px;
  font-size: 18px !important;
}

input.form-control.ui-select-search {
  padding: .5rem .75rem;
}

.ui-select-container.ui-select-multiple.dropdown.form-control.open {
  padding: .5rem .75rem;
}

.ui-autocomplete {
  margin: 0 !important;
}

span.ui-autocomplete.ui-widget {
  width: 100%;;
}

input.ui-autocomplete-input.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  height: 56.67px;
}

/* Application preferences */
.accordion-toggle .pref-heading {
  font-size: 24px !important;
}

/* toggle accordion */
// This commented out to fix the global styling of the accordion headers
// DO NOT UNCOMMENT THIS. If you think you need to, contact @dpili.
//.accordion-toggle {
//  font-size: 16px !important;
//}

.panel-collapse.collapse.in.show {
  margin-top: -30px;
  padding-left: 20px;
}

.accordion-heading-title /* delivery instructions, card address parent div */
{
  margin-left: -75px;
}

.services-container .accordion-heading-title {
  margin-left: 0;
}

// TODO: Never put crazy margins on generic global reusable control styles. Refactor needed.
// If we use a control that uses this style on a different page or context:
// those will then appear off-screen by default and appear broken.
.panel-heading.card-header {
  margin-left: -80px;
}

.nested-accordion .panel-heading.card-header {
  margin-left: 0 !important;
}

/* arrow color */
.fa.pull-left {
  margin-right: .3em;
  color: $email-text !important;
  padding-right: 10px;
  margin-top: 6px;
}

// .pac-container needs z-index above .modal-open when on a modal.
.pac-container {
  z-index: 1051;
}

.modal-dialog {
  margin: 90px auto 30px auto;
}

.div-autosearch, /* spacer with center position style */
.spacer-bottom {
  margin: 0 auto;
  margin-top: 20px;
}

/* top margin */
.top-margin,
.textarea {
  margin-top: 20px;
}

/* bottom margin */
.bottom-margin {
  margin-bottom: 20px;
}

.left-margin {
  margin-left: 20px;
}

.left-padding {
  padding-left: 20px;
}

.row.rem-margin {
  margin: 0 !important;
}

/* Townip button/images */
.tip-img-crop {
  width: auto;
  height: auto;
  overflow: hidden;
  border-radius: 50%;
}

.tip-button-img {
  height: 23px;
  width: 23px;
  margin-right: 10px;
}

/* uploader */
.well.my-drop-zone {
  text-align: center;
  border: dashed 3px lightgray !important;
  border-width: thin !important;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* uploader */

/* file upload remove button */
.fu-container button {
  margin-left: 10px;
  width: initial !important;
  margin-bottom: 5px;
}

.btn-xs {
  display: none;
}

.bottom-space {
  padding-bottom: 200px;
}

/* order page */
span.ty-header a {
  color: $gray-dark;
}

.panel-title.card-title {
  margin-bottom: 0 !important;
}

/* initial */
.initial {
  color: $white;
  font-size: 20px;
  width: 55px;
  margin: -10px auto;
  background-color: $gray-dark;
  padding: 12px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
}

.client-title {
  text-align: left;
  overflow: hidden;
}

.pd-container .client-title {
  text-align: left;
  overflow: hidden;
  line-height: 36px;
}

.ui-disabled {
  z-index: 2 !important;
}

.radio-item label span {
  font-size: 14pt;
}

sup.required {
  top: 0;
  font-size: 16px !important;
  font-weight: 400;
}

.hr-divider {
  margin-bottom: 2rem;
}

/* Hide number spinners */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.settings-pill {
  margin-left: 55px;
}

.card-header-bold {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14pt;
}

.overdue-color {
  color: #EA0723;
  background-color: #EA0723;
}

.needs-action-color {
  color: #FFA000;
  background-color: #FFA000;
}

.on-track-color {
  color: #2EA152;
  background-color: #2EA152;
}

.estimate-review-color,
.new-projects-color {
  color: #5B91FF;
  background-color: #5B91FF;
}

.rush-color {
  color: #854AB8;
  background-color: #854AB8;
}

.active-color {
  color: $green;
  background-color: $green;
}

.completed-color {
  color: #6D7D9E;
  background-color: #6D7D9E;
}

// Quickview and sidepane

townip-message-quickview .quickview .user-name.col-lg-6,
townip-message-quickview .quickview .user-meta.col-lg-6,
townip-alerts-quickview .quickview .user-name.col-lg-6,
townip-alerts-quickview .quickview .user-meta.col-lg-6 {
  max-width: 100%;
  width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 100%;
  -ms-flex: 0 0 50%;
  flex: 100%;
}

townip-message-quickview .quickview .user-content.compact,
townip-alerts-quickview .quickview .user-content.compact {
  margin-left: 0;
}

townip-message-quickview .quickview .card-container .image-container,
townip-alerts-quickview .quickview .card-container .image-container {
  padding-right: 0;
}

townip-message-quickview .quickview townip-user-meta,
townip-alerts-quickview .quickview townip-user-meta {
  display: inherit;
}

townip-message-quickview .quickview townip-user-meta div:nth-child(1),
townip-alerts-quickview .quickview townip-user-meta div:nth-child(1) { // date
  order: 2;
  margin-top: -25px;
}

townip-message-quickview .quickview townip-user-meta div:nth-child(2),
townip-alerts-quickview .quickview townip-user-meta div:nth-child(2) { // company name
  order: 1;
  text-align: left;
}

.quickview-container .grid.content-container {
  padding-left: 0 !important;
}

/* end of quickview style */

span.fa.fa-star-o,
.ui-rating a {
  color: $green;
  text-decoration: none;
}

.ui-rating a:hover {
  color: #2c7e6b;
  text-decoration: none;
}

span.fa.fa-star-o:hover {
  color: #2c7e6b;
}

/* end of style */

// override bootstrap primary button colors
.btn-primary,
.btn-primary.active,
.btn-primary:active,
.btn-primary:hover,
.btn-outline-primary,
.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show>.btn-primary.dropdown-toggle{
  background-color: $green;
  border-color: $green;
  outline: none;
  box-shadow: none;
}

.btn-primary:focus,
.btn-primary:active {
  color: white;
}

.border-primary {
  border-color: $green !important;
}

.invalid-feedback {
  display: inherit;
}

body .settings-button .ui-button {
  background-color: $green;
  border-color: $green;
}

body .settings-button .ui-button:hover {
  background-color: $green !important;
  border-color: $green !important;
}

body .settings-button .ui-buttonset .ui-button:hover {
  color: $white !important;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  opacity: 0.8;
  background-color: $green;
  color: $white;
}

// -------- Project finances tab -- override bootstrap--- //

body a,
body a:hover,
a:focus,
a:hover {
  color: $green;
}

.btn-outline-primary:active:not(:disabled),
.show > .btn-outline-primary.dropdown-toggle,
.show > .btn-primary.dropdown-toggle {
  background-color: $green !important;
  border-color: $green !important;
  color: #ffffff !important;
}

.row.finances-btn-container button {
  margin: 0 5px 0 5px;
}

.row.finances-btn-container {
  padding-left: 25px;
}

// Messaging

.reply-box-container {
  margin-top: 20px;
}

// End Messageing Style

// Projects and Tasks top buttons
.btns-container {
  max-width: 100%;
}

.thread-btn-new { /* new message button */
  width: 115px;
  height: 30px;
  border-radius: 20px;
  background-color: #f8fbf8;
  font-weight: 300;
  letter-spacing: 1.2px;
  text-align: center;
  color: $gray-dark;
  padding: 0;
}

.dashboard-container .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  text-transform: uppercase;
  font-family: $roboto;
  font-weight: bold;
}

townip-internal-management .cv-tab .ui-tabview.ui-widget {
  background-color: transparent;
}

.dashboard-container .ui-tabview .ui-tabview-panel {
  background-color: transparent;
}

p-tabView .ui-tabview.ui-tabview-top .ui-tabview-nav {
  background: transparent;
}

p-tabView .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  background-color: transparent;
}

p-tabView .ui-tabview.ui-tabview-top .ui-tabview-nav {
  border-color: #CCCCCC;
}

p-tabView .ui-state-active {
  background-color: transparent;
}

p-tabView .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  border-bottom: 3px solid transparent;
}

p-tabView .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: transparent;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 3px solid $green;
}

p-tabView .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active {
  background-color: transparent;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 3px solid $green;
}

p-tabView .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a,
p-tabView .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a,
p-tabView .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a,
p-tabView .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: $gray-dark !important;
}

p-tabView .ui-tabview-title {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.6px;
}

p-tabView .ui-tabview .ui-tabview-nav li .ui-state-active .ui-tabview-title {
  color: $gray-dark;
}

p-tabView .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-default a,
p-tabView .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-default a,
p-tabView .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-default a,
p-tabView .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-default a {
  color: $email-text;;
}

/* checkbox label */
li.ui-multiselect-item.ui-corner-all label {
  margin-bottom: initial;
}

/* file upload dropdown */
.file-upload-container .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  min-width: 100%;
}

/* uploader close/remove button */
#file-uploader span.fa.fa-close {
  padding: initial;
}

/* messaging module */
$messages-border: 1px solid #E4E4E4;
$messages-border-radius: 5px;

townip-project-communication townip-messages townip-message-list,
townip-messages-page townip-messages townip-message-list {
  border-right: 1px solid #E4E4E4;
}

townip-messages-page townip-messages {
  background: $white;
}

townip-project-communication townip-messages townip-message-list townip-messages-header,
townip-project-communication townip-messages townip-message-list townip-messages-sub-header,
townip-messages-page townip-messages townip-message-list townip-messages-header,
townip-messages-page townip-messages townip-message-list townip-messages-sub-header {
  margin: 1px;
}

townip-dashboard-messages townip-message-list townip-messages-header .content-height {
  height: 70px;
}

townip-messages .message-select townip-messages-header .content-height {
  min-height: 70px;
}

townip-messages townip-user-card townip-user-content townip-message-recipients .message-participants {
  color: #C1CACD;
}

townip-user-chips .message-recipients-container typeahead-container {
  width: 400px;
  left: 30px !important;
  margin-top: 10px;
}


/* Delivery Contacts */
townip-contact-container {
  width: 100%;
}

/* Progress Bar */
.progress {
  border-radius: 0;
}

body .ui-progressbar {
  height: 10px;
  border-radius: 5px;
}

body .ui-progressbar .ui-progressbar-value {
  background: #43BCA1;
  border-radius: 5px;
}

// Default tooltip styles
.tooltip.info,
.tooltip.client-info-tooltip {
  pointer-events: auto !important;
}

body .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before, .tooltip.tooltip-top .tooltip-inner::before {
  border-top-color: $green;
}

.tooltip-inner {
  max-width: 300px;
  font-size: 12pt;
  color: $gray-dark;
  background-color: $gray-lightest;
  border: 1px solid $green;
}

.legal-translation-progress-container .tooltip-inner {
  max-width: 300px;
  font-size: 12pt;
  color: white;
  background-color: #383838;
  border: 1px solid #383838;
}

.info .tooltip-inner {
  padding: 5px;
  background-color: #545454 !important;
  color: #FFFFFF !important;
  box-shadow: $hover-shadow;
  border: none;
  font-weight: 200;
  font-size: 10pt;
  cursor: pointer;
}

// Additional style only for bulk assign calendar tooltip
.tooltip.bs-tooltip-top.bulk-assign-calendar {
  margin-bottom: 10px;
}

.info hr {
  background-color: #B3B3B3;
  margin: 5px 3px 5px 1px;
}

body .ui-breadcrumb ul li .ui-menuitem-link {
  color: $green;
  text-decoration: underline;
  font-weight: normal;
  background-color: transparent;
}

body .ui-breadcrumb ul li:first-child {
  font-size: 1em;
}

body .ui-breadcrumb ul li .ui-menuitem-link.ui-state-disabled {
  color: $gray-dark;
  text-decoration: none;
  opacity: 1;
}

body .ui-breadcrumb {
  background-color: #FAFAF9;
}

.menu-rule {
  width: 80%;
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.2;
  background-color: #cecece;
}

.action-item {
  text-transform: uppercase;
  text-align: center;
}

body .ui-accordion .ui-accordion-header {
  color: $green;
}

label.has-error {
  color: red;
}

/* overwrite bootstrap styling */
.btn-link:hover,
.townip-cal .ui-button:hover {
  color: $green;
  opacity: 0.8;
}

.ui-datepicker {
  background-color: white;
  width: 275px;
}

.row-group-misc {
  font-weight: normal;
}

.forecast-container .report-summary .ui-dropdown .ui-dropdown-label {
  color: #00B1A8;
}

/* PrimeNG Calendar Styling*/
table.ui-datepicker-calendar {
  background-color: #FFFFFF;
  margin: 0;
}

.heading-button {
  border: none;
}

input:focus {
  box-shadow: $default-shadow;
}

.badge-primary {
  font-family: $font-family-default;
  background-color: $green;
  border-right: .45rem;
}

.user-badge {
  height: fit-content;
  font-size: 11px;
  align-self: center;
  margin-left: 5px;
}

.onboarding-form-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

input.form-control.settings-input {
  font-size: .9em !important;
  line-height: 1.25 !important;
  height: unset !important;
  font-family: $roboto;
}

p-fileUpload input.form-control.ng-touched.ng-invalid {
  border: 1px solid red !important;
}

townip-direct-filing-upload p-fileUpload p-calendar.ng-touched.ng-invalid input {
  border: 1px solid red !important;
}

.fit-content {
  width: fit-content !important;
}

.message-thread p {
  margin-bottom: 0;
  word-wrap: break-word;
}

.message-thread p.proposed-note {
  word-break: break-word;
}

// Messaging Thread Styling
.trimmedMessage strong {
  font-weight: 600;
}

/** Word Count Table Styling **/
.table.word-count-viewer th,
.table.word-count-viewer td {
  border-width: 0;
  text-align: center;
  font-size: 13px;
}

.table.word-count-viewer td {
  font-weight: normal;
  background-color: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-width: 1px 0;
}

.table.word-count-viewer td:first-of-type {
  border-left-width: 1px;
}

.table.word-count-viewer td:last-of-type {
  border-right-width: 1px;
}

.table.word-count-viewer th {
  background-color: #E9ECED;
  border: 1px solid #E9ECED;
}

/** Change font selections **/
::selection,
::-moz-selection,
::-o-selection,
::-ms-selection,
::-webkit-selection {
  background-color: $green;
  color: $white;
}

/* Start grid col hidden */
@media (max-width: 719px) { .hidden-col-sm { display: none; } }
@media (max-width: 959px) { .hidden-col-md { display: none; } }
@media (max-width: 1199px) { .hidden-col-lg { display: none; } }
@media (max-width: 1449px) { .hidden-col-xl { display: none; } }
@media (min-width: 1450px) { .hidden-col-xxl { display: none; } }
/* End grid col hidden */

@media (min-width: 1450px) {

  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.bg-green,
.bg-townip {
  background-color: $green;
}

.btn-outline-primary {
  @include button-outline-variant($primary);
}

// ------------------------------------
// START OF: FIXING FORMS
// ------------------------------------
.form-control.standard-control,
.ui-calendar.standard-control .ui-inputtext,
.standard-control .ui-dropdown .ui-inputtext.ui-dropdown-label,
.standard-control .ui-multiselect .ui-multiselect-label-container {
  // RESETTING the style of the standard bootstrap control (this is based on _forms.scss of bootstrap)
  // Once global css has been reset, we will remove this and cleanup.
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  @include box-shadow($input-box-shadow);
  @include form-control-focus();
}

/* Invalid state */
  // input
.form-control.standard-control.ng-dirty.ng-invalid,
.form-control.standard-control.ng-touched.ng-invalid,
  // p-calendar
.ng-dirty.ng-invalid > .ui-calendar.standard-control .ui-inputtext,
.ng-touched.ng-invalid > .ui-calendar.standard-control .ui-inputtext,
  // date-only-picker
.ng-dirty.ng-invalid > .date-only-picker > .ui-calendar.standard-control .ui-inputtext,
.ng-touched.ng-invalid > .date-only-picker > .ui-calendar.standard-control .ui-inputtext,
  // dropdown
.standard-control.ng-dirty.ng-invalid .ui-dropdown .ui-inputtext.ui-dropdown-label,
.standard-control.ng-touched.ng-invalid .ui-dropdown .ui-inputtext.ui-dropdown-label,
.standard-control.ng-touched.ng-invalid .ui-multiselect .ui-multiselect-label-container,
  // editor-v1
.standard-control.ng-touched.ng-invalid .ui-editor-container {
  @include standard-form-error-style;
}
.standard-control.ng-touched.ng-invalid .ui-chkbox .ui-chkbox-box {
  @include standard-form-error-style;
  border-width: 1px;
}

/* Invalid state focused */
  // input
.form-control.standard-control.ng-dirty.ng-invalid:focus,
.form-control.standard-control.ng-touched.ng-invalid:focus,
  // p-calendar
.ng-dirty.ng-invalid > .ui-calendar.standard-control .ui-inputtext:focus,
.ng-touched.ng-invalid > .ui-calendar.standard-control .ui-inputtext:focus,
  // date-only-picker
.ng-dirty.ng-invalid > .date-only-picker > .ui-calendar.standard-control .ui-inputtext:focus,
.ng-touched.ng-invalid > .date-only-picker > .ui-calendar.standard-control .ui-inputtext:focus,
  // dropdown
.standard-control.ng-dirty.ng-invalid .ui-dropdown.ui-state-focus .ui-inputtext.ui-dropdown-label,
.standard-control.ng-touched.ng-invalid .ui-dropdown.ui-state-focus .ui-inputtext.ui-dropdown-label,
.standard-control.ng-dirty.ng-invalid .ui-multiselect.ui-state-focus .ui-multiselect-label-container,
.standard-control.ng-touched.ng-invalid .ui-multiselect.ui-state-focus .ui-multiselect-label-container,
  // dropdown with opened menu
.standard-control.ng-dirty.ng-invalid .ui-dropdown.ui-dropdown-open .ui-inputtext.ui-dropdown-label,
.standard-control.ng-touched.ng-invalid .ui-dropdown.ui-dropdown-open .ui-inputtext.ui-dropdown-label,
.standard-control.ng-touched.ng-invalid .ui-multiselect.ui-multiselect-open .ui-multiselect-label-container,
  // editor-v1
.standard-control.ng-touched.ng-invalid .focused .ui-editor-container {
  box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, .25);
}

// Disabled state
.form-control.standard-control:disabled,
.form-control.standard-control[readonly],
.ui-calendar.standard-control .ui-inputtext:disabled,
.standard-control .ui-dropdown.ui-state-disabled .ui-inputtext.ui-dropdown-label,
.standard-control .ui-multiselect.ui-state-disabled .ui-multiselect-label-container {
  background-color: $input-disabled-bg;
  border: $input-border-width solid $input-border-color;
  opacity: 1;
}

// Readonly state
.standard-control .ui-dropdown.readonly {
  opacity: .35;
}


textarea.form-control.standard-control {
  height: auto;
}

select.form-control.standard-control {
  padding: $input-padding-y $input-padding-x;
}

select.form-control.standard-control {
  &[size],
  &[multiple] {
    height: auto;
  }
}

.form-group townip-dropdown {
  display: block;
}

// additional styling for calendars
.ui-calendar.standard-control {
  width: 100%;
}
.ui-calendar.standard-control.ui-calendar-w-btn .ui-inputtext {
  padding-left: 29px !important;
  border-radius: $input-border-radius;
  width: 100% !important;
}
.ui-calendar.standard-control.ui-calendar-w-btn .ui-calendar-button {
  position: absolute;
  left: 2px;
  bottom: 2px;
  top: 2px;
  pointer-events: none;
  border: 0;
  box-shadow: none;
  height: auto;
  background-color: transparent;
}

// additional styling for dropdowns
.standard-control .ui-dropdown,
.standard-control .ui-multiselect {
  border: 0;
  padding: 0;
  width: 100%;
  background: none;
}
.standard-control .ui-multiselect .ui-multiselect-label {
  padding: 0;
}
.standard-control .ui-dropdown.ui-state-disabled,
.standard-control .ui-multiselect.ui-state-disabled {
  opacity: 1;
}
.standard-control .ui-dropdown.ui-state-disabled .ui-inputtext.ui-dropdown-label,
.standard-control .ui-multiselect.ui-state-disabled * {
  cursor: default !important;
}
.standard-control .ui-dropdown.ui-state-focus .ui-inputtext.ui-dropdown-label,
.standard-control .ui-dropdown.ui-dropdown-open .ui-inputtext.ui-dropdown-label,
.standard-control .ui-multiselect.ui-multiselect-open .ui-multiselect-label-container {
  color: $input-focus-color;
  background-color: $input-focus-bg;
  border: 1px solid $input-focus-border-color;
  outline: 0;
  box-shadow: $input-box-shadow, $input-focus-box-shadow;
}
.standard-control .ui-dropdown .ui-inputtext.ui-dropdown-label,
.standard-control .ui-multiselect .ui-multiselect-label-container {
  border-radius: $input-border-radius !important;
  padding-right: 35px;
  text-overflow: ellipsis;
}
.standard-control .ui-dropdown .ui-dropdown-trigger,
.standard-control .ui-multiselect .ui-multiselect-trigger {
  top: 2px;
  right: 2px;
  bottom: 2px;
  height: auto;
  pointer-events: none;
  background-color: transparent;
}
.standard-control .ui-dropdown.ui-helper-clearfix::before,
.standard-control .ui-dropdown.ui-helper-clearfix::after {
  // fixes table header filter issue
  display: none;
}

// additional styling for editor-v1
.standard-control .ql-toolbar.ql-snow {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding: $input-padding-y $input-padding-x;
  background-color: $input-bg;
  border-bottom: 1px solid $input-border-color;
}
.standard-control .ql-container.ql-snow {
  border: 0;
  overflow: hidden;
  padding: $input-padding-y $input-padding-x;
}
.standard-control .ui-editor-container {
  width: 100%;
  height: 100%;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  display: flex;
  flex-direction: column;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  @include box-shadow($input-box-shadow);
}
.standard-control .focused .ui-editor-container {
  color: $input-focus-color;
  background-color: $input-focus-bg;
  border-color: $input-focus-border-color;
  outline: 0;
  @if $enable-shadows {
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
  } @else {
    box-shadow: $input-focus-box-shadow;
  }
}
.standard-control .ui-editor-container .ql-editor {
  padding: 0;
}

.input-group > .form-control.standard-control {
  width: 1%;
}

.input-group-lg > .form-control.standard-control {
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $input-font-size-lg !important;
  line-height: $input-line-height-lg !important;
}

// ------------------------------------
// END OF: FIXING FORMS
// ------------------------------------


// ------------------------------------
// NEW STYLES
// Do not PURGE on CSS refactor JOB
// ------------------------------------
.step-indicator {
  background-color: #d6e4e8;
}

// TODO: Adding in 5's increment
.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.btn-outline-primary.btn-outline-primary-white {
  background-color: white;
}

// Mostly used on buttons that are outline but does not have any BG.
.btn-outline-primary.btn-outline-primary-white:hover,
.btn-outline-primary.btn-outline-primary-white:active {
  background-color: $primary;
}

// Another new d*** alert style from design.
.alert-info.darker {
  background: #3086DD;
  border-color: #3086DD;
  color: white;
}

.alert-info {
  background: #cce5ff
}

.alert-primary.alert-payment-info {
  background-color: #E8F7F4;
  border-color: #E2F2EF;
  color: #000000;
}

// Override the border styling of input from theme.css prime7.1.3
body .ui-inputtext:focus {
  border: 1px solid $primary;
}

// Do not remove for now - will break a lot more things we can fix at the moment
.btn > i.fa {
  margin-right: 0.5rem;
}

.dev-tools-toggle-container {
  position: fixed;
  right: 15px;
  bottom: 15px
}

.action-header-container {
  // This is used for wizard pages where a custom wide implementation is needed.
  // The background must match the custom width, otherwise it will be cutoff at the end
  // For an accurate implementation, see bulk-price-freeze.component.html
  background-color: $action-header-bg;
}

// ------------------------------------
// END OF: NEW STYLES
// ------------------------------------


.form-control.standard-control.otp-input[readonly] {
  background-color: white;
  opacity: 0.5;
}
