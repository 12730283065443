@import "custom_variables";

// container and header
@media (min-width: 2100px) {
  .side-menu {
    width: 260px;
  }

  .app-container.expanded .side-body {
    margin-left: 260px;
  }

  .app-container.expanded .content-container .navbar-top {
    padding-left: 260px;
  }
}

@media (min-width: 3000px) {
  .app-container.expanded .content-container .side-menu {
    width: 350px;
  }

  .app-container.expanded .side-body {
    margin-left: 350px;
  }

  .app-container.expanded .content-container .navbar-top {
    padding-left: 350px;
  }
}



// header
@media (max-width: 768px) {
  /* onboarding create vendor form step 2*/
  .create-vendor-container .row.services-btn div {
    margin-bottom: 10px;
  }
  /* --- */

  .navbar-top {
    height: auto;
  }

  ul.dropdown-menu.right-nav-menu {
    margin-top: -20px;
    z-index: 1;
  }

  .nav-box {
    height: 150px;
    margin-top: 30px;
    line-height: 9pt !important;
    width: 90% !important;
    position: fixed;
  }

  .sm-container {
    padding: 20px;
    font-size: 12pt;
  }

  .sm-container div {
    line-height: 20pt;
    color: $white;
  }

  .right-nav-items-sm:hover,
  .sm-container div a:hover {
    opacity: 0.5;
  }

  .sm-container div a {
    color: $white;
  }

  button.navbar-right-expand-toggle.pull-right.visible-xs.hidden-lg-up {
    position: fixed;
  }

  input.search-box-navbar {
    /*margin-top: 75px;*/
  }

  // right navigation
  .row.right-nav-items img {
    display: none;
  }
}

// end of header section

// side by side checkbox
@media (min-width: 992px) {
  .col-lg-2 {
    max-width: 50% !important;
  }
}

@media (min-width: 1000px) {
  .xs-search-bar {
    display: none;
  }
}

// get estimate button
@media (min-width: 1200px) {
  .order-btns .col-xl-2 {
    max-width: 100% !important;
  }

  .navbar-toggleable-md li.nav-item {
    width: 100% !important;
  }

  label.btn.btn-pill-right.btn-right {
    margin-left: -20px;
  }

  .navbar-toggleable-md {
    left: 60% !important;
    width: 85% !important;
  }

}

@media (max-width: 1200px) {
  .right-nav-items {
    margin-left: 550px;
    margin-top: 50px;
    position: fixed !important;
  }
}

@media (max-width: 980px) {
  //hide search bar
  .lg-search-bar {
    display: none;
  }
}

@media(max-width: 768px) {
  .button-margin-top {
    margin-top: $button-margin-top;
  }
  .dropdown-padding {
    padding-right: $padding-right-input;
  }
  .pad-left-card {
    padding-left: 0;
  }

  // for small devices
  footer {
    height: auto !important;
    padding-left: 0;
  }
  body {
    height: auto !important;
  }

  .footer-col-container {
    margin-left: 0 !important;
    padding-left: 20px;
  }

  .footer-col {
    padding-bottom: 50px;
  }

  .footer-col-container .row {
    margin: 0 auto;
  }

  .footer-padding {
    display: none;
  }

  // spacing on setting pages
  ul.ul-company-profile {
    margin-bottom: 50px;
  }
  button.btn.btn-outline-secondary.tip-clear-button {
    min-width: initial;
  }

  main { // clear paddings on estimate and order pages mobile view
    padding-bottom: 0 !important;
  }

  .company-summary .fa-button-outline { // spacing on outline top right icon
    margin: 10px;
  }

  .toolbar-search { // search icon
    // Commented out - this never worked before because it's misspelled
    // margi-top: -60px !important;
  }
}

/* footer section hide elements in bootstrap 4*/
@media screen and (max-width:1150px){
  .lg-dis{
    display: none;
  }
}

@media screen and (max-width:1000px){
  .md-dis{
    display: none;
  }

  .patent-data {
    margin-left: 0 !important;
  }
}

@media screen and (max-width:800px){
  .sm-dis{
    display: none;
  }
}
/* --------- */

// Left navigation mobile view
@media(max-width: 500px) {
  .left-nav-menu {
    padding: 0;
  }
}

// form inputs container on mobile
@media(max-width: 500px) {
  .company-info {
    padding: 0 !important;
  }
}

// offset navigation margin style
@media (min-width: 576px) {
  .offset-sm-1 {
    margin-left: 13% !important;
  }
}

@media (min-width: 900px) {
  .btn-group .col-lg-3 {
    display: inline-table !important;
  }
}

@media (min-width: 768px) {
  .list-padding {
    padding: 10px 0 10px 0;
  }

  button.navbar-right-expand-toggle {
    display: none;
  }
}

@media (max-width: 768px) {
  .btn-group.tip-button-group {
    display: block !important;
  }

  .div-uploader {
    margin-left: 45px;
  }

  .ui-fileupload.ui-widget .ui-button {
    width: 100%;
    margin-bottom: 5px;
  }

  .fu-container {
    display: none;
  }

  .btn-xs {
    display: block;
    width: initial !important;
  }

  button.btn.btn-primary.btn-cta-primary,
  button.btn.btn-secondary.btn-cta-secondary {
    width: $full-width;
  }

  // get rid of top margin
  button.btn.btn-secondary.btn-cta-secondary {
    margin-top: 0;
  }
  // clear wrapping
  .row-text span {
    display: block;
  }

  .right-nav-message,
  .right-nav-alert {
    z-index: 0;
  }

  // Project section
  .activity-log {
    margin-left: 0 !important;
  }

  .timeline-row .card-container {
    margin-top: 10px;
  }

  table.table.finance-summary tr {
    display: grid;
  }
}

@media(max-width: 1200px) and (min-width: 900px) {
  .nav-container {
    max-width: 0 !important;
  }
}

@media (max-width: 1000px) {
  .right-nav-alert {
    margin-top: 40px;
  }
}

@media (max-width: 1300px) {
  .right-nav-message,
  .right-nav-alert {
    left: 60%;
    margin-right: 50px;
  }
}

@media (max-width: 1600px) {
  .right-nav-message,
  .right-nav-alert {
    left: 60%;
  }

  input.search-box-navbar { //header search input box
    max-width: 550px !important;
  }
}

// off canvas in small screen view
@media(max-width: 600px) {
  .sidebar-offcanvas {
    width: 100%;
  }
  .row-offcanvas-right.active {
    .sidebar-offcanvas {
      left: 0 !important;
    }
  }
  .toggle-image {
    background-image: none !important;
  }
  .row.row-offcanvas.row-offcanvas-right.active .btn-outline-secondary.toggle-image button.toggle-close {
    position: absolute;
    margin-top: -30px;
    margin-left: 60px;
  }
  .row.row-offcanvas.row-offcanvas-right.active,
  .btn-outline-secondary.toggle-image:hover,
  .row.row-offcanvas.row-offcanvas-right.active,
  .btn-outline-secondary.toggle-image {
    background: transparent !important;
    border: none;
  }
  .container-fluid { // remove container fluid padding to make the off canvas in full screen
    padding: 0;
  }
  .row {
    padding: 10px;
  }
  .col-6,
  main.col-sm-11{
    max-width: 100%;
  }
}

@media (max-width: 630px) {
  button.btn.btn-outline-secondary.toggle-image {
    margin-top: -80px !important;
  }

  .right-nav-items { // right navigation mobile display
    float: left;
  }

  // Billing info
  .company-header {
    margin-top: 0 !important;
  }

  .card.address-card {
    min-width: 300px;
  }

  .billing-form-card {
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding: 0;
  }

  // card address add left margin on small screen
  .billing-card-block.row .card.address-card.col-lg-12 {
    padding-left: 20px !important;
  }

  .edit-billing-title {
    margin-top: 20px;
    text-align: center;
  }

  //user and teams
  label.btn.btn-pill-right.btn-right { //header right button
    margin-left: -20px !important;
  }
}
@media (max-width: 450px) {
  button.btn.btn-outline-secondary.toggle-image {
    z-index: 11111 !important;
  }
  .navbar-toggleable-md {
    left: 0 !important;
  }
}

// onboarding
@media (max-width: 1200px) {
  .radio-item {
    margin-top: 20px;
  }
  .navbar-toggleable-md {
    position: sticky !important;
  }
}
/* ----- */

/* user and teams */
@media (max-width: 1600px) {

  .user-list-entry .col-teams {
    min-width: 200px;
  }

  .user-list-entry .dropdown {
    min-width: 150px;
  }

  .user-list-entry .btn-user-actions {
    width: auto;
  }

  /* nav icons - message & alert */
  .hidden-md-down {
    min-width: 80% !important;
  }

  .nav-spacer {
    max-width: 20%;
  }
}
/* ----- */

@media (max-width: 975px) {
  .right-nav-items {
    display: none;
  }
}
/* firefox */
@-moz-document url-prefix() {
  /* sidebar scrollbar */

  @media (min-width: 786px) {
    .side-menu-container {
      margin-right: -10px;
    }
  }

  @media (min-width: 899px) {
    .side-menu-container {
      margin-right: -11px;
    }
  }

  @media (min-width: 1024px) {
    .side-menu-container {
      margin-right: -13px;
    }
  }

  @media (min-width: 1138px) {
    .side-menu-container {
      margin-right: -14px;
    }
  }

  @media (min-width: 1252px) {
    .side-menu-container {
      margin-right: -14.9px;
    }
  }

  @media (min-width: 1366px) {
    .side-menu-container {
      margin-right: -17px;
    }
  }

  @media (min-width: 1525px) {
    .side-menu-container {
      margin-right: -19px;
    }
  }


  @media (min-width: 1700px) {
    .side-menu-container {
      margin-right: -21px;
    }
  }

  @media (min-width: 2049px) {
    .side-menu-container {
      margin-right: -26px;
    }
  }

  @media (min-width: 2732px) {
    .side-menu-container {
      margin-right: -34px;
    }
  }

  @media (min-width: 4553px) {
    .side-menu-container {
      margin-right: -50px;
    }
  }
}
/* end firefox styling */


