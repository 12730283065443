@import 'custom_variables';

.tasks-container {
  min-width: 1200px;
}

/* modified style of table header*/

.tasks-container span.fa.fa-fw.fa-caret-down.ui-c {
  margin-top: 5px;
}

.tasks-container .form-group.message-form-container {
  margin-top: 10px;
}

.tasks-container .message-thread p,
.tasks-container townip-message-content,
.tasks-container townip-note-content {
  line-height: 20px;
  width: 100%;
}

/* messages dropdown & modal dropdown styles*/
.tasks-container .messages .ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
  height: 50px;
  line-height: 40px;
  max-width: 350px;
}

.tasks-container input.form-control.thread-search-control { /* search box */
  border: 1px solid #eeeeee;
  padding-left: 30px;
}

.tasks-container span.fa-search-input.search-message { /* search icon */
  margin-top: -25px;
  margin-left: 10px;
  color: #cccccc;
  position: absolute;
}

.tasks-container .btn-cm-prev, /* previous & next button */
.tasks-container .btn-cm-next {
  background: transparent;
  border: none;
  color: #9b9b9b;
}

/* ------------- Tasks Overview style ----------------*/
.tasks-container .project-title-head {
  font-weight: 300;
  letter-spacing: 1.1px;
  text-align: left;
  color: #9b9b9b;
  text-transform: uppercase;
  line-height: 30px;
}

.tasks-container .heading-content {
  font-weight: 300;
  text-align: left;
  color: $gray-dark;
}

.tasks-container .due-date-content {
  font-weight: 300;
  letter-spacing: 1px;
  text-align: left;
  color: $white;
  width: 100px;
  height: 19px;
  border-radius: 4px;
  background-color: $green;
  padding: 5px;
}

.tasks-container .project-client-details,
.tasks-container .company-title,
.tasks-container .client-title,
.tasks-container .company-group {
  color: $green;
}

.tasks-container .project-number {
  font-weight: 500;
  text-align: left;
  color: $gray-dark;
}

.tasks-container .pct-header {
  height: 60px;
  background-color: $green;
  line-height: 58px;
}

.tasks-container .pd-container {
  max-width: 650px;
  margin: 20px auto;
  background-color: $white;
  border: solid 1px $green;
  padding-bottom: 20px;
}

.tasks-container .project-num-title,
.tasks-container .project-cat,
.tasks-container .client-detail,
.tasks-container .project-detail,
.tasks-container .patent-detail {
  font-weight: 300;
  letter-spacing: 1.2px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: middle;
  height: 55px;
  display: table-cell;
}

.tasks-container .project-num-title {
  color: $white;
}

.tasks-container .client-detail,
.tasks-container .patent-detail ,
.tasks-container .project-detail{
  text-transform: capitalize;
}

.tasks-container .project-cat {
  color: #9b9b9b;
}

.tasks-container .project-num {
  text-align: left;
  color: $white;
}

.tasks-container .pin-container i,
.tasks-container .fp-container i {
  padding-right: 5px;
}

.tasks-container .container-tasks {
  border: none;
}

@media (min-width: 768px) {
  .tasks-container .project-num-header {
    max-width: 200px;
  }
}

.tasks-container .pd-row townip-simple-user {
  display: inline-flex;
}

.tasks-container .pd-row {
  min-height: 58px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  margin: 0 auto;
}

.tasks-container .top-border {
  border-top: 1px solid #eeeeee;
}

.tasks-container .pd-row span {
  display: table-cell;
  vertical-align: middle;
  height: 58px;
}
.tasks-container span.fa.fa-fw.fa-caret-down.ui-c {
  height: 45px;
}

.tasks-container .ui-tabview-title {
  font-weight: bold;
  line-height: 2.08;
  letter-spacing: 1.6px;
  text-align: center;
  color: #9b9b9b;
  text-transform: uppercase;
}

.tasks-container .ui-state-active .ui-tabview-title {
  color: $gray-dark;
}

.tasks-container .ui-tabview .ui-tabview-panel {
  background-color: #f8fbfb;
}

.tasks-container .btns-container {
  padding: 0 5px 18px;
  background: #F8FBFB;
  max-width: initial;
}

.estimate-container .total-estimates,
.tasks-container .total-tasks,
.tasks-container .total-projects {
  font-weight: bold;
  letter-spacing: 1.6px;
  color: $gray-dark;
  height: 60px;
  background-color: #f8fffd;
  border: solid 1px #eeeeee;
  padding: 15px;
  text-transform: uppercase;
}

/* estimate */
.townip-table input.form-control {
  height: 35px !important;
  width: 75px;
}
// ----------------------------//
/* details */

.tasks-container .ui-tabview .ui-tabview-panel {
  background-color: #f8fbfb;
}

/* left align column title */
.tasks-container th.ui-state-default {
  text-align: left;
}

.tasks-container .col-name {
  color: #f8fbfb;
}

.tasks-container .client-name {
  color: $gray-dark;
  font-weight: 400;
}

.tasks-container .project-client-name,
.tasks-container .project-p-type {
  color: $green;
  font-weight: 400;
}

// -------- //

.tasks-container .notes-container,
.tasks-container .activity-container {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.deadline-calendar {
  background-color: #FAFAF9;
  border: 1px solid #E1E5E6;
  padding: 30px;
}

.impacted-tasks {
  background-color: rgba(245,166,35,0.07);
  color: #F5A623;
  font-weight: 400;
  border: 1px solid #F5A623;
}

.impacted-tasks hr {
  background: #F5A623;
  width: 95%
}

.deadline-warning {
  padding-top: 15px;
  padding-left: 25px;
}

.modal .fa-star-o, .modal .fa-star {
  font-size: 12px;
}

.rate-vendor .fa-star-o, .modal .fa-star {
  font-size: 25px;
}
