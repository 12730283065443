.ui-virtualscroller.clear-scroller .ui-widget-content {
  border: none;
  background-color: transparent;
}

.ui-virtualscroller.clear-scroller .ui-widget-content li {
  border: none;
}

.ui-virtualscroller.calendar-day-scroller .ui-widget-content li {
    border: 1px solid #DEDEDE;
}
