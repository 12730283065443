@import '../custom_variables';

$border-radius: 10px;

/* PrimeNG Growl Styling*/
p-growl .ui-growl-item-container {
  border-radius: $border-radius;
}

p-growl .ui-shadow {
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
}

p-growl .ui-growl {
  top: 95px;
  border-radius: $border-radius;
  width: 350px;
}

townip-root p-growl .ui-growl.ui-widget {
  z-index: 999999 !important; // p-dialog is 999998. When we display growl message while p-dialog is still open, we want this to be on top.
}

p-growl .ui-growl-item {
  border-radius: $border-radius;
  border: none;
  padding: 0;
}

p-growl .ui-growl-title {
  font-size: 1.2rem;
  font-weight: normal;
  word-wrap: break-word;
}

p-growl .ui-growl-icon-close {
  top: 15px;
  right: 15px;
}

p-growl .ui-growl-image {
  top: 10px;
  left: 19px;
}

p-growl .ui-growl-message {
  padding: 10px 20px;
  border-radius: 0 $border-radius $border-radius 0;
  margin-left: 60px;
}

// severity mode: 'error'
p-growl .ui-growl-message-error .ui-growl-item {
  background-color: #ED4C5F;
  color: $white;
}

p-growl .ui-growl-message-error .ui-growl-title {
  color: $white;
}

p-growl .ui-growl-message-error .ui-growl-message {
  background-color: #F4697A;
  color: #ffe2e6;
}

// severity mode: 'warn'
p-growl .ui-growl-message-warn .ui-growl-item {
  background-color: #F7B039;
  color: $white;
}

p-growl .ui-growl-message-warn .ui-growl-title {
  color: $white;
}

p-growl .ui-growl-message-warn .ui-growl-message {
  background-color: #FEBE54;
  color: #fff5e6;
}

// severity mode: 'success'
p-growl .ui-growl-message-success .ui-growl-item {
  background-color: $green2;
  color: $white;
}

p-growl .ui-growl-message-success .ui-growl-title {
  color: $white;
}

p-growl .ui-growl-message-success .ui-growl-message {
  background-color: $primary;
  color: $white;
}

// severity mode: 'info'
p-growl .ui-growl-message-info .ui-growl-item {
  background-color: #89bfda;
  color: $white;
}

p-growl .ui-growl-message-info .ui-growl-title {
  color: $white;
}

p-growl .ui-growl-message-info .ui-growl-message {
  background-color: #a5c7d8;
  color: $white;
}
