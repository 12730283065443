@import "../custom_variables";

p-autoComplete .ui-autocomplete-panel .ui-widget-content {
  z-index: 1071;
}

p-autoComplete .autocomplete-tags .ui-autocomplete-token {
  background-color: $green;
  color: white;
}

p-autoComplete .autocomplete-tags .ui-autocomplete-multiple-container.ui-state-focus .ui-autocomplete-input-token input:focus {
  border:none;
  box-shadow: none;
}

p-autoComplete .autocomplete-tags .ui-autocomplete-multiple-container {
  @include bs-input;
}

townip-tag-input-autocomplete p-autoComplete .autocomplete-tags .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
  width: 50%;
}

townip-tag-input-autocomplete p-autoComplete .autocomplete-tags .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
  width: 100%;
}
