@import "custom_variables";

/** Use this stylesheet to override fontawesome iccns in prime components **/

.ui-splitbutton.ui-buttonset .ui-button.ui-splitbutton-menubutton .fa:before {
  content: $chevron-down;
  font-family: $font-awesome;
  font-size: x-small ;
}

.ui-splitbutton.ui-buttonset .ui-button.ui-splitbutton-menubutton .fa {
  font-size: x-small;
}

.ui-button-icon-only .fa, .ui-button-text-icon-left .fa, .ui-button-text-icon-right .fa {
  font-size: x-small;
}

.ui-dropdown-trigger .pi:before {
  content: $chevron-down;
  font-family: $font-awesome;
  font-size: x-small ;
}

.ui-multiselect-trigger .pi:before {
  content: $chevron-down;
  font-family: $font-awesome;
  font-size: x-small ;
}
