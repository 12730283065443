.flag-sprite-sm {
  background-image: url('../assets/flags/4x3/flat-sm.png');
  background-repeat: no-repeat;
  display: inline-block;
}

.flag-sprite-sm-ad {
  width: 25px;
  height: 18px;
  background-position: -5px -5px;
}

.flag-sprite-sm-ae {
  width: 25px;
  height: 18px;
  background-position: -40px -5px;
}

.flag-sprite-sm-af {
  width: 25px;
  height: 18px;
  background-position: -75px -5px;
}

.flag-sprite-sm-ag {
  width: 25px;
  height: 18px;
  background-position: -110px -5px;
}

.flag-sprite-sm-ai {
  width: 25px;
  height: 18px;
  background-position: -145px -5px;
}

.flag-sprite-sm-al {
  width: 25px;
  height: 18px;
  background-position: -180px -5px;
}

.flag-sprite-sm-am {
  width: 25px;
  height: 18px;
  background-position: -215px -5px;
}

.flag-sprite-sm-ao {
  width: 25px;
  height: 18px;
  background-position: -250px -5px;
}

.flag-sprite-sm-ap {
  width: 25px;
  height: 18px;
  background-position: -285px -5px;
}

.flag-sprite-sm-aq {
  width: 25px;
  height: 18px;
  background-position: -320px -5px;
}

.flag-sprite-sm-ar {
  width: 25px;
  height: 18px;
  background-position: -355px -5px;
}

.flag-sprite-sm-as {
  width: 25px;
  height: 18px;
  background-position: -390px -5px;
}

.flag-sprite-sm-at {
  width: 25px;
  height: 18px;
  background-position: -425px -5px;
}

.flag-sprite-sm-au {
  width: 25px;
  height: 18px;
  background-position: -460px -5px;
}

.flag-sprite-sm-aw {
  width: 25px;
  height: 18px;
  background-position: -5px -33px;
}

.flag-sprite-sm-ax {
  width: 25px;
  height: 18px;
  background-position: -40px -33px;
}

.flag-sprite-sm-az {
  width: 25px;
  height: 18px;
  background-position: -75px -33px;
}

.flag-sprite-sm-ba {
  width: 25px;
  height: 18px;
  background-position: -110px -33px;
}

.flag-sprite-sm-bb {
  width: 25px;
  height: 18px;
  background-position: -145px -33px;
}

.flag-sprite-sm-bd {
  width: 25px;
  height: 18px;
  background-position: -180px -33px;
}

.flag-sprite-sm-be {
  width: 25px;
  height: 18px;
  background-position: -215px -33px;
}

.flag-sprite-sm-bf {
  width: 25px;
  height: 18px;
  background-position: -250px -33px;
}

.flag-sprite-sm-bg {
  width: 25px;
  height: 18px;
  background-position: -285px -33px;
}

.flag-sprite-sm-bh {
  width: 25px;
  height: 18px;
  background-position: -320px -33px;
}

.flag-sprite-sm-bi {
  width: 25px;
  height: 18px;
  background-position: -355px -33px;
}

.flag-sprite-sm-bj {
  width: 25px;
  height: 18px;
  background-position: -390px -33px;
}

.flag-sprite-sm-bl {
  width: 25px;
  height: 18px;
  background-position: -425px -33px;
}

.flag-sprite-sm-bm {
  width: 25px;
  height: 18px;
  background-position: -460px -33px;
}

.flag-sprite-sm-bn {
  width: 25px;
  height: 18px;
  background-position: -5px -61px;
}

.flag-sprite-sm-bo {
  width: 25px;
  height: 18px;
  background-position: -40px -61px;
}

.flag-sprite-sm-bq {
  width: 25px;
  height: 18px;
  background-position: -75px -61px;
}

.flag-sprite-sm-br {
  width: 25px;
  height: 18px;
  background-position: -110px -61px;
}

.flag-sprite-sm-bs {
  width: 25px;
  height: 18px;
  background-position: -145px -61px;
}

.flag-sprite-sm-bt {
  width: 25px;
  height: 18px;
  background-position: -180px -61px;
}

.flag-sprite-sm-bv {
  width: 25px;
  height: 18px;
  background-position: -215px -61px;
}

.flag-sprite-sm-bw {
  width: 25px;
  height: 18px;
  background-position: -250px -61px;
}

.flag-sprite-sm-bx {
  width: 25px;
  height: 18px;
  background-position: -285px -61px;
}

.flag-sprite-sm-by {
  width: 25px;
  height: 18px;
  background-position: -320px -61px;
}

.flag-sprite-sm-bz {
  width: 25px;
  height: 18px;
  background-position: -355px -61px;
}

.flag-sprite-sm-ca {
  width: 25px;
  height: 18px;
  background-position: -390px -61px;
}

.flag-sprite-sm-cc {
  width: 25px;
  height: 18px;
  background-position: -425px -61px;
}

.flag-sprite-sm-cd {
  width: 25px;
  height: 18px;
  background-position: -460px -61px;
}

.flag-sprite-sm-cf {
  width: 25px;
  height: 18px;
  background-position: -5px -89px;
}

.flag-sprite-sm-cg {
  width: 25px;
  height: 18px;
  background-position: -40px -89px;
}

.flag-sprite-sm-ch {
  width: 25px;
  height: 18px;
  background-position: -75px -89px;
}

.flag-sprite-sm-ci {
  width: 25px;
  height: 18px;
  background-position: -110px -89px;
}

.flag-sprite-sm-ck {
  width: 25px;
  height: 18px;
  background-position: -145px -89px;
}

.flag-sprite-sm-cl {
  width: 25px;
  height: 18px;
  background-position: -180px -89px;
}

.flag-sprite-sm-cm {
  width: 25px;
  height: 18px;
  background-position: -215px -89px;
}

.flag-sprite-sm-cn {
  width: 25px;
  height: 18px;
  background-position: -250px -89px;
}

.flag-sprite-sm-co {
  width: 25px;
  height: 18px;
  background-position: -285px -89px;
}

.flag-sprite-sm-cr {
  width: 25px;
  height: 18px;
  background-position: -320px -89px;
}

.flag-sprite-sm-cu {
  width: 25px;
  height: 18px;
  background-position: -355px -89px;
}

.flag-sprite-sm-cv {
  width: 25px;
  height: 18px;
  background-position: -390px -89px;
}

.flag-sprite-sm-cw {
  width: 25px;
  height: 18px;
  background-position: -425px -89px;
}

.flag-sprite-sm-cx {
  width: 25px;
  height: 18px;
  background-position: -460px -89px;
}

.flag-sprite-sm-cy {
  width: 25px;
  height: 18px;
  background-position: -5px -117px;
}

.flag-sprite-sm-cz {
  width: 25px;
  height: 18px;
  background-position: -40px -117px;
}

.flag-sprite-sm-de {
  width: 25px;
  height: 18px;
  background-position: -75px -117px;
}

.flag-sprite-sm-dj {
  width: 25px;
  height: 18px;
  background-position: -110px -117px;
}

.flag-sprite-sm-dk {
  width: 25px;
  height: 18px;
  background-position: -145px -117px;
}

.flag-sprite-sm-dm {
  width: 25px;
  height: 18px;
  background-position: -180px -117px;
}

.flag-sprite-sm-do {
  width: 25px;
  height: 18px;
  background-position: -215px -117px;
}

.flag-sprite-sm-dz {
  width: 25px;
  height: 18px;
  background-position: -250px -117px;
}

.flag-sprite-sm-ea {
  width: 25px;
  height: 18px;
  background-position: -285px -117px;
}

.flag-sprite-sm-ec {
  width: 25px;
  height: 18px;
  background-position: -320px -117px;
}

.flag-sprite-sm-ee {
  width: 25px;
  height: 18px;
  background-position: -355px -117px;
}

.flag-sprite-sm-eg {
  width: 25px;
  height: 18px;
  background-position: -390px -117px;
}

.flag-sprite-sm-eh {
  width: 25px;
  height: 18px;
  background-position: -425px -117px;
}

.flag-sprite-sm-ep {
  width: 25px;
  height: 18px;
  background-position: -460px -117px;
}

.flag-sprite-sm-er {
  width: 25px;
  height: 18px;
  background-position: -5px -145px;
}

.flag-sprite-sm-es {
  width: 25px;
  height: 18px;
  background-position: -40px -145px;
}

.flag-sprite-sm-et {
  width: 25px;
  height: 18px;
  background-position: -75px -145px;
}

.flag-sprite-sm-eu {
  width: 25px;
  height: 18px;
  background-position: -110px -145px;
}

.flag-sprite-sm-fi {
  width: 25px;
  height: 18px;
  background-position: -145px -145px;
}

.flag-sprite-sm-fj {
  width: 25px;
  height: 18px;
  background-position: -180px -145px;
}

.flag-sprite-sm-fk {
  width: 25px;
  height: 18px;
  background-position: -215px -145px;
}

.flag-sprite-sm-fm {
  width: 25px;
  height: 18px;
  background-position: -250px -145px;
}

.flag-sprite-sm-fo {
  width: 25px;
  height: 18px;
  background-position: -285px -145px;
}

.flag-sprite-sm-fr {
  width: 25px;
  height: 18px;
  background-position: -320px -145px;
}

.flag-sprite-sm-ga {
  width: 25px;
  height: 18px;
  background-position: -355px -145px;
}

.flag-sprite-sm-gb {
  width: 25px;
  height: 18px;
  background-position: -390px -145px;
}

.flag-sprite-sm-gc {
  width: 25px;
  height: 18px;
  background-position: -425px -145px;
}

.flag-sprite-sm-gd {
  width: 25px;
  height: 18px;
  background-position: -460px -145px;
}

.flag-sprite-sm-ge {
  width: 25px;
  height: 18px;
  background-position: -5px -173px;
}

.flag-sprite-sm-gf {
  width: 25px;
  height: 18px;
  background-position: -40px -173px;
}

.flag-sprite-sm-gg {
  width: 25px;
  height: 18px;
  background-position: -75px -173px;
}

.flag-sprite-sm-gh {
  width: 25px;
  height: 18px;
  background-position: -110px -173px;
}

.flag-sprite-sm-gi {
  width: 25px;
  height: 18px;
  background-position: -145px -173px;
}

.flag-sprite-sm-gl {
  width: 25px;
  height: 18px;
  background-position: -180px -173px;
}

.flag-sprite-sm-gm {
  width: 25px;
  height: 18px;
  background-position: -215px -173px;
}

.flag-sprite-sm-gn {
  width: 25px;
  height: 18px;
  background-position: -250px -173px;
}

.flag-sprite-sm-gp {
  width: 25px;
  height: 18px;
  background-position: -285px -173px;
}

.flag-sprite-sm-gq {
  width: 25px;
  height: 18px;
  background-position: -320px -173px;
}

.flag-sprite-sm-gr {
  width: 25px;
  height: 18px;
  background-position: -355px -173px;
}

.flag-sprite-sm-gs {
  width: 25px;
  height: 18px;
  background-position: -390px -173px;
}

.flag-sprite-sm-gt {
  width: 25px;
  height: 18px;
  background-position: -425px -173px;
}

.flag-sprite-sm-gu {
  width: 25px;
  height: 18px;
  background-position: -460px -173px;
}

.flag-sprite-sm-gw {
  width: 25px;
  height: 18px;
  background-position: -5px -201px;
}

.flag-sprite-sm-gy {
  width: 25px;
  height: 18px;
  background-position: -40px -201px;
}

.flag-sprite-sm-hk {
  width: 25px;
  height: 18px;
  background-position: -75px -201px;
}

.flag-sprite-sm-hm {
  width: 25px;
  height: 18px;
  background-position: -110px -201px;
}

.flag-sprite-sm-hn {
  width: 25px;
  height: 18px;
  background-position: -145px -201px;
}

.flag-sprite-sm-hr {
  width: 25px;
  height: 18px;
  background-position: -180px -201px;
}

.flag-sprite-sm-ht {
  width: 25px;
  height: 18px;
  background-position: -215px -201px;
}

.flag-sprite-sm-hu {
  width: 25px;
  height: 18px;
  background-position: -250px -201px;
}

.flag-sprite-sm-id {
  width: 25px;
  height: 18px;
  background-position: -285px -201px;
}

.flag-sprite-sm-ie {
  width: 25px;
  height: 18px;
  background-position: -320px -201px;
}

.flag-sprite-sm-il {
  width: 25px;
  height: 18px;
  background-position: -355px -201px;
}

.flag-sprite-sm-im {
  width: 25px;
  height: 18px;
  background-position: -390px -201px;
}

.flag-sprite-sm-in {
  width: 25px;
  height: 18px;
  background-position: -425px -201px;
}

.flag-sprite-sm-io {
  width: 25px;
  height: 18px;
  background-position: -460px -201px;
}

.flag-sprite-sm-iq {
  width: 25px;
  height: 18px;
  background-position: -5px -229px;
}

.flag-sprite-sm-ir {
  width: 25px;
  height: 18px;
  background-position: -40px -229px;
}

.flag-sprite-sm-is {
  width: 25px;
  height: 18px;
  background-position: -75px -229px;
}

.flag-sprite-sm-it {
  width: 25px;
  height: 18px;
  background-position: -110px -229px;
}

.flag-sprite-sm-je {
  width: 25px;
  height: 18px;
  background-position: -145px -229px;
}

.flag-sprite-sm-jm {
  width: 25px;
  height: 18px;
  background-position: -180px -229px;
}

.flag-sprite-sm-jo {
  width: 25px;
  height: 18px;
  background-position: -215px -229px;
}

.flag-sprite-sm-jp {
  width: 25px;
  height: 18px;
  background-position: -250px -229px;
}

.flag-sprite-sm-ke {
  width: 25px;
  height: 18px;
  background-position: -285px -229px;
}

.flag-sprite-sm-kg {
  width: 25px;
  height: 18px;
  background-position: -320px -229px;
}

.flag-sprite-sm-kh {
  width: 25px;
  height: 18px;
  background-position: -355px -229px;
}

.flag-sprite-sm-ki {
  width: 25px;
  height: 18px;
  background-position: -390px -229px;
}

.flag-sprite-sm-km {
  width: 25px;
  height: 18px;
  background-position: -425px -229px;
}

.flag-sprite-sm-kn {
  width: 25px;
  height: 18px;
  background-position: -460px -229px;
}

.flag-sprite-sm-kp {
  width: 25px;
  height: 18px;
  background-position: -5px -257px;
}

.flag-sprite-sm-kr {
  width: 25px;
  height: 18px;
  background-position: -40px -257px;
}

.flag-sprite-sm-kw {
  width: 25px;
  height: 18px;
  background-position: -75px -257px;
}

.flag-sprite-sm-ky {
  width: 25px;
  height: 18px;
  background-position: -110px -257px;
}

.flag-sprite-sm-kz {
  width: 25px;
  height: 18px;
  background-position: -145px -257px;
}

.flag-sprite-sm-la {
  width: 25px;
  height: 18px;
  background-position: -180px -257px;
}

.flag-sprite-sm-lb {
  width: 25px;
  height: 18px;
  background-position: -215px -257px;
}

.flag-sprite-sm-lc {
  width: 25px;
  height: 18px;
  background-position: -250px -257px;
}

.flag-sprite-sm-li {
  width: 25px;
  height: 18px;
  background-position: -285px -257px;
}

.flag-sprite-sm-lk {
  width: 25px;
  height: 18px;
  background-position: -320px -257px;
}

.flag-sprite-sm-lr {
  width: 25px;
  height: 18px;
  background-position: -355px -257px;
}

.flag-sprite-sm-ls {
  width: 25px;
  height: 18px;
  background-position: -390px -257px;
}

.flag-sprite-sm-lt {
  width: 25px;
  height: 18px;
  background-position: -425px -257px;
}

.flag-sprite-sm-lu {
  width: 25px;
  height: 18px;
  background-position: -460px -257px;
}

.flag-sprite-sm-lv {
  width: 25px;
  height: 18px;
  background-position: -5px -285px;
}

.flag-sprite-sm-ly {
  width: 25px;
  height: 18px;
  background-position: -40px -285px;
}

.flag-sprite-sm-ma {
  width: 25px;
  height: 18px;
  background-position: -75px -285px;
}

.flag-sprite-sm-mc {
  width: 25px;
  height: 18px;
  background-position: -110px -285px;
}

.flag-sprite-sm-md {
  width: 25px;
  height: 18px;
  background-position: -145px -285px;
}

.flag-sprite-sm-me {
  width: 25px;
  height: 18px;
  background-position: -180px -285px;
}

.flag-sprite-sm-mf {
  width: 25px;
  height: 18px;
  background-position: -215px -285px;
}

.flag-sprite-sm-mg {
  width: 25px;
  height: 18px;
  background-position: -250px -285px;
}

.flag-sprite-sm-mh {
  width: 25px;
  height: 18px;
  background-position: -285px -285px;
}

.flag-sprite-sm-mk {
  width: 25px;
  height: 18px;
  background-position: -320px -285px;
}

.flag-sprite-sm-ml {
  width: 25px;
  height: 18px;
  background-position: -355px -285px;
}

.flag-sprite-sm-mm {
  width: 25px;
  height: 18px;
  background-position: -390px -285px;
}

.flag-sprite-sm-mn {
  width: 25px;
  height: 18px;
  background-position: -425px -285px;
}

.flag-sprite-sm-mo {
  width: 25px;
  height: 18px;
  background-position: -460px -285px;
}

.flag-sprite-sm-mp {
  width: 25px;
  height: 18px;
  background-position: -5px -313px;
}

.flag-sprite-sm-mq {
  width: 25px;
  height: 18px;
  background-position: -40px -313px;
}

.flag-sprite-sm-mr {
  width: 25px;
  height: 18px;
  background-position: -75px -313px;
}

.flag-sprite-sm-ms {
  width: 25px;
  height: 18px;
  background-position: -110px -313px;
}

.flag-sprite-sm-mt {
  width: 25px;
  height: 18px;
  background-position: -145px -313px;
}

.flag-sprite-sm-mu {
  width: 25px;
  height: 18px;
  background-position: -180px -313px;
}

.flag-sprite-sm-mv {
  width: 25px;
  height: 18px;
  background-position: -215px -313px;
}

.flag-sprite-sm-mw {
  width: 25px;
  height: 18px;
  background-position: -250px -313px;
}

.flag-sprite-sm-mx {
  width: 25px;
  height: 18px;
  background-position: -285px -313px;
}

.flag-sprite-sm-my {
  width: 25px;
  height: 18px;
  background-position: -320px -313px;
}

.flag-sprite-sm-mz {
  width: 25px;
  height: 18px;
  background-position: -355px -313px;
}

.flag-sprite-sm-na {
  width: 25px;
  height: 18px;
  background-position: -390px -313px;
}

.flag-sprite-sm-nc {
  width: 25px;
  height: 18px;
  background-position: -425px -313px;
}

.flag-sprite-sm-ne {
  width: 25px;
  height: 18px;
  background-position: -460px -313px;
}

.flag-sprite-sm-nf {
  width: 25px;
  height: 18px;
  background-position: -5px -341px;
}

.flag-sprite-sm-ng {
  width: 25px;
  height: 18px;
  background-position: -40px -341px;
}

.flag-sprite-sm-ni {
  width: 25px;
  height: 18px;
  background-position: -75px -341px;
}

.flag-sprite-sm-nl {
  width: 25px;
  height: 18px;
  background-position: -110px -341px;
}

.flag-sprite-sm-no {
  width: 25px;
  height: 18px;
  background-position: -145px -341px;
}

.flag-sprite-sm-np {
  width: 25px;
  height: 18px;
  background-position: -180px -341px;
}

.flag-sprite-sm-nr {
  width: 25px;
  height: 18px;
  background-position: -215px -341px;
}

.flag-sprite-sm-nu {
  width: 25px;
  height: 18px;
  background-position: -250px -341px;
}

.flag-sprite-sm-nz {
  width: 25px;
  height: 18px;
  background-position: -285px -341px;
}

.flag-sprite-sm-oa {
  width: 25px;
  height: 18px;
  background-position: -320px -341px;
}

.flag-sprite-sm-om {
  width: 25px;
  height: 18px;
  background-position: -355px -341px;
}

.flag-sprite-sm-pa {
  width: 25px;
  height: 18px;
  background-position: -390px -341px;
}

.flag-sprite-sm-pct {
  width: 25px;
  height: 19px;
  background-position: -425px -341px;
}

.flag-sprite-sm-pe {
  width: 25px;
  height: 18px;
  background-position: -460px -341px;
}

.flag-sprite-sm-pf {
  width: 25px;
  height: 18px;
  background-position: -5px -369px;
}

.flag-sprite-sm-pg {
  width: 25px;
  height: 18px;
  background-position: -40px -369px;
}

.flag-sprite-sm-ph {
  width: 25px;
  height: 18px;
  background-position: -75px -369px;
}

.flag-sprite-sm-pk {
  width: 25px;
  height: 18px;
  background-position: -110px -369px;
}

.flag-sprite-sm-pl {
  width: 25px;
  height: 18px;
  background-position: -145px -369px;
}

.flag-sprite-sm-pm {
  width: 25px;
  height: 18px;
  background-position: -180px -369px;
}

.flag-sprite-sm-pn {
  width: 25px;
  height: 18px;
  background-position: -215px -369px;
}

.flag-sprite-sm-pr {
  width: 25px;
  height: 18px;
  background-position: -250px -369px;
}

.flag-sprite-sm-ps {
  width: 25px;
  height: 18px;
  background-position: -285px -369px;
}

.flag-sprite-sm-pt {
  width: 25px;
  height: 18px;
  background-position: -320px -369px;
}

.flag-sprite-sm-pw {
  width: 25px;
  height: 18px;
  background-position: -355px -369px;
}

.flag-sprite-sm-py {
  width: 25px;
  height: 18px;
  background-position: -390px -369px;
}

.flag-sprite-sm-qa {
  width: 25px;
  height: 18px;
  background-position: -460px -369px;
}

.flag-sprite-sm-re {
  width: 25px;
  height: 18px;
  background-position: -5px -397px;
}

.flag-sprite-sm-ro {
  width: 25px;
  height: 18px;
  background-position: -40px -397px;
}

.flag-sprite-sm-rs {
  width: 25px;
  height: 18px;
  background-position: -75px -397px;
}

.flag-sprite-sm-ru {
  width: 25px;
  height: 18px;
  background-position: -110px -397px;
}

.flag-sprite-sm-rw {
  width: 25px;
  height: 18px;
  background-position: -145px -397px;
}

.flag-sprite-sm-sa {
  width: 25px;
  height: 18px;
  background-position: -180px -397px;
}

.flag-sprite-sm-sb {
  width: 25px;
  height: 18px;
  background-position: -215px -397px;
}

.flag-sprite-sm-sc {
  width: 25px;
  height: 18px;
  background-position: -250px -397px;
}

.flag-sprite-sm-sd {
  width: 25px;
  height: 18px;
  background-position: -285px -397px;
}

.flag-sprite-sm-se {
  width: 25px;
  height: 18px;
  background-position: -320px -397px;
}

.flag-sprite-sm-sg {
  width: 25px;
  height: 18px;
  background-position: -355px -397px;
}

.flag-sprite-sm-sh {
  width: 25px;
  height: 18px;
  background-position: -390px -397px;
}

.flag-sprite-sm-si {
  width: 25px;
  height: 18px;
  background-position: -425px -397px;
}

.flag-sprite-sm-sj {
  width: 25px;
  height: 18px;
  background-position: -460px -397px;
}

.flag-sprite-sm-sk {
  width: 25px;
  height: 18px;
  background-position: -5px -425px;
}

.flag-sprite-sm-sl {
  width: 25px;
  height: 18px;
  background-position: -40px -425px;
}

.flag-sprite-sm-sm {
  width: 25px;
  height: 18px;
  background-position: -75px -425px;
}

.flag-sprite-sm-sn {
  width: 25px;
  height: 18px;
  background-position: -110px -425px;
}

.flag-sprite-sm-so {
  width: 25px;
  height: 18px;
  background-position: -145px -425px;
}

.flag-sprite-sm-sr {
  width: 25px;
  height: 18px;
  background-position: -180px -425px;
}

.flag-sprite-sm-ss {
  width: 25px;
  height: 18px;
  background-position: -215px -425px;
}

.flag-sprite-sm-st {
  width: 25px;
  height: 18px;
  background-position: -250px -425px;
}

.flag-sprite-sm-sv {
  width: 25px;
  height: 18px;
  background-position: -285px -425px;
}

.flag-sprite-sm-sx {
  width: 25px;
  height: 18px;
  background-position: -320px -425px;
}

.flag-sprite-sm-sy {
  width: 25px;
  height: 18px;
  background-position: -355px -425px;
}

.flag-sprite-sm-sz {
  width: 25px;
  height: 18px;
  background-position: -390px -425px;
}

.flag-sprite-sm-tc {
  width: 25px;
  height: 18px;
  background-position: -425px -425px;
}

.flag-sprite-sm-td {
  width: 25px;
  height: 18px;
  background-position: -460px -425px;
}

.flag-sprite-sm-tf {
  width: 25px;
  height: 18px;
  background-position: -5px -453px;
}

.flag-sprite-sm-tg {
  width: 25px;
  height: 18px;
  background-position: -40px -453px;
}

.flag-sprite-sm-th {
  width: 25px;
  height: 18px;
  background-position: -75px -453px;
}

.flag-sprite-sm-tj {
  width: 25px;
  height: 18px;
  background-position: -110px -453px;
}

.flag-sprite-sm-tk {
  width: 25px;
  height: 18px;
  background-position: -145px -453px;
}

.flag-sprite-sm-tl {
  width: 25px;
  height: 18px;
  background-position: -180px -453px;
}

.flag-sprite-sm-tm {
  width: 25px;
  height: 18px;
  background-position: -215px -453px;
}

.flag-sprite-sm-tn {
  width: 25px;
  height: 18px;
  background-position: -250px -453px;
}

.flag-sprite-sm-to {
  width: 25px;
  height: 18px;
  background-position: -285px -453px;
}

.flag-sprite-sm-tr {
  width: 25px;
  height: 18px;
  background-position: -320px -453px;
}

.flag-sprite-sm-tt {
  width: 25px;
  height: 18px;
  background-position: -355px -453px;
}

.flag-sprite-sm-tv {
  width: 25px;
  height: 18px;
  background-position: -390px -453px;
}

.flag-sprite-sm-tw {
  width: 25px;
  height: 18px;
  background-position: -425px -453px;
}

.flag-sprite-sm-tz {
  width: 25px;
  height: 18px;
  background-position: -460px -453px;
}

.flag-sprite-sm-ua {
  width: 25px;
  height: 18px;
  background-position: -5px -481px;
}

.flag-sprite-sm-ug {
  width: 25px;
  height: 18px;
  background-position: -40px -481px;
}

.flag-sprite-sm-um {
  width: 25px;
  height: 18px;
  background-position: -75px -481px;
}

.flag-sprite-sm-un {
  width: 25px;
  height: 18px;
  background-position: -110px -481px;
}

.flag-sprite-sm-us {
  width: 25px;
  height: 18px;
  background-position: -145px -481px;
}

.flag-sprite-sm-uy {
  width: 25px;
  height: 18px;
  background-position: -180px -481px;
}

.flag-sprite-sm-uz {
  width: 25px;
  height: 18px;
  background-position: -215px -481px;
}

.flag-sprite-sm-va {
  width: 25px;
  height: 18px;
  background-position: -250px -481px;
}

.flag-sprite-sm-vc {
  width: 25px;
  height: 18px;
  background-position: -285px -481px;
}

.flag-sprite-sm-ve {
  width: 25px;
  height: 18px;
  background-position: -320px -481px;
}

.flag-sprite-sm-vg {
  width: 25px;
  height: 18px;
  background-position: -355px -481px;
}

.flag-sprite-sm-vi {
  width: 25px;
  height: 18px;
  background-position: -390px -481px;
}

.flag-sprite-sm-vn {
  width: 25px;
  height: 18px;
  background-position: -425px -481px;
}

.flag-sprite-sm-vu {
  width: 25px;
  height: 18px;
  background-position: -460px -481px;
}

.flag-sprite-sm-wf {
  width: 25px;
  height: 18px;
  background-position: -495px -5px;
}

.flag-sprite-sm-wo {
  width: 25px;
  height: 19px;
  background-position: -425px -340px;
}

.flag-sprite-sm-ws {
  width: 25px;
  height: 18px;
  background-position: -495px -62px;
}

.flag-sprite-sm-xk {
  width: 25px;
  height: 17px;
  background-position: -495px -90px;
}

.flag-sprite-sm-ye {
  width: 25px;
  height: 18px;
  background-position: -495px -117px;
}

.flag-sprite-sm-yt {
  width: 25px;
  height: 18px;
  background-position: -495px -145px;
}

.flag-sprite-sm-za {
  width: 25px;
  height: 18px;
  background-position: -495px -173px;
}

.flag-sprite-sm-zm {
  width: 25px;
  height: 18px;
  background-position: -495px -201px;
}

.flag-sprite-sm-zw {
  width: 25px;
  height: 18px;
  background-position: -495px -229px;
}
