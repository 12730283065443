@import "../custom_variables";

.ui-accordion.ui-widget.ui-helper-reset {
  box-shadow: $default-shadow
}

.ui-accordion .ui-accordion-content {
  border: none;
}

accordion .card {
  width: 100%;
}

.no-shadow .card {
  box-shadow: none;
}


// Styling for vendor / pricing group accordion.
.vendor-pricing-container .ui-accordion .ui-accordion-header.ui-state-active {
  background-color: $green !important;
  font-size: 14px;
}

.vendor-pricing-container .ui-accordion .ui-accordion-header a {
  padding: 10px;
}

.vendor-pricing-container .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon {
  margin-right: 5px;
}

.vendor-pricing-container .ui-accordion .ui-accordion-header {
  border-radius: 0;
  border-width: 1px 0;
}

.vendor-pricing-container .ui-accordion .ui-accordion-header.ui-state-active {
  border: none;
  border-radius: 0;
}

.vendor-pricing-container .ui-accordion .ui-accordion-header.ui-state-active span {
  color: #FFFFFF;
}

.vendor-pricing-container .ui-accordion .ui-accordion-header.ui-state-active a:hover,
.vendor-pricing-container .ui-accordion .ui-accordion-header a:hover {
  text-decoration: unset;
}

.vendor-pricing-container .ui-accordion.ui-widget.ui-helper-reset {
  box-shadow: none;
}

.vendor-pricing-container .ui-accordion-content.ui-widget-content {
  border-width: 0;
}
