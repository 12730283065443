@import '../custom_variables.scss';

tag-input,
tag-input .form-control {
  padding: 0 10px !important;
}

tag-input .ng2-tag-input {
  border-bottom: none !important;
}

tag-input tag-input-form .ng2-tag-input__text-input {
  box-shadow: none;
}

tag-input tag {
  border-radius: 5px !important;
}
tag-input tag:focus,
tag-input tag:active {
  background: $primary !important;
}
