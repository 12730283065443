@import "../custom_variables";

/* Dropdown */
p-dropdown .ui-dropdown:not(.ui-state-disabled):hover{
  background-color: $white;
}

/** There is a bug with the computed dropdown width when a user has the Fuze chrome extension
    enabled. This is a workaround that will allow us to override the width of 30px calculated
    in that situation, but still allow the dropdown width to be correctly calculated when it
    needs to be greater than 150px and Fuze is not installed.**/
p-dropdown .ui-dropdown{
   min-width: 150px;
 }

// Custom dropdown width that set the width to 100%
p-dropdown .ui-dropdown.full-width {
  min-width: 0;
  width: 100%;
}

p-dropdown .ui-dropdown:not(.ui-state-disabled):hover .ui-dropdown-trigger,
p-dropdown .ui-dropdown.ui-state-focus .ui-dropdown-trigger,
p-dropdown .ui-dropdown.ui-state-focus .ui-dropdown-label,
p-dropdown .ui-dropdown:not(.ui-state-disabled):hover .ui-dropdown-label {
  background-color: $white;
}

p-dropdown .ui-dropdown .ui-dropdown-trigger {
  width: 1.8em;
}

p-dropdown .ui-dropdown .ui-dropdown-trigger .pi {
  line-height: 10px;
}

p-dropdown .ui-dropdown:not(.ui-state-disabled):hover .ui-dropdown-trigger .pi,
p-dropdown .ui-dropdown-open .ui-dropdown-trigger .pi {
  color: $green !important;
  transition: color 280ms;
}

p-dropdown .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
  top: 1em;
}

p-dropdown .ui-dropdown-panel .ui-dropdown-filter-container input:focus {
  box-shadow: none;
  border-color: $primary;
}

p-dropdown .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight  {
  background-color: $white;
  color: $green;
  text-decoration: underline;
}

p-dropdown .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item:not(.ui-state-highlight):hover {
  background-color: $white;
  color: $green;
  transition: color 280ms;
}

p-dropdown.fluid-dropdown .ui-dropdown {
  width: 100%;
}

.form-dropdown p-dropdown .ui-dropdown .ui-dropdown-label {
  font-size: 12pt !important;
  padding: .5rem .75rem;
  line-height: 2;
  text-transform: none;
}

.form-dropdown p-dropdown .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  min-height: 50px;
}

/* Force the dropdown panel of source language to always position at the bottom */
.ui-dropdown.source-language-options .ui-dropdown-panel {
  top: auto !important;
}

/** Styles specific to country select */
townip-create-estimate .large-dropdown .ui-inputtext {
  padding-left: 20px !important;
  padding-top: 15px !important;
  font-size: 14.666px !important;
  color: #898989;
  font-weight: normal;
  line-height: 1;
}

townip-create-estimate .large-dropdown .ui-dropdown {
  height: 50px;
  padding-top: 0 !important;
  min-width: 100%;
  border: 2px solid #CECECE;
}



/* large drop down */
.large-dropdown .ui-dropdown {
  height: 50px;
  padding-top: 8px;
}

.large-dropdown .ui-dropdown-label {
  margin: 0;
}

.large-dropdown .ui-inputtext {
  font-size: 13.5pt !important;
  padding: 5px 10px !important;
}

.large-dropdown .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item {
  padding: 5px !important;
  font-size: 13.5pt !important;
}

.onboarding-container .large-dropdown .ui-dropdown {
  height: 48px;
  width: 100% !important;
}

.onboarding-container .large-dropdown .ui-dropdown {
  height: 48px;
  width: 100% !important;
  padding-top: 0;
}

.onboarding-container .large-dropdown .ui-dropdown {
  height: 48px;
  width: 100% !important;
  font-size: 13.5pt !important;
}

.normal-dropdown .ui-dropdown .ui-dropdown-label {
  padding: .35rem 1rem;
}

/** Form classes */
.form-group .ui-dropdown-label {
  text-transform: none;
}

.onboarding-form-container .large-dropdown .ui-dropdown {
  width: 100% !important;
}

.onboarding-form-container label.ui-dropdown-label {
  color: #495057 !important;
}

townip-input-generic-dropdown.ng-touched.ng-invalid .ui-dropdown.ui-widget,
p-dropdown.ng-touched.ng-invalid .ui-dropdown.ui-widget {
  border-color: #dc3545;
}

/* Message filter by */
.navigation-header .message-search-by .ui-dropdown {
  width: 100% !important;
}

.navigation-header .message-search-by .ui-dropdown-label {
  line-height: 2;
}

/** New client Estimate */
p-dropdown.nce-dropdown .ui-dropdown {
  width: 100%;
  height: 57px;
  padding-top: 10px;
  background-color: white;
}

/** Global style to prevent form control borders from being applied to the dropdown */
townip-input-generic-dropdown.form-control {
  border: none !important;
  padding: unset !important;
  border-radius: unset !important;
}

townip-input-generic-dropdown.large-form-dropdown .ui-dropdown {
  border-radius: 2px;
  border: 2px solid #cacaca;
  padding: 0.4rem;
  width: 100%;
}

/** General Translation Bulk Assign - side panel dropdown */
townip-bulk-assign townip-vendor-detail-card-footer-tab p-dropdown .ui-dropdown .ui-dropdown-label {
  font-size: 13px;
  line-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

// Set the z-index of legal translation dropdown to 1090.
// This is special case, because we want it to be higher than the estimate footer that is currently set to 1091
p-dropdown .type-of-legal-translation .ui-dropdown-panel {
  z-index: 1091 !important;
}

// Message List Dropdown
p-dropdown .ui-dropdown.message-search-option label {
  height: 35px;
  padding: 3px 10px 4px;
}

// Complete Project > Billable Task Summary
// default, hover, focus states
.ui-dropdown.billable-task-filter-dropdown,
.ui-dropdown.billable-task-filter-dropdown label.ui-dropdown-label,
.ui-dropdown.billable-task-filter-dropdown:not(.ui-state-disabled):hover label.ui-dropdown-label,
.ui-dropdown.billable-task-filter-dropdown .ui-dropdown-trigger,
.ui-dropdown.billable-task-filter-dropdown:not(.ui-state-disabled):hover .ui-dropdown-trigger,
.ui-dropdown.ui-state-focus.billable-task-filter-dropdown .ui-dropdown-trigger {
  border-radius: 0 10.5px 10.5px 0;
}

// Custom transparent background dropdown
.ui-dropdown.clear-bg:not(.ui-state-disabled) .ui-dropdown-trigger,
.ui-dropdown.clear-bg:not(.ui-state-disabled) .ui-dropdown-label,
.ui-dropdown.clear-bg:not(.ui-state-disabled):hover .ui-dropdown-trigger,
.ui-dropdown.clear-bg.ui-state-focus .ui-dropdown-trigger,
.ui-dropdown.clear-bg.ui-state-focus .ui-dropdown-label,
.ui-dropdown.clear-bg:not(.ui-state-disabled):hover .ui-dropdown-label {
  background-color: transparent;
}
