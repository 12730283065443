p-scrollPanel .estimate-review.ui-scrollpanel {
  height: 90vh;
  border: unset;
}

p-scrollPanel .estimate-review.ui-scrollpanel.flag-cards {
  height: 70vh !important;
}

p-scrollPanel .estimate-review .ui-scrollpanel-bar-x {
  display: none;
}
