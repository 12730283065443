.ui-inputswitch {
    position: relative;
    display: inline-block;
    width: 3em;
    height: 1.75em;
}

.ui-inputswitch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 30px;
}

.ui-inputswitch-slider:before {
    position: absolute;
    content: "";
    height: 1.250em;
    width: 1.250em;
    left: .25em;
    bottom: .25em;
    border-radius: 50%;
    -webkit-transition: .3s;
    transition: .3s;
}

.ui-inputswitch-checked .ui-inputswitch-slider:before {
    -webkit-transform: translateX(1.250em);
    -ms-transform: translateX(1.250em);
    transform: translateX(1.250em);
}

.ui-inputswitch.ui-state-disabled .ui-inputswitch-slider, 
.ui-inputswitch-readonly .ui-inputswitch-slider {
    cursor: default;
}