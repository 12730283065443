townip-check-list p-checkbox .ui-chkbox-label {
  margin-left: 5px;
}

townip-check-list .checklist-group .card {
  padding: 0;
  box-shadow: none;
};

townip-check-list .checklist-group .panel .panel-heading.card-header {
  background-color: transparent;
  border-top: 1px solid #CCCCCC;
  border-radius: 0;
  border-bottom: none;
  margin-left: 0;
  padding: .75rem 0;
};

townip-check-list .checklist-group .panel .panel-heading.card-header:hover {
  background-color: transparent;
}

townip-check-list .checklist-group accordion > accordion-group:first-of-type .card-header {
  border-top: none;
};

townip-check-list .checklist-group accordion accordion > accordion-group:first-of-type .card-header {
  padding-top: 0;
};

townip-check-list .checklist-group .accordion-toggle {
  // Note: remove important when !important from global style has been fixed
  font-size: 0.9rem !important;
  font-weight: 500;
};

townip-check-list .checklist-group accordion accordion .accordion-toggle {
  // Note: remove important when !important from global style has been fixed
  font-weight: 400;
};


townip-check-list .checklist-group .accordion-toggle:hover {
  cursor: pointer;
}

townip-check-list .checklist-group .card-body{
  padding-left: 0;
  padding-right: 0;
};

townip-check-list .checklist-group .panel-collapse.collapse.in.show {
  border-top: 1px solid #CCCCCC;
}

