@import '../custom_variables.scss';

p-slider .ui-slider .ui-slider-range {
  background-color: $green-light;
}

p-slider .ui-slider .ui-slider-handle,
p-slider .ui-slider .ui-slider-handle.ui-slider-handle-active{
  background-color: $white !important;
  border: 1px solid $table-gray;
  width: 13px;
  height: 13px;
}

p-slider .ui-slider .ui-slider-handle:hover{
  background-color: 1px solid $table-gray;
}

p-slider .ui-slider {
  background-color: $table-gray;
  border: none;
  height: 3px;
}

p-slider .ui-slider .ui-slider-range {
  background-color: $green;
}

p-slider .ui-slider.ui-slider-horizontal .ui-slider-handle {
  top: -5px;
}

townip-year-range-slider p-slider .ui-slider .ui-slider-handle {
  background: url('/assets/images/slider/slider-caret.png') 0 0 no-repeat;
}

townip-year-range-slider p-slider .ui-corner-all {
  border-radius: 0;
}

townip-year-range-slider p-slider .ui-slider.ui-slider-horizontal .ui-slider-handle {
  top: -21px;
}

townip-year-range-slider p-slider .ui-slider {
  background-color: $table-gray;
  border: none;
  height: 6px;
}

townip-year-range-slider p-slider .ui-slider .ui-slider-handle,
townip-year-range-slider p-slider .ui-slider .ui-slider-handle.ui-slider-handle-active{
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 0;
  cursor: pointer;
}
