@import "../custom_variables";

/**
  This will set the default active/highlight state for these values accross all prime
  components. These can be overridden when necessary in a component's scss.
 */
body .ui-state-highlight, body .ui-state-active {
  color: $green;
  background-color: $white;
  transition: color 280ms;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border: 1px solid $green;
  background-color: $green;
  color: $white;
}

/** Select Button */
p-selectButton .ui-selectbutton.ui-buttonset {
  box-shadow: $default-shadow;
}

p-selectButton .ui-selectbutton.ui-buttonset .ui-button.ui-state-active,
p-selectButton .ui-selectbutton.ui-buttonset .ui-button:active {
  background-color: $green;
  color: $white;
  transition: all 280ms;
}

p-selectButton .ui-selectbutton.ui-buttonset .ui-button:active {
  box-shadow: $active-shadow;
}

p-selectButton .ui-selectbutton.ui-buttonset .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: $white;
  box-shadow: $hover-shadow;
  transition: all 280ms;
}


/** Prime Breadcrumb */
townip-breadcrumb .ui-breadcrumb-chevron:before {
  font-family: $font-awesome;
  content: $fa-next;
}


/** Fieldset */
p-fieldset .ui-fieldset .ui-fieldset-legend .ui-fieldset-toggler {
  font-size: small;
  color: $green;
  transition: $all-transition;
}

p-fieldset .ui-fieldset .ui-fieldset-legend:hover .ui-fieldset-toggler {
  color: $green2;
  transition: $all-transition;
}

p-fieldset .ui-fieldset .ui-fieldset-legend,
p-fieldset .ui-fieldset .ui-fieldset-legend:hover{
  text-transform: capitalize;
  border: none;
  background-color: $white;
}

p-fieldset .ui-fieldset {
  box-shadow: $default-shadow;
  border: none;
  transition: $all-transition;
  margin-top: 30px;
}

 .ui-fieldset:hover {
  box-shadow: $hover-shadow;
  transition: $all-transition;
}

/** Prime Tooltip */
body .ui-tooltip.ui-widget {
  min-width: fit-content;
}

body .ui-tooltip-text.ui-shadow.ui-corner-all {
  background-color: white;
  color: $green;
  box-shadow: $hover-shadow;
}

.ui-tooltip-text.ui-shadow.ui-corner-all {
  margin: auto;
  max-width: 500px;
}

.ui-tooltip.mw-200 .ui-tooltip-text {
  max-width: 200px !important;
  word-wrap: break-word;
}

.needs-action-tooltip div.ui-tooltip-text.ui-shadow.ui-corner-all {
  background-color: $status-needs-action;
  color: white;
  box-shadow: $hover-shadow;
}

body .ui-tooltip .ui-tooltip-arrow {
  border-top-color: $green;
}

body .ui-tooltip-top .ui-tooltip-arrow {
  bottom: -4px;
  border-width: .5em .5em 0;
}

/** Prime Inplace */
p-inplace .ui-inplace .ui-inplace-display:hover {
  box-shadow: $hover-shadow;
  background-color: transparent;
}

/** Tab View */
p-tabview .ui-tabview-left>.ui-tabview-nav {
  width: 100%;
  height: unset;
}

p-tabview .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, p-tabview .ui-tabview.ui-tabview-left .ui-tabview-nav li:hover {
  background: white;
  border: none;
  border-left: 5px solid #00B1A8;
  border-radius: unset;
}

p-tabView .ui-tabview .ui-tabview-panel {
  padding: 1em;
}

p-tabView .ui-tabview.ui-tabview-left .ui-tabview-nav {
  border-right: unset;
}

p-tabView .ui-tabview.ui-tabview-left .ui-tabview-nav li {
  border-left: 5px solid transparent;
}

p-tabView .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  border: 1px solid transparent;
  border-left: 5px solid #00B1A8;
}

p-tabView .ui-tabview {
  width: fit-content;
}

.full-p-tab p-tabView .ui-tabview {
  width: 100%;
}

/** Side Bar */
p-sidebar .ui-sidebar-top.ui-sidebar-active,
p-sidebar .ui-sidebar-right.ui-sidebar-active,
p-sidebar .ui-sidebar-left.ui-sidebar-active {
  margin-top: $toolbar-height;
}

p-sidebar .ui-sidebar-top.ui-sidebar-active,
p-sidebar .ui-sidebar-bottom.ui-sidebar-active {
  min-height: fit-content;
}

p-fileUpload .ui-button {
  background-color: $green;
}

p-fileUpload .compact-mode .ui-fileupload-content {
  padding: 0;
  margin: 0;
}

p-fileUpload .ui-fileupload-choose:not(.ui-state-disabled):hover,
p-fileUpload .ui-fileupload-choose.ui-state-focus {
  background-color: $green2;
}

p-fileUpload .ui-fileupload .ui-fileupload-buttonbar .ui-messages-icon,
p-fileUpload .ui-fileupload .ui-fileupload-content .ui-messages-icon {
  display: none;
}

body .ui-fileupload .ui-fileupload-content .drag-drop-area {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  z-index: 0;
}

body .ui-fileupload .ui-fileupload-content .ui-fileupload-files,
body .ui-fileupload .ui-fileupload-content p-messages .ui-messages {
  position: relative;
  z-index: 1;
}

.app-expanded .layout-main p-sidebar .ui-sidebar-top {
  margin-left: $expanded-margin;
}

.layout-main p-sidebar .ui-sidebar-top {
  margin-left: $collapsed-margin;
}

  ul.pagination {
  margin-bottom: 0;
}

.pagination-row {
  align-items: center;
}


/** split-button */
.end-splitbutton p-splitButton .ui-menu {
  left: -42px !important;
}

p-tabView .ui-tabview.task-sidebar .ui-tabview-panel {
  padding: 0;
}
