/**
This file should only contain global styles that used in the new settings layout.
Prefixed by the main module component selected.
 - Standard buttons
 - DO NOT USE !important here.
 - If you are using !important, you're probably doing it wrong. (ex: Wrong selector override)
 */

@import '../../stylesheet/custom_variables.scss';

townip-settings-v2 h1 {
  color: $green;
  font-size: 1.4rem;
  font-weight: normal;
}

townip-settings-v2 button.btn-link {
  padding: 0;
}

// This is for buttons that you want to look like a normal link
townip-settings-v2 .btn-link.btn-xs {
  padding: 0;
  font-weight: normal;
  display: inline-block;
  min-width: 0;
  font-size: 1rem;
}
townip-settings-v2 .btn-link.btn-xs:hover {
  text-decoration: none;
  color: darken($green, 20%);
}

townip-settings-v2 .btn-link.btn-xs.dark {
  color: $gray-dark;
}
townip-settings-v2 .btn-link.btn-xs.dark:hover {
  color: lighten($gray-dark, 20%);
}

townip-settings-v2 .btn-lg {
  padding: 1rem 3rem;
  border-radius: 6px;
  font-size: 1rem;
}

townip-settings-v2 .btn-disable {
  color: #CCC !important;
}

townip-settings-v2 .btn-icon {
  background-color: transparent;
  text-align: center;
  padding: 0 2px;
  min-width: 0;
}

townip-settings-v2 .btn-icon .fa {
  margin-right: 0;
}

townip-settings-v2 .pref-title {
  font-weight: bold;
}

townip-settings-v2 accordion .panel-heading.card-header {
  margin-left: 0;
  background-color: #E1E5E6;
  border-bottom: none;
  border-radius: 3px;
  padding: 0.5rem 1.3rem;
}

townip-settings-v2 accordion .panel.panel-open .panel-heading.card-header {
  border-radius: 3px 3px 0 0;
}

townip-settings-v2 accordion .card {
  box-shadow: none;
  padding: 0;
}

townip-settings-v2 accordion .panel-body {
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  border-radius: 0 0 3px 3px;
  padding: 1rem;
}

townip-settings-v2 .basic-filter .field-dropdown {
  min-width: 200px;
}

townip-settings-v2 .basic-filter .field-visibility {
  min-width: 200px;
}

townip-settings-v2 .basic-filter .field-term {
  min-width: 300px;
}


townip-settings-v2 .badge {
  padding: .4em .5em;
}


townip-settings-v2 p-multiSelect.is-invalid .ui-multiselect {
  border-color: #dc3545;
}

// P-Chips style override
townip-settings-v2 p-chips .ui-chips-token.ui-state-highlight {
  padding: 0.3rem 1.2rem 0.3rem 0.8rem;
}

townip-settings-v2 p-chips .ui-chips>ul.ui-inputtext {
  padding: 0 0.8rem;
  border: 2px solid #cacaca;
}

townip-settings-v2 p-chips .ui-chips {
  box-shadow: none;
}

townip-settings-v2 p-chips .ui-chips > ul.ui-inputtext.ui-state-disabled {
  opacity: 1;
}

townip-settings-v2 p-radioButton label {
  margin-bottom: 0;
}

/** Wider headers for pages with no left menu */
.tip-wide-header .app-expanded {
  width: calc(100% - 240px) !important;
}
.tip-wide-header .app-collapsed {
  width: calc(100% - 60px) !important;
}
