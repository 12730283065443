// GLOBAL overrides for Bootstrap's modal
// This stylesheet controls the styling of bootstrap's modal on all PAGES
// For PAGE-SPECIFIC modal overrides, place it on the component's stylesheet
// -----
// Based on the new style-guides
// https://projects.invisionapp.com/d/main#/console/11927627/263377600/comments

@import '../custom_variables';

.modal-dialog {
  margin: 50px 10px;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 50px auto;
  }
}

.modal-content {
  padding: 0 20px;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #979797;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}

.modal-header {
  color: $green;
  font-family: $roboto;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 11px;
  border-bottom: 2px solid #e9ecef;
}

.modal-title {
  font-weight: 400;
  margin-left: auto;
  color: $gray-dark;
  font-size: 20px;
}

.modal-dialog .modal-content button.close {
  color: #B3B4B4;
  font-size: 2.5rem;
}


