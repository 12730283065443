// GLOBAL OVERRIDES for third party controls only, namespaced by the component to prevent leaks.
// All other css, add to service-info.component.scss

townip-service-info accordion-group .card-header {
  padding: .2rem 1.25rem;
}

townip-service-info .panel.townip-finances-accordion .panel-heading.card-header {
  background-color: #F5F6F8;
  line-height: 1rem;
  padding: .4rem 0.5rem;
}

townip-service-info .panel.townip-finances-accordion .card-body {
  padding: 0.5rem;
}

townip-service-info townip-proposed-due-date .panel.townip-finances-accordion .panel-body {
  padding: 0;
}
