@import "../custom_variables";

p-chips .ui-chips {
  height: auto;
}

p-chips .ui-chips-token.ui-state-highlight {
  background-color: $green;
  color: $white;
  margin-bottom: 2px;
}


p-chips .ui-chips-token .ui-chips-token-icon {
  margin-top: -6px;
  font-size: 11px;
}

p-chips .ui-chips-input-token input {
  box-shadow: none;
}

townip-q-country-select p-chips .ui-chips-input-token,
townip-country-selector p-chips .ui-chips-input-token {
  height: 45px;
}

townip-q-country-select .ui-chips-input-token input,
townip-country-selector .ui-chips-input-token input {
  display: none;
}

townip-q-country-select p-chips .ui-chips-token.ui-state-highlight,
townip-country-selector p-chips .ui-chips-token.ui-state-highlight {
  padding: 0.3rem 1.2rem 0.3rem 0.8rem;
}

p-chips .bs-input {
  display: block;
  box-shadow: none
}

p-chips .bs-input .ui-inputtext {
  @include bs-input;
  padding: .55rem 1rem;
}

