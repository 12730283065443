@import '../custom_variables.scss';

p-panel .ui-panel {
  border: none;
}

p-panel .ui-panel .ui-panel-titlebar {
  background-color: #E1E5E6;
}

p-panel .ui-panel-title {
  font-size: 20px;
  font-weight: bold;
  color: $green;
}

p-panel .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
   float: left;
   top: 4px;
   font-size: large;
   margin-right: 10px;
 }

p-scrollPanel .ui-scrollpanel-bar-x {
  display: none;
}
