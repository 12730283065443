.ui-radiobutton {
    display:inline-block;
    cursor: pointer;
    vertical-align: middle;
    margin-right: .25em;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.ui-radiobutton-box {
    width: 1.125em;
    height: 1.125em;
    line-height: 1.125em;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    text-align: center;
    position: relative;
}

.ui-radiobutton-icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1em;
    height: 1em;
    margin-top: -.5em;
    margin-left: -.5em;
}
 
.ui-radiobutton {
    vertical-align: middle;
}