@import "custom_variables";

.app-pref-container {
  width: 1000px;
}

/* 3 buttons layout */
.app-pref-container .settings-pill.btn-group.btn-pill-group label {
  width: 180px !important;
}

.app-pref-container label.btn.app-btn-company {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.app-pref-container label.btn.btn-pill-right.button3,
.app-pref-container label.btn.btn-pill-right.button3.app-user-pill.ng-valid {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: 0;
  border-left: 0;
}

.app-pref-container label.btn.btn-pill-right.button3.app-btn-user {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  margin-left: -30px !important;
}

.app-pref-container label.btn.btn-pill-middle:hover {
  color: #ffffff;
}

.app-pref-container label.btn.app-user-company {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.app-pref-container .btn-pill-group label.btn-pill-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.app-pref-container  label.btn.btn-pill-left.ng-valid.ng-touched.active,
.app-pref-container  label.btn.btn-pill-left.ng-untouched.ng-pristine.ng-valid.active {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
/* --- */


/* 3 buttons */
.app-pref-container label.btn.btn-pill-middle {
  color: $green;
}

.app-pref-container label.btn.btn-pill-middle.active {
  color: #ffffff;
  box-shadow: none;
  z-index: 11;
}
/* end */


.app-pref-container .btn-app-preferences {
  padding-bottom: 20px;
  margin-left: 20px;
}

.app-pref-container .btn-app-preferences label {
  /* transition: all .2s ease-in-out !important; */
  height: 50px;
  transition: none;
}

.app-pref-container .btn-app-preferences label:hover {
  background: linear-gradient(180deg, $green 0%, #34907B 100%);
  color: #ffffff;
  transition: none;
}

.app-pref-container .btn-app-preferences label.active {
  background: linear-gradient(180deg, $green 0%, #34907B 100%);
  color: #ffffff;
  border: 1px solid $green;
}

.app-pref-container .btn-app-preferences label.btn-company {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.app-pref-container .btn-app-preferences label.btn-user {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.app-pref-container .btn-group.btn-app-preferences label {
  padding: 12px;
  padding-left: 60px;
  padding-right: 60px;
  border: 1px solid #979797;
}


.app-pref-container .tip-clear-button {
  padding: .3rem .5rem;
  margin: -5px 0 0 10px;
}

.app-pref-container .message-button {
  float: right;
  margin-top: -32px;
}

.app-pref-container .flag-btn-img {
  /*margin-top: -15px;*/
}

/* drop down padding */
.app-pref-container .ui-select-container.dropdown {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* 21 languages */
.app-pref-container button.text-button.data-button {
  margin-top: 0;
}
.app-pref-container .row.data-container {
  padding: 20px 0;
}

.app-pref-container .row.data-container input[type="radio"] { /* Yes/NO radio button spacer */
  margin-right: 20px;
}

/* accordion container padding left */

.app-pref-container .contact-name {
  display: inline-block;
  width: 80%;
  padding-right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.app-pref-container .tip-button-img {
  margin-top: -12px;
}

.app-pref-container .billing-info {
  margin-top: 20px;
}

@media(min-width: 500px) {
  .app-pref-container .panel-body.card-block {
    padding-left: 45px;
  }
}



/**
  Handling Cards
 */
.app-pref-container .has-shadow .card {
  box-shadow: $default-shadow !important;
  margin-bottom: 20px;
  transition: box-shadow 280ms;
}
.app-pref-container .has-shadow .card:hover {
  box-shadow: $hover-shadow !important;
  transition: box-shadow 280ms;
}

.app-pref-container .card-header {
  background-color: white;
}

.app-pref-container .card-header {
  border-bottom: none;
}

.app-pref-container .card {
  width: 100%;
}

.app-pref-container .panel-body.card-block {
  margin-top: -40px;
  margin-left: -5px;
}

.app-pref-container .pref-container {
  overflow: hidden;
}

.app-pref-container .pref-heading .fa.pull-left {
  margin: 2px 0 0 0;
}

.app-pref-container .billing-card-block.row .form-container {
  background-color: #FFFFFF;
  margin-left: 33px;
  width: 100%;
  max-width: 550px;
}

.app-pref-container .accordion-toggle .pref-heading {
  font-family: Arvo-Regular;
  color: $gray-dark;
}

.app-pref-container .accordion-heading-title {
  margin-top: -35px;
  margin-bottom: 20px;
  margin-left: 88px;
  font-weight: 400;
}

.app-pref-container .preferred-country-container {
  background-color: #ebf6f4;
  padding: 15px;
  margin-bottom: 20px;
}

.app-pref-container .flag-btn-img {
  padding-right: 2px;
}

.app-pref-container input.form-control.ui-select-search {
  line-height: 1.8;
}

.app-pref-container .row.data-container {
  margin-left: 0;
}

.app-pref-container .accordion-child-div {
  margin-top: 0;
  margin-left: 67px;
}

.app-pref-container .accordion-child-div h4 {
  font-family: $font-family-default;
  color: $gray-dark;
}

.app-pref-container .add-jurisdiction {
  margin-top: -20px;
}

.app-pref-container .pref-heading .make-default-btn {
  margin-left: 20px;
}

.app-pref-container .pref-name .text-button.make-default-btn {
  margin-top: -1rem !important;
}

.app-pref-container input.form-control.ui-select-search,
.app-pref-container .ui-select-placeholder {
  font-family: Arvo-regular;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  line-height: 2;
  height: 45px;
}

/* column height */
.app-pref-container .ui-datatable .ui-datatable-data > tr > td {
  padding: 0;
  text-align: center;
  word-wrap: break-word;
}

.app-pref-container .col-name {
  color: #f8fbfb;
}

.app-pref-container .client-name {
  /* color: $green; */
  color: $gray-dark;
  font-weight: 400;
}

.app-pref-container .project-client-name,
.app-pref-container .project-p-type {
  color: $green;
  font-weight: 400;
}
/* start */
.app-pref-container .project-view-dropdown {
  text-align: right;
}

.app-pref-container .project-view-dropdown span.fa.fa-fw.fa-caret-down.ui-c,
.app-pref-container .task-view-dropdown span.fa.fa-fw.fa-caret-down.ui-c  {
  margin-top: 0;
}

.app-pref-container .show>.dropdown-menu {
  position: absolute;
  width: 175px;
  right: 0;
  left: auto;
  border: 1px solid $gray-dark;
}

/* billing settings */
.billing-set-container .address-card {
  background-color: #FFFFFF;
  border: 1px solid $green;
  border-radius: 2px;
  word-wrap: break-word;
}
.billing-set-container .billing-card-block.row .card.address-card.col-lg-12 {
  padding-left: 40px;
}

.billing-set-container .txt-title {
  color: $green;
}

.billing-set-container .btn-add {
  padding-left: 0;
}

.billing-set-container .billing-buttons {
  padding: 15px;
}


/* External Contacts */

.external-set-container .col-lg-12.external-contacts {
  padding-bottom: 20px;
}

.external-set-container span.accordion-heading-title {
  margin-left: 0;
}

.external-set-container label.btn.btn-pill-right.right-button {
  margin-left: -20px;
}

.external-set-container label.btn.btn-pill-left.ng-valid.ng-touched.active,
.external-set-container label.btn.btn-pill-left.ng-untouched.ng-pristine.ng-valid.active {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media (max-width: 768px) {
  .external-set-container label.btn.btn-pill-right.right-button {
    margin-left: 0;
  }
}
/* ----------- */

/* Create external teams component */
.external-set-container .name-text-margin {
  margin-top: 0;
  line-height: 20px;
}

/* external companies */
@media (max-width: 580px) {
  .external-set-container .initial {
  }

  .external-set-container.name-text-margin {
    margin-top: 20px;
    margin-left: 65px;
    padding-left: 0;
  }

  .external-set-container .row-contact-team .col-sm-1 {
    width: 50px;
  }
}

@media (min-width: 1100px) {
  .external-set-container.name-text-margin {
    padding-left: 0;
  }
}
/* ------- */

.settings-wide {
  max-width: 1100px;
  min-width: 800px;
}

.settings-x-wide {
  max-width: 1600px;
  min-width: 1400px;
}

.container.manage-user-container {
  width: 1100px !important;
  min-width: 1100px !important;
}

.end-client-container, .org-set-container, .external-set-container,
.billing-set-container, .company-vendor-container {
  min-width: 800px;
  max-width: 50%;
}
