/*
NOTE:
Only place global-specific overrides in this stylesheet, namespaced by the component
 */

townip-header-filter p-multiSelect .ui-multiselect {
  width: 100%;
  padding-right: 0;
}

townip-header-filter p-multiSelect .ui-multiselect .ui-multiselect-trigger {
  background-color: #FFFFFF;
}

townip-header-filter p-calendar {
  width: 100%;
}

townip-header-filter p-calendar .ui-calendar {
  width: 100%;
}

townip-header-filter p-dropdown .ui-dropdown {
  width: 100% !important;
  border: none;
}

townip-header-filter {
  background: white;
}

