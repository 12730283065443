.ui-paginator {
    margin: 0;
    text-align: center;
    padding: .125em;
}

.ui-paginator .ui-paginator-top {
    border-bottom: 0 none;
}

.ui-paginator .ui-paginator-bottom {
    border-top:0 none;
}

.ui-paginator .ui-paginator-left-content {
    float: left;    
}

.ui-paginator .ui-paginator-right-content {
    float: right;    
}

.ui-paginator .ui-paginator-page,
.ui-paginator .ui-paginator-pages,
.ui-paginator .ui-paginator-next,
.ui-paginator .ui-paginator-last,
.ui-paginator .ui-paginator-first,
.ui-paginator .ui-paginator-prev,
.ui-paginator .ui-paginator-current {
    display: inline-block;
    min-width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
	zoom: 1;
	margin-left: .063em;
	margin-right: .063em;
	text-decoration: none;
    vertical-align: middle;
    text-align: center;
    position: relative;
}

.ui-paginator .ui-paginator-pages {
    width: auto;
    line-height: 1;
}

.ui-paginator .ui-paginator-icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1em;
    height: 1em;
    margin-top: -.5em;
    margin-left: -.5em;
}

.ui-paginator .ui-paginator-page,
.ui-paginator .ui-paginator-next,
.ui-paginator .ui-paginator-last,
.ui-paginator .ui-paginator-first,
.ui-paginator .ui-paginator-prev{
    cursor: pointer;
}

.ui-paginator .ui-paginator-current,
.ui-paginator .ui-paginator-rpp-options {
	margin-left: 1em;
	margin-right: 1em;
    background-image: none;
}

.ui-paginator .ui-paginator-jtp-select option,
.ui-paginator .ui-paginator-rpp-options option {
    background-image: none;
    border: 0 none;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.ui-paginator a.ui-state-disabled {
    outline: 0 none;
}

.ui-paginator .ui-dropdown {
    min-width: 4em;
    margin-left: .375em;
}

.ui-fluid .ui-paginator .ui-dropdown {
    width: auto;
}