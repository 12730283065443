.ui-menu {
    width: 12.5em;
    padding: .25em;
}

.ui-menu.ui-menu-dynamic {
    position: absolute;
}

.ui-menu .ui-menu-separator {
    border-width: 1px 0 0 0;
}

.ui-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ui-menu .ui-submenu-header {
    padding: .25em .5em;
    margin: .125em 0;
}

.ui-menu .ui-menuitem {
    margin: .125em 0;
}

.ui-menu .ui-menuitem-link {
    cursor: pointer;
    padding: .25em;
    display: block;
    text-decoration: none;
}

.ui-menu .ui-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.ui-menu .ui-menuitem-text {
    vertical-align: middle;
}