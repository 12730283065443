.ui-tooltip {
    position:absolute;
    display:none;
    padding: .25em .5em;
    max-width: 12.5em;
}

.ui-tooltip.ui-tooltip-right,
.ui-tooltip.ui-tooltip-left {
    padding: 0 .25em;
}

.ui-tooltip.ui-tooltip-top,
.ui-tooltip.ui-tooltip-bottom {
    padding:.25em 0;
}

.ui-tooltip .ui-tooltip-text {
   padding: .125em .5em;
   background-color: rgb(76, 76, 76);
   color: #ffffff;
   white-space: pre-line;
}

.ui-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.ui-tooltip-right .ui-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -.25em;
    border-width: .25em .25em .25em 0;
    border-right-color: rgb(76, 76, 76);
}

.ui-tooltip-left .ui-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -.25em;
    border-width: .25em 0 .25em .25em;
    border-left-color: rgb(76, 76, 76);
}

.ui-tooltip.ui-tooltip-top {
    padding: .25em 0;
}

.ui-tooltip-top .ui-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -.25em;
    border-width: .25em .25em 0;
    border-top-color: rgb(76, 76, 76);
}

.ui-tooltip-bottom .ui-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -.25em;
    border-width: 0 .25em .25em;
    border-bottom-color: rgb(76, 76, 76);
}