@import "custom_variables";

.navbar-icon {
  padding: 10px 0;
  width: 25px;
}

.panel-heading h4 {
  margin: 0;
}

a.nav-link.side-nav-link {
  display: inline-block;
}

a.nav-link.side-nav-link.active,
.navbar-nav .nav-link.active {
  background-position: left bottom;
  color: $white;
}

.navbar-light .navbar-nav .nav-link {
  width: 100%;
}

.navbar-nav {
  width: 240px;
}

.side-menu .navbar  {
  height: 100%;
}

.panel-collapse.collapse.in.show {
  margin-top: 0;
  padding-left: 0;
}

.nav-fa {
  font-size: 26pt;
  padding: 17px;
}

.brand-img {
  margin-top: 4px;
  width: 84px;
  height: auto;
  margin-left: 181px;

  // Transition default to NONE (for IE and Firefox)
  transition: none;
  -moz-transition: none !important;
  -o-transition: none !important;
  -webkit-transition: margin-left 0.3s;
}

.layout-sidebar:hover .brand-img {
  margin-left: 4px;
}

.app-expanded .brand-img {
  margin-left: 4px;
}

.navbar-image {
  /* margin: 11px 15px 40px 15px; */
  width: 30px;
  height: 25px;
}

.title.brand {
  color: $white;
  font-family: $roboto;
}

.navbar-top {
  height: 80px;
}

a.navbar-brand { // app logo background
  background: $green;
}

.navbar-inverse { // nav header background
  background-color: $green !important;
  border-color: $green !important;
  transition: all 0.25s linear;

}

.side-nav-text {
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 600;
  color: $blue-accent;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  float: left;
}

.nav-link.active .side-nav-text,
.nav-link:hover .side-nav-text{
  color: white;
}

.side-menu-container {
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}

.nav-scrollpanel.ui-widget-content {
  background-color: transparent;
  border: none;
  border-radius: 0;
  height: calc(100% - 70px);
}

.nav-scrollpanel.ui-scrollpanel:hover .ui-scrollpanel-bar,
.nav-scrollpanel.ui-scrollpanel:active .ui-scrollpanel-bar {
  opacity: 0.4;
}

.nav-scrollpanel .ui-scrollpanel-bar-y {
  width: 5px;
}

.nav-scrollpanel .ui-scrollpanel-bar-x {
  display: none;
}
