// Contains Global Style Changes for the p-paginator (GLOBAL ONLY, e.g changes the looks the component on all pages)
// Always place them under p-paginator scope! so it won't leak to other prime components

@import "../custom_variables";

p-paginator .ui-paginator {
  background-color: transparent;
  border-color: transparent;
}

p-paginator .ui-paginator .ui-paginator-pages {
  padding: 0 0.5rem;
  min-height: 1.875em;
}

p-paginator .ui-paginator.ui-paginator-bottom .ui-paginator-pages .ui-paginator-page {
  margin: 0 5px;
}


p-paginator .ui-paginator .ui-paginator-element {
  text-align: center;
}

p-paginator .ui-paginator .ui-paginator-pages .ui-paginator-page {
  background-color: $green-accent;
  border-radius: 3px;
  color: $green2;
  padding: 0.2rem 0.4rem;
  min-width: 1.5rem;
  height: 100%;
}

p-paginator .ui-paginator .ui-paginator-pages .ui-paginator-page:hover{
  background-color: $green-accent;
  color: $green2;
}

p-paginator .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active,
p-paginator .ui-paginator .ui-paginator-pages .ui-paginator-page:hover.ui-state-active {
  color: $white;
  background-color: $green;
}

p-paginator .ui-paginator .ui-paginator-first,
p-paginator .ui-paginator .ui-paginator-prev,
p-paginator .ui-paginator .ui-paginator-next,
p-paginator .ui-paginator .ui-paginator-last {
  background-color: transparent;
  border-radius: 3px;
  color: $gray-dark;
  padding: 0.2rem 0.4rem;
  min-height: 1.875em;
}

p-paginator .ui-paginator .ui-paginator-first:hover,
p-paginator .ui-paginator .ui-paginator-prev:hover,
p-paginator .ui-paginator .ui-paginator-next:hover,
p-paginator .ui-paginator .ui-paginator-last:hover {
  background-color: transparent;
  color: $gray-dark;
}

p-paginator .ui-paginator-rpp-options {
  padding: 0.2rem 0.4rem;
  border-radius: 3px
}

p-paginator .ui-paginator-first .fa:before {
  font-family: $font-awesome;
  content: $fa-first;
}
p-paginator .ui-paginator-prev .fa:before {
  font-family: $font-awesome;
  content: $fa-prev;
}
p-paginator .ui-paginator-next .fa:before {
  font-family: $font-awesome;
  content: $fa-next;
}
p-paginator .ui-paginator-last .fa:before {
  font-family: $font-awesome;
  content: $fa-last;
}

p-paginator .ui-paginator .ui-paginator-element {
  box-shadow: $default-shadow;
  margin: 5px;
}
p-paginator .ui-paginator .ui-paginator-element:hover {
  box-shadow: $hover-shadow;
}
p-paginator .ui-paginator .ui-paginator-element:active {
  box-shadow: $active-shadow;
}

// Design for the new TownIP paginator
townip-paginator p-paginator .ui-paginator {
  height: 35px;
}

townip-paginator p-paginator .ui-paginator .ui-paginator-element {
  box-shadow: none;
  border: 1px solid #E0E4E5;
  margin: 0;
}

townip-paginator p-paginator .ui-paginator .ui-paginator-first,
townip-paginator p-paginator .ui-paginator .ui-paginator-prev,
townip-paginator p-paginator .ui-paginator .ui-paginator-next,
townip-paginator p-paginator .ui-paginator .ui-paginator-last {
  background-color: $green-light;
  color: $green;
  padding: 7px 14px;
  font-weight: 500;
  height: 100%;
}

townip-paginator p-paginator .ui-paginator .ui-paginator-next {
  margin-left: -6px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

townip-paginator p-paginator .ui-paginator .ui-paginator-first,
townip-paginator p-paginator .ui-paginator .ui-paginator-last {
  border-radius: 5px;
  width: 50px;
}

townip-paginator p-paginator .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active,
townip-paginator p-paginator .ui-paginator .ui-paginator-pages .ui-paginator-page:hover.ui-state-active {
  color: $white;
  background-color: $green;
  border-color: $green;
}

townip-paginator p-paginator .ui-paginator .ui-paginator-prev {
  margin-right: -6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

townip-paginator p-paginator .ui-paginator .ui-paginator-pages .ui-paginator-page:hover{
  background-color: $green-light;
}

townip-paginator p-paginator .ui-paginator .ui-paginator-pages .ui-paginator-page {
  background-color: $green-light;
  color: $green;
  min-width: 1.5rem;
  padding: 7px 14px;
  height: 100%;
  border-radius: 0;
  margin: 0;
  border-right: 0;
  font-weight: 500;
}

townip-paginator p-paginator .ui-paginator .ui-paginator-pages {
  height: 100%;
}
