@import '../../stylesheet/custom_variables';

p-table .preference .ui-widget-header {
  border: none;
  margin-bottom: 15px;
}

p-table .preference tr {
  border-bottom: 1px solid #EEEEEE;
}

p-table .stage-details thead tr th {
  background-color: #4A4C4E;
  background-clip: padding-box;
}

p-table .stage-details thead tr th:first-child {
  border-radius: 5px 0 0 0;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;

  background-color: #4A4C4E;
  background-clip: padding-box;
}

p-table .stage-details thead tr th:last-child {
  border-radius: 0 5px 0 0;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;

  background-color: #4A4C4E;
  background-clip: padding-box;
}

p-table .stage-details .ui-table-summary {
  background: #676E77;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-clip: padding-box;
}

p-table .preference .ui-table-scrollable-wrapper table {
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

p-table .preference .ui-table-scrollable-wrapper table thead{
  background-color: #E1E5E6;
}

// Patent List Selector Table Styling
p-table .model-list .ui-widget-header {
  border: unset;
  background-color: #E2E4E7;
  padding: 10px 0 3px 10px;
}

p-table .model-list th.ui-sortable-column.ui-state-highlight {
  background-color: #E2E4E7;
  color: #000000;
}

// p-table design specific for finance tab table
p-table .finance-table.ui-table .ui-table-wrapper .ui-table-thead tr th.ui-sortable-column.ui-state-highlight {
  background-color: #E2E4E7;
}

p-table .finance-table.ui-table .ui-table-wrapper .ui-table-tbody tr {
  height: 60px;
  border-bottom: 1px solid #BDBDBD
}

p-table .finance-table.ui-table p-paginator .ui-paginator-bottom.ui-paginator {
  text-align: right;
  padding-top: 15px;
  padding-bottom: 15px;
}

p-table .fa-sort-up:before,
p-table .fa-sort-asc:before {
  content: $chevron-up;
}

p-table .fa-sort-down:before,
p-table .fa-sort-desc:before {
  content: $chevron-down;
}

p-table .fa-sort-up,
p-table .fa-sort-asc,
p-table .fa-sort-down,
p-table .fa-sort-desc {
  display: inline-block !important;
  font-size: x-small;
}

p-table .fa-sort:not(.fa-sort-asc), p-table .fa-sort:not(.fa-sort-up),
p-table .fa-sort:not(.fa-sort-desc), p-table .fa-sort:not(.fa-sort-down) {
  display: none;
}

// Prime table design for General Translation Files table
p-table .general-translation-files-table.ui-table .ui-table-wrapper .ui-table-thead tr th {
  height: 60px;
  background-color: #E1E5E6;
  border-radius: 2px;
  color: #495057;
}

p-table .general-translation-files-table.ui-table .ui-table-wrapper .ui-table-tbody tr td {
  height: 60px;
  border-bottom: 1px solid #B8B8B8;
}

p-table .action-bulk-table tr {
  border-top: 1px solid #000000;
}

/* [START] Styling for tables migrated from p-dataTable */
.standard-table.ui-table, .standard-table.ui-table.ui-widget {
  font-size: 10pt;
}

.standard-table.ui-table .ui-table-thead > tr {
  border-bottom: 2px solid #00B1A8;
}

.standard-table.ui-table .ui-table-thead > tr > th {
  padding: 11px 11px 8px;
  background: #E2E4E7;
  border: none;
  text-align: center;
  vertical-align: bottom;
  font-weight: normal;
  color: #39393a;
}

.standard-table.ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #E2E4E7;
}

.standard-table.ui-table .ui-table-thead > tr > th .column-title {
  letter-spacing: 1.1px;
  color: #5C5A5A;
  font-weight: 500;
}

.standard-table.ui-table .ui-table-thead > tr > th input.ui-inputtext {
  padding: $input-padding-y $input-padding-x;
  height: $input-height;
  border-radius: $input-border-radius;
}

.standard-table.ui-table .ui-table-thead > tr > th .clear-btn-visible .date-only-picker-input.ui-inputtext {
  padding-right: 2rem;
}

.standard-table.ui-table .ui-table-tbody > tr {
  height: 47px;
  background: #ffffff;
  margin-bottom: 5px;
  border: 1px solid #eeeeee;
}

.standard-table.ui-table .ui-table-tbody > tr:hover:not(.no-hover) {
  background: #f1f1f1;
  border-bottom: 1px solid #fafafa;
}

.standard-table.ui-table .ui-table-tbody > tr > td {
  padding: 0 5px 0 5px;
  text-align: center;
  word-wrap: break-word;
  border: none;
  font-weight: normal;
  color: #39393a;
}

.standard-table.ui-table .ui-table-tbody > tr > td:first-child {
  padding-left: 10px;
}

.standard-table.ui-table .ui-table-tbody > tr > td:last-child {
  padding-right: 10px;
}

.standard-table.ui-table .ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
  height: 29px;
  vertical-align: bottom;
  border-radius: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #FFFFFF;
  margin-top: 1px;
  border: none;
}

.standard-table.ui-table .ui-table-loading {
  opacity: 0.1;
}

.standard-table.ui-table .ui-paginator {
  text-align: right;
  padding-top: 15px;
  padding-bottom: 15px;
}

.standard-table p-dropdown .ui-dropdown {
  min-width: 0;
}

.standard-table p-dropdown .ui-dropdown .ui-dropdown-panel {
  min-width: 180px;
}

/* [END] Styling for tables migrated from p-dataTable */

.standard-table.ui-table.new-table-design .ui-table-thead tr th {
  padding: 1rem;
  height: 50px;
}

.standard-table.ui-table.new-table-design .ui-table-tbody tr {
  height: 60px;
  border-bottom: 1px solid #E4E4E4 !important;
}

.standard-table.ui-table.new-table-design .ui-table-tbody tr {
  border-left-color: transparent;
  border-right-color: transparent;
}

.standard-table.ui-table.new-table-design .ui-table-tbody > tr > td:first-child {
  border-left: none;
}

.standard-table.ui-table.new-table-design .ui-table-tbody > tr > td:last-child {
  border-right: none;
}

.standard-table.ui-table.new-table-design .ui-table-tfoot tr {
  height: 45px;
}

.standard-table.ui-table.new-table-design .ui-table-tfoot tr td {
  border-bottom: 1px solid #E4E4E4 !important;
}

.standard-table.ui-table.new-table-design .ui-table-tfoot tr td.no-border {
  border-bottom: none !important;
}

.standard-table.ui-table.files-table .ui-table-thead > tr {
  border-bottom: 0;
}

.standard-table.ui-table.files-table.new-table-design .ui-table-thead > tr > th {
  padding: 15px 0;
}

// Spacer Styling for VendorCost
.standard-table.ui-table.vendor-cost.new-table-design .ui-table-tfoot tr.spacer {
  height: 10px;
}

.standard-table.ui-table .ui-table-thead > tr > th p-multiselect input.ui-inputtext {
  padding-left: 30px;
}

// Forecast Model List table styling
.standard-table.ui-table.new-model-list .ui-table-tbody tr.ui-state-highlight td {
  border-top: 2px solid $green;
  border-bottom: 2px solid $green;
}

.standard-table.ui-table.new-model-list .ui-table-tbody tr.ui-state-highlight td:first-of-type {
  border-left: 2px solid $green;
}

.standard-table.ui-table.new-model-list .ui-table-tbody tr.ui-state-highlight td:last-of-type {
  border-right: 2px solid $green;
}

// Scrollable Table styling
.standard-table.ui-table .ui-table-scrollable-header {
  border: 0;
  background-color: #E2E4E7;
  border-bottom: 2px solid $green;
  overflow: visible;
}
.standard-table.ui-table .ui-table-scrollable-header .ui-table-thead > tr {
  border-bottom: 0;
}
