.table.table-two th {
  background-color: #E1E5E6;
  border-bottom-color: #E1E5E6;
}

.table.table-two th,
.table.table-two td, {
  vertical-align: middle;
}

.table.table-two th:first-of-type {
  border-left: 1px solid #E1E5E6;
}

.table.table-two th:last-of-type {
  border-right: 1px solid #E1E5E6;
}

.table.table-two tr td:first-of-type {
  border-left: 1px solid #E1E5E6;
}

.table.table-two tr td:last-of-type {
  border-right: 1px solid #E1E5E6;
}

.table.table-two tr td {
  border-top: 0;
  border-bottom: 1px solid #E1E5E6;
}


table.table-four th {
  border-bottom: 2px solid #B4B4B4;
}

table.table-four tr td {
  border-top: 0;
  border-bottom: 1px solid #E1E5E6;
}

table.table-two th {
  background-color: #E0E4E7;
  border: 1px solid #E0E4E7;
}

table.table-two tr {
  border: 1px solid #E0E4E7;
}
