.ui-selectbutton {
    display: inline-block;
}
            
.ui-selectbutton.ui-state-error {
    padding: 0;
}

.ui-selectbutton .ui-button.ui-state-focus {
    outline: none;
}