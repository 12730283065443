@import "../custom_variables";

p-multiSelect .ui-multiselect .ui-multiselect-label-container {
  line-height: 1;
}

p-multiSelect .ui-multiselect.full-width {
  width: 100%;
}

p-multiSelect .ui-multiselect:not(.ui-state-disabled) .ui-multiselect-trigger:hover,
p-multiSelect .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-trigger {
  background-color: $white;
}


p-multiSelect .ui-multiselect:not(.ui-state-disabled) .ui-multiselect-trigger:hover .pi,
p-multiSelect .ui-multiselect:not(.ui-state-disabled):hover .ui-multiselect-trigger .pi{
  color: $green !important;
  transition: color 280ms;
}

p-multiSelect .ui-multiselect-label {
  margin-bottom: 0;
}

p-multiSelect .ui-multiselect .ui-multiselect-trigger {
  width: 1.8em;
}

p-multiSelect .ui-multiselect .ui-multiselect-trigger .pi {
  line-height: 10px;
}

p-multiSelect .ui-multiselect .ui-multiselect-item.ui-state-highlight {
  background-color: $white;
  color: $green;
  transition: color 280ms;
}

p-multiSelect .ui-multiselect-filter-container input:focus {
  box-shadow: none;
  border-color: $primary;
}

p-multiSelect .ui-multiselect .ui-multiselect-item:not(.ui-state-highlight):hover {
  background-color: $white;
  color: $green;
  transition: color 280ms;
}

p-multiSelect .ui-multiselect .ui-multiselect-panel {
  width: fit-content;
  min-width: 14em;
}

p-multiSelect .ui-multiselect .ui-multiselect-item.ui-state-highlight .ui-chkbox {
  border: 1px solid $green;
  background-color: $green;
  color: $white;
}

p-multiSelect .ui-chkbox .ui-chkbox-box.ui-state-active {
  border: 1px solid $green;
  background-color: $green;
  color: $white;
}

p-multiSelect input.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  padding-left: 40px;
  height: 40px;
}

p-multiSelect .ui-multiselect-header .ui-multiselect-filter-container .pi {
  top: 50%;
  left: .75em;
  margin-top: -7px;
}

p-multiSelect .forecast-report-total-header div.ui-multiselect-header {
  width: 96%;
}

/** close positioning */
p-multiSelect .ui-multiselect-header .ui-multiselect-close {
  position: absolute;
  right: 7px;
  top: 6px;
  // This was 10
}

p-multiSelect .ui-datatable-tablewrapper input.ui-inputtext.ui-corner-all.ui-state-default.ui-widget { /* input boxes header */
  height: 30px;
  border-radius: 0;
  border: 1px solid #eeeeee;
}

p-multiSelect .ui-multiselect .ui-multiselect-header,
p-multiSelect .ui-multiselect .ui-multiselect-item {
  margin: 5px;
  border: none;
}

p-multiSelect .ui-multiselect p-multiselectitem:last-child .ui-chkbox + span {
  border-bottom: none;
}

p-multiSelect .ui-multiselect p-multiselectitem .ui-chkbox + span {
  border-bottom: 1px solid #cacaca;
  padding-bottom: 10px;
  margin-left: 5px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

p-multiSelect .ui-multiselect-header .ui-multiselect-filter-container {
  margin-left: 5px;
  width: 80%;
}

p-multiSelect .ui-multiselect-header .ui-multiselect-close {
  display: none;
}

p-multiSelect .ui-multiselect .ui-multiselect-item .ui-chkbox.ui-widget {
  margin-bottom: 10px;
}

p-multiSelect .ui-multiselect .ui-multiselect-item .ui-chkbox.ui-widget .ui-chkbox-icon {
  display: block;
}

p-multiSelect .ui-multiselect .ui-multiselect-label {
  white-space: nowrap;

  // This is the fix for Multiselect height issue in IE browser
  line-height: 0.8;     // IE specific line height (since 'unset' is not recognizable in IE)
  line-height: unset;   // Unset for other webkit browser like Chrome, Firefox, Safari.
}

townip-user-patents p-multiSelect .ui-multiselect .ui-multiselect-panel {
  width: 100%;
}

townip-header-filter p-multiSelect .ui-multiselect .ui-multiselect-panel .ui-widget-header .ui-multiselect-filter-container .ui-inputtext,
townip-header-filters-v2 p-multiSelect .ui-multiselect .ui-multiselect-panel .ui-widget-header .ui-multiselect-filter-container .ui-inputtext {
  padding-left: 30px
}

// Custom Style class: Display multiselect popover on bottom
p-multiSelect .ui-multiselect.edit-column-popover .ui-multiselect-panel {
  left: -30px !important;
  top: 35px !important;
}


// START - style in ReportForecastAnalysis
// Style for the multiselect dropdown of countries in the ReportForecastAnalysis component.
// This is to fix the right side out of bounds issue for other browser (Edge and IE browser)
townip-report-forecast-analysis p-multiSelect .forecast-report-total-header.ui-multiselect {
  width: 150px;
}

townip-report-forecast-analysis p-multiSelect .forecast-report-total-header.ui-multiselect .ui-multiselect-panel {
  width: 200px;
  left: -50px !important;
}
// END - style in ReportForecastAnalysis

// custom-edit-columns style class specific
p-multiSelect .custom-edit-columns.ui-multiselect .ui-multiselect-label {
  padding-top: 4px;
  font-size: 13px;
  font-weight: 300;
}

p-multiSelect .custom-edit-columns.ui-multiselect {
  padding-top: 0.45rem;
  padding-right: 0 !important;
  padding-bottom: 0;
  padding-left: 3px;
  border: none;
  font-family: $font-family-default;
}

p-multiSelect .custom-edit-columns.ui-multiselect .ui-multiselect-trigger {
  display: none;
}

// Style for the multiselect dropdown of filter header in the FileAssociateTasks component.
// This is to fix the issue of last column dropdown that not visible inside the modal
townip-files-associate-tasks townip-header-filter p-multiSelect .ui-multiselect .ui-multiselect-panel {
  left: auto !important; // Important needed here because we are overwriting an inline style of PrimeNG
  right: 0;
}

.ui-multiselect .ui-multiselect-panel.header-filter-panel {
  max-width: 300px;
}
