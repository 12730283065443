@import "custom_variables";

* {  outline: none; }

.navlink {
  font-family: $font-family-default;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
}

.navbar {
  z-index: 10001;
  padding-left: 60px;
  /* -webkit-transition: all 0.25s;
  transition: all 0.25s; */
}

.navbar > .container,
.navbar > .container-fluid {
  z-index: 10001; }
.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
  height: 60px;
  line-height: 60px;
  margin-left: 0;
  font-family: $font-family-default;
  font-weight: 400;
  padding: 0;
  font-size: 1.5em; }
.navbar > .container .navbar-brand .fa-angle-right,
.navbar > .container-fluid .navbar-brand .fa-angle-right {
  margin-left: 5px;
  margin-right: 5px; }
.navbar > .container .navbar-expand-toggle,
.navbar > .container-fluid .navbar-expand-toggle {
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 0;
  float: left;
  -moz-transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  opacity: 0.75; }
.navbar > .container .navbar-expand-toggle .icon,
.navbar > .container-fluid .navbar-expand-toggle .icon {
  font-size: 1.4em; }
.navbar > .container .navbar-right-expand-toggle,
.navbar > .container-fluid .navbar-right-expand-toggle {
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0;
  -moz-transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  opacity: 0.75; }
.navbar > .container .navbar-right-expand-toggle .icon,
.navbar > .container-fluid .navbar-right-expand-toggle .icon {
  font-size: 1.4em; }
.navbar .navbar-breadcrumb {
  margin-left: 0;
  background-color: transparent;
  padding: 0;
  float: left; }
.navbar .navbar-breadcrumb > li {
  height: 60px;
  line-height: 60px;
  vertical-align: middle;
  font-family: $font-family-default;
  font-size: 1.5em; }
.navbar .navbar-nav > li > a {
  font-family: $font-family-default;
  height: 60px;
  line-height: 60px;
  padding: 0 20px; }
.navbar .dropdown-menu {
  padding: 0;
  border: 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  animation-duration: 0.4s;
  -webkit-animation-duration: 0.4s;
  z-index: -1;
  position: absolute; }
.navbar .dropdown-menu .title {
  font-family: $font-family-default;
  padding: 5px 10px;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); }
.navbar .dropdown-menu .message {
  font-family: $font-family-default;
  text-align: center;
  padding: 10px 20px; }
.navbar .dropdown-menu .notifications.list-group {
  list-style: none;
  padding: 0;
  margin: 0; }
.navbar .dropdown-menu .notifications.list-group .list-group-item {
  min-width: 250px;
  padding: 8px;
  border: 0;
  border-bottom: 1px solid #EEE; }
.navbar .dropdown-menu .notifications.list-group .list-group-item .icon {
  margin-right: 5px; }
.navbar .dropdown-menu .notifications.list-group .badge {
  border-radius: 1em; }
.navbar .dropdown-menu .notifications.list-group .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
.navbar .dropdown-menu .notifications.list-group .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }
.navbar .dropdown-menu .notifications.list-group a.list-group-item:hover {
  cursor: pointer; }
.navbar .dropdown.profile .dropdown-menu {
  width: 305px;
  padding-bottom: 0;
  text-align: center; }
.navbar .dropdown.profile .dropdown-menu li.profile-img {
  padding: 0;
  max-height: 300px;
  overflow: hidden; }
.navbar .dropdown.profile .dropdown-menu li.profile-img img.profile-img {
  width: 100%;
  height: auto;
  margin: 0;
  border: 0; }
.navbar .dropdown.profile .dropdown-menu .profile-info {
  font-family: $font-family-default;
  padding: 15px; }
.navbar .dropdown.profile .dropdown-menu .profile-info .username {
  font-size: 1.8em; }

.app-container {
  min-height: 100%;
  display: block;
  position: relative;
  padding-bottom: 0; }
.app-container .app-footer {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 65px;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  font-size: 12px;
  font-family: $font-family-default; }
.app-container .app-footer .wrapper {
  padding: 10px 35px;
  padding-left: 25px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle; }
.app-container .content-container {
  margin-right: 0;
  margin-left: 0; }
.side-menu.sidebar-inverse .navbar { // left side navigation gray background
  background: $gray-nav !important;
  width: $expanded-margin;
  -webkit-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  background-color: #566e87;
}


.side-menu-container {
  overflow-x: hidden !important;
}

.side-menu .navbar-header {
  border-bottom: 0 solid #e7e7e7;
  z-index: 1001;
}
.side-menu .navbar-header .navbar-brand {
  height: $toolbar-height;
  padding: 0;
  width: 100%;
  font-family: $font-family-default;
}

.side-menu .navbar-header .navbar-brand .icon {
  width: 100%;
  height: $toolbar-height;
}

.side-menu .navbar-header .navbar-brand .title {
  font-size: 30px;
  margin-top: 10px;
  margin-left: 20px;
}

.side-menu .navbar-header .navbar-expand-toggle {
  position: absolute;
  right: 0;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 0;
  -moz-transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  opacity: 0.75; }

.side-menu .navbar {
  border: none;
  padding: 0;
  width: $expanded-margin;
}

.side-menu .navbar-nav li {
  display: block;
  width: 100%;
  overflow: hidden;
}

.side-menu-container .nav>li>a {
  background: linear-gradient(to right, $green 50%, $gray-nav 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .25s ease-out;
}

.side-menu-container .nav>li>a:hover {
  //hover side bar <li> transition
  background-position: left bottom;
}

.dropdown .panel-collapse .panel-body {
  // background: $green2;
}

.nav .panel {
  background: $gray-nav;
}

button.navbar-right-expand-toggle.pull-right.visible-xs { // hide right nav button
  //display: block;
}

i.fa.fa-bars.icon { // hamburger icon
  margin: 20px 30px;
  font-size: 20px;
  cursor: pointer;
}

.side-menu .navbar-nav li a {
  font-family: $font-family-default;
  padding: 0 10px 0 13px;
  height: 60px;
  color: #1f1f1f; /* drop down icon color */
  /* line-height: 46px; */
  display: block;
  white-space: nowrap;
  text-decoration: none;
}

.side-menu .navbar-nav.uncategorized li a {
  padding-left: 5px;
}

.side-menu .navbar-nav li a .icon {
  margin-left: 0;
  width: 38px;
  text-align: center;
  font-size: 1.1em;
  display: inline-block;
  float: right;
}

.side-menu .navbar-nav li a .title {
  width: 0;
  white-space: nowrap;
  padding-left: 6px;
}

.side-menu .navbar-nav li.dropdown {
  border: 0;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  z-index: 1000;
}

.side-menu .navbar-nav li.dropdown ul li a {
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  padding: 0em 1.2em 0 16px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px; }
.side-menu .navbar-nav li.dropdown ul li a .fa {
  float: right;
  padding-right: 10px;
  padding-top: 8px;
  font-size: small;}
.app-container .content-container .side-body {
  padding-top: 80px;
  overflow-y: hidden; }
.app-container .content-container .side-body.padding-top {
  padding-top: 84px; }

.app-container.expanded .app-footer {
  left: 300px; }
.app-container.expanded .app-footer .wrapper {
  padding-left: 25px; }

.app-container.expanded .content-container .navbar-top {
  padding-left: 200px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.app-container.expanded .content-container .side-menu {
  width: 220px;
  overflow-y: auto; }
.app-container.expanded .content-container .side-menu .navbar-header .navbar-brand .title {
  display: inline-block; }
.app-container.expanded .content-container .side-menu .navbar-nav li a .title {
  display: inline-block; }
.app-container.expanded .content-container .side-menu .navbar-nav li .panel-collapse.in {
  display: block; }
.app-container.expanded .content-container .side-menu .navbar-nav li.dropdown > a:after {
  font-family: FontAwesome;
  content: "\f107";
  position: absolute;
  right: 1em; }

.container-fluid > .navbar-collapse,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container > .navbar-header {
  margin-left: -15px;
  margin-right: -15px; }

.page-title {
  font-family: $font-family-default;
  margin-left: -10px;
  margin-right: -10px;
  padding: 15px 10px;
  margin-bottom: 0;
  height: auto; }
.page-title .title {
  font-size: 2em; }
.page-title .page-action {
  float: right;
  height: 40px;
  line-height: 40px;
  vertical-align: middle; }

.sub-title {
  font-family: $font-family-default; }
.sub-title > * {
  display: inline-block; }
.sub-title h3 {
  margin-right: 10px; }
.sub-title .description {
  font-family: $font-family-default;
  font-size: 0.9em; }
.sub-title .action .btn {
  padding: 0 0.5em; }

/* Main body section */
.app-container .side-body {
  margin-left: 60px;
  // Main body margins are set in .div-container @ custom.scss
  // margin-right: 15px;
  -webkit-transition: all 0.25s;
  transition: all 0.25s; }

.app-container.home .side-body {
  margin-left: 60px;
  margin-right: 0;
  padding-top: 60px; }

.app-container #page-wrapper {
  width: 100%;
}

.app-container.home #page-wrapper.container-fluid {
  padding-left: 0;
  padding-right: 0; }

.app-container.expanded .side-body {
  margin-left: 220px; }

.app-container:not(.home) footer {
  margin-left: -30px;
  margin-right: -30px; }

/* small screen */
@media (max-width: 768px) {
  .app-container.home #page-wrapper.container-fluid {
    padding-left: 15px;
    padding-right: 15px; }
  .app-container footer {
    margin-left: -30px;
    margin-right: -30px; }
  .navbar {
    padding-left: 0; }
  .navbar .navbar-header {
    width: auto;
    display: block; }
  .navbar .navbar-nav {
    width: auto;
    margin: 0; }
  .navbar .navbar-nav > li {
    display: inline-block; }
  .navbar .navbar-right {
    position: absolute;
    top: 0;
    right: -100%;
    height: 100%;
    width: 100%;
    -moz-transition: all 0.25s linear;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear; }
  .navbar .navbar-right .open .dropdown-menu {
    position: absolute; }
  .navbar .navbar-right.expanded {
    right: 0; }
  .app-container .navbar-top {
    min-width: 300px; }
  .app-container .content-container .side-body {
    margin-left: 10px; }
  .app-container.home .content-container .side-body {
    margin-left: 0;
    margin-right: 15px; }
  .app-container.home .content-container .side-body .home-container {
    margin-left: -15px;
    margin-right: -15px; }
  .app-container.home .content-container .side-body .home-container .home-section {
    margin-right: -15px; }
  .app-container.expanded .navbar-top {
    min-width: 480px;
    padding-left: 0; }
  .app-container.expanded .side-menu {
    margin-left: 0;
    width: 300px; }
  .app-container.expanded .side-body {
    margin-left: 10px; }
  .app-container.expanded.home .content-container .side-body {
    margin-left: 0; }
  .app-container .app-footer .wrapper,
  .app-container.expanded .app-footer .wrapper {
    padding-left: 25px; } }


.nav-tabs > li {
  margin-bottom: -1px; }
.nav-tabs > li > a {
  border-radius: 0;
  border: 0; }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border-radius: 0;
  border-top: 0; }
