@import "custom_variables";

.estimate-section {
  counter-reset: estimate-counter;
}

.estimate-section span.step-number {
  counter-increment: estimate-counter;
}

.estimate-section span.step-number::before {
  content: counter(estimate-counter);
}

/* Patent Search */


/* -------- */

/* Order - Delivery Info component */

.project-client-details, .company-title, .client-title, .company-group {
  color: $green;
}


// We need margin instead of padding since we bind the scroll anchors to the element.
.question-row:first-child {
  margin-top: 90px;
}

// We need margin instead of padding since we bind the scroll anchors to the element.
.question-row {
  margin-top: 90px;
  padding: 0 0 45px 0; // T
}

/* updates */

.div-order .modal-lg .form-group label {
  color: #9b9b9b;
}

.div-order .modal-lg .contact-type-input {
  margin-top: 2px;
}

/* add another - button */
.div-order .btn-cta-secondary {
  /*
   height: 40px;
   margin-top: 28px;
  */
}

.div-order .client-name {
  color: $gray-dark;
  font-weight: 400;
}

/* uploader */
.div-order .ui-fileupload.ui-widget {
  margin: 45px;
}

/* uploader button */
.div-order .ui-button,
.div-order .ui-button:enabled:active,
.div-order body .ui-button:focus,
.div-order body .ui-button:enabled:hover {
  background-color: $green;
}

.div-order body .ui-button:enabled:hover {
  opacity: 0.8;
}

/* Create Estimates */

.chips-container {
  width: 100%;
}

/* Buttons */
.estimate-button .ui-button {
  height: 50px;
  font-weight: 500;
}

.estimate-button .ui-button-secondary {
  border: 1px solid $green;
  color: $green;
}

/* country flags 5 columns */
@media (min-width: 1200px) {
  .row.btn-country .col-lg-15 {
    position: relative;
    min-height: 1px;
  }
}

/* Third party components */

.text-link {
  margin-left: 3px;
}

.estimate-input-label {
  opacity: 0.7;
  color: $gray-dark;
  font-size: 16px;
  line-height: 20px;
}


/**
  Shared estimate styles
 */
.estimate-header {
  color: $green;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 25px;
  padding: 20px 65px;
  border-bottom: 2px solid $green;
  position: fixed;
  top: $toolbar-height;
  background-color: white;
  width: $calc-width-collapsed;
  z-index: 1004;
}

.app-expanded .estimate-header {
  width: $calc-width-expanded;
}

.heading-row {
  height: 60px;
}

.heading-row h1 {
  margin-top: 0;
}

.field {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.approve-icon {
  height: 150px;
  width: auto;
  margin-top: 60px;
  margin-left: 100px;
}

.contact-heading {
  color: $green;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.approved-container {
  min-width: 600px;
}

.approved-container .ui-accordion .ui-accordion-header.ui-state-active {
  background-color: $green !important;
  font-size: 16px;
}

.approved-container .ui-button-text-icon-left .fa {
  font-size: 16px;
}

.secondary-accordion .ui-accordion .ui-accordion-header.ui-state-active {
  background-color: white !important;
  border: none;
}

.secondary-accordion .ui-accordion .ui-accordion-header.ui-state-active a {
  color: $green;
}

/**
  Setting the calendar width
 */

.answered-question label.active,
.answered-question p-dropdown{
  animation: question-beat .2s 4 alternate;
}

@keyframes question-beat{
  to { opacity: .5;}
}

// Estimate Review
.data-value {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 16px;
}

.total-title {
  color: #777777;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 300;
  padding-top: 5px;
}

.total-value {
  color: #777777;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
}

.project-total-value {
  color: $green;
  font-size: 20px;
  font-weight: 500;
  text-align: right;
}

.project-total-row {
  background-color: $gray-light;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

// Removing this to remove overflow issues in all pages
// This is the cause of a lot of the overflow issues in MOST pages and prevents use from
// doing proper responsive layouts
// DO NOT uncomment this without talking to @dpili or your tech lead.
//.container {
//  min-width: 1100px;
//}

.total-only-label {
  color: $gray-dark;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  text-align: right;
}

.total-only-value {
  font-size: 30px;
  font-weight: 500;
  color: $green;
}

.rejected-text {
  color: $red-text;
}

townip-review-complete townip-estimate-table .card {
  margin-right: auto;
  margin-left: auto;
}
