.ui-chkbox {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    margin-right: .25em;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.ui-chkbox .ui-chkbox-box {
    width: 1.125em;
    height: 1.125em;
    line-height: 1.125em;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
}

.ui-chkbox .ui-chkbox-icon {
    display: block;
}

.ui-chkbox-label {
    vertical-align: middle;  
}

