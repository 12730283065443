@import '../custom_variables.scss';
p-confirmdialog .ui-dialog {
  min-width: 630px !important;
  border-radius: 4px;
  padding: 0 1rem 0 1rem;
}

p-confirmdialog .ui-dialog .ui-dialog-content {
  padding: 0 1em;
  overflow-x: hidden;
}

p-confirmdialog .ui-dialog .ui-dialog-titlebar {
  padding: 1em;
  border: none;
}

p-confirmdialog span.ui-dialog-title {
  border-bottom: 1px solid $secondary-normal;
  width: 100%;
  display: inline-block;
  padding-bottom: 10px;
}

p-confirmdialog .ui-dialog-footer {
  border: none;
  border-top: 1px solid $secondary-normal !important;
  padding: 0 0 20px 20px;
  margin: 0 1em 0 1em;
}

p-confirmdialog .dialog-border {
  border-bottom: 1px solid $secondary-normal;
}

p-confirmdialog .ui-widget-content {
  border: none;
}


p-confirmdialog .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  font-weight: 400;
  font-size: 24px;
  color: $green;
}

p-confirmdialog .ui-button {
  padding: 10px 60px 10px 60px;
  margin-top: 20px;
}

// No button
p-confirmdialog .ui-dialog-footer .ui-button:first-child {
  background: $secondary-normal !important;
  color: $white !important;
}

p-confirmdialog .ui-dialog-footer .ui-button:first-child:active {
  background: $secondary-active !important;
  color: $white !important;
}

// Yes button
p-confirmdialog .ui-dialog-footer .ui-button:last-child {
  background: $green !important;
  margin-right: 5px;
  color: $white !important;
}

p-confirmdialog .ui-dialog-footer .ui-button:last-child:hover {
  background: $green !important;
  opacity: 0.8 !important;
  color: $white !important;
}

p-confirmdialog .ui-dialog-footer .ui-button:last-child:active {
  background: $green;
}

p-confirmdialog .ui-button .fa,
p-confirmdialog .ui-button .fa {
  padding-left: 65px;
}

p-confirmdialog .ui-clickable.fa {
  font-size: small;
}

p-confirmdialog .ui-dialog .ui-dialog-titlebar-close span {
  top: 5px;
  margin-left: -10px;
  position: absolute;
  font-size: 20pt;
  color: $secondary-normal;
}

p-confirmdialog .ui-dialog .ui-dialog-titlebar-close span:hover {
  color: $secondary-hover;
}
