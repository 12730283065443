@import 'custom_variables';

// TODO: Add other statuses here
.text-status-overdue {
  color: $status-overdue;
  font-weight: bold;
}

.text-status-needs-action {
  color: $status-needs-action;
}
