@import '../custom_variables.scss';
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

// Removes the extra margin added by reboot.
// DO NOT REMOVE THIS.
label.ui-radiobutton-label {
  margin-bottom: 0;
}

.model-list p-dtRadioButton .ui-radiobutton.ui-widget {
  font-size: 19px;
}

.model-list p-dtRadioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.fa-circle,
.model-list p-dtRadioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.pi-circle-on {
  font-size: 0.8em;
  margin: 0.15em;
}

p-dtRadioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active,
p-dtRadioButton .ui-radiobutton .ui-radiobutton-box,
p-radioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active,
p-radioButton .ui-radiobutton .ui-radiobutton-box,
p-tableRadioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active,
p-tableRadioButton .ui-radiobutton .ui-radiobutton-box {
  background-color: $white;
  border: 1px solid $email-text;
}

p-dtRadioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.fa-circle,
p-dtRadioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.pi-circle-on,
p-radioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.fa-circle,
p-radioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.pi-circle-on,
p-tableRadioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.fa-circle,
p-tableRadioButton .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.pi-circle-on {
  color: $primary;
  font-size: 1em;
  top: 0;
  left: 0;
  margin: 0;
}

// This fixes the alignment of the center selected indicator.
// Use this if the default unstyled radio button is broken for you.
p-radioButton .ui-radiobutton.fixed-default .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.pi-circle-on,
p-tableRadioButton .ui-radiobutton.fixed-default .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon.pi-circle-on  {
  color: $primary;
  font-size: 1em;
  top: 0;
  left: 0;
  margin: 0;
}

p-radioButton .ui-radiobutton.fixed-default .ui-radiobutton-box.ui-state-active,
p-tableRadioButton .ui-radiobutton.fixed-default .ui-radiobutton-box.ui-state-active {
  border-color: $primary;
}

/* validations */
p-radioButton.ng-invalid.ng-touched label.ui-radiobutton-label {
  color: $danger;
}

p-radioButton.ng-invalid.ng-touched .ui-radiobutton .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active),
p-radioButton.ng-invalid.ng-touched .ui-radiobutton .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: $danger;
}
/* ---------- */
