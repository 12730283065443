/**
This file should only contain global styles that used in the new settings layout.
Prefixed by the main module component selected.
 - Standard buttons
 - Standard form styles
 - DO NOT USE !important here.
 - If you are using !important, you're probably doing it wrong. (ex: Wrong selector override)
 */

@import '../../stylesheet/custom_variables.scss';

$default-form-input-border: 2px solid #cacaca;

townip-estimate-approved h1 {
  color: $green;
  font-size: 1.4rem;
  font-weight: normal;
}

townip-estimate-approved .form-group.has-icon-left,
townip-estimate-approved .form-group.has-icon-right {
  position: relative;
}

townip-estimate-approved .form-group.has-icon-right .form-control {
  padding-right: 2.5rem;
}

townip-estimate-approved .form-group.has-icon-left .form-control {
  padding-left: 2.5rem;
}

townip-estimate-approved .form-group .input-icon {
  position: absolute;
  top: 0.8rem;
  color: #ccc;
}

townip-estimate-approved .form-group .input-icon.icon-right {
  right: 1rem;
}

townip-estimate-approved .form-group .input-icon.icon-left {
  left: 1rem;
}

townip-estimate-approved .form-control:disabled, .form-control[readonly] {
  background-color: transparent;
  border: 2px solid #cacaca;
  box-shadow: none !important;
  opacity: .35;
}

townip-estimate-approved .form-control:disabled.filter-input, .form-control[readonly].filter-input {
  border: 1px solid #efefef
}

// Legacy override - was overridden in the wrong way in our base global style.
townip-estimate-approved input.form-control {
  font-family: $font-family-default;
}

// The right way to override bootstrap's default form-control. Always match the original selector.
townip-estimate-approved townip-order .form-control {
  border: $default-form-input-border;
  border-radius: 2px;
  padding: .8rem 1rem;

  // NOTE: We are only adding important here because it had !important in the base styles.
  // Now we have to cascade it to override in here.
  // This is a negative effect of the !important in styles.scss
  line-height: 1.5 !important;
  font-size: 1rem !important;
}

townip-estimate-approved .form-control.custom-control {
  padding: 0;
  border: none;
  display: block;
}

townip-estimate-approved select.form-control:not([size]):not([multiple]) {
   height: auto;
   padding: .85rem 1rem;
}

townip-estimate-approved .form-control.filter-input {
  border: 1px solid #E1E5E6;
  border-radius: 4px;
}

townip-estimate-approved select.form-control.filter-input {
  border: 1px solid #E1E5E6;
  border-radius: 4px;
}

townip-estimate-approved .pref-title {
  font-weight: bold;
}

townip-estimate-approved accordion .panel-heading.card-header {
  margin-left: 0;
  background-color: #E1E5E6;
  border-bottom: none;
  border-radius: 3px;
  padding: 0.5rem 1.3rem;
}

townip-estimate-approved accordion .panel.panel-open .panel-heading.card-header {
  border-radius: 3px 3px 0 0;
}

townip-estimate-approved accordion .card {
  box-shadow: none;
  padding: 0;
}

townip-estimate-approved accordion .panel-body {
  border-left: 1px solid #E1E5E6;
  border-right: 1px solid #E1E5E6;
  border-bottom: 1px solid #E1E5E6;
  border-radius: 0 0 3px 3px;
}

townip-estimate-approved .basic-filter .form-group {
  padding: 0 0.2rem;
}

townip-estimate-approved .basic-filter .form-group:first-child {
  padding-left: 0;
}

townip-estimate-approved .basic-filter .field-dropdown {
  min-width: 200px;
}

townip-estimate-approved .basic-filter .field-visibility {
  min-width: 200px;
}

townip-estimate-approved .basic-filter .field-term {
  min-width: 300px;
}


townip-estimate-approved .badge {
  padding: .4em .5em;
}

// An override of the prime-dropdown that we have on p-dropdown.scss
// We just need to adjust the min-height to match all our forms
townip-estimate-approved .form-dropdown p-dropdown .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix  {
  // min-height: 0;
}

// By default, the dropdowns should be block-level styling. (Always fill 100% of the container)
// If you want to disable this for certain forms, add a custom class. (e.x townip-estimate-approved .form-dropdown.new-class)
townip-estimate-approved p-dropdown .ui-dropdown {
  width: 100%;
  border: $default-form-input-border;
  border-radius: 2px;
}

townip-estimate-approved p-dropdown.is-invalid .ui-dropdown {
  border-color: #dc3545;
}

townip-estimate-approved p-dropdown .ui-dropdown .ui-dropdown-label {
  font-size: 1rem;
  padding: .8rem 1rem;
  line-height: unset; // Must be the same as the .form-control above.
}


// By default, the multiselect should be block-level styling. (Always fill 100% of the container)
// If you want custom size, wrap it in a container width a different size.
townip-estimate-approved p-multiSelect .ui-multiselect {
  width: 100%;
  font-size: 1rem;
  padding: .8rem 1rem;
  border: $default-form-input-border;
  line-height: 1.2;
  border-radius: 2px;
}

townip-estimate-approved p-multiSelect.is-invalid .ui-multiselect {
  border-color: #dc3545;
}

// P-Chips style override
townip-estimate-approved p-chips .ui-chips-token.ui-state-highlight {
  padding: 0.3rem 1.2rem 0.3rem 0.8rem;
}

townip-estimate-approved p-chips .ui-chips>ul.ui-inputtext {
  padding: 0 0.8rem;
  border: 2px solid #cacaca;
}

townip-estimate-approved p-chips .ui-chips {
  box-shadow: none;
}

townip-estimate-approved p-chips .ui-chips > ul.ui-inputtext.ui-state-disabled {
  opacity: 1;
}

townip-estimate-approved p-radioButton label {
  margin-bottom: 0;
}

townip-estimate-approved p-autoComplete input.ui-autocomplete-input.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  height: auto;
  padding: 0.9rem 1rem;
  border: $default-form-input-border;
  border-radius: 2px;
}

townip-estimate-approved .btn-secondary {
  color: #757272;
  background-color: #E3E5E3;
  border-color: transparent;
}
