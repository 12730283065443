@import "../custom_variables";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

body .ui-datepicker .ui-datepicker-header,
p-calendar .ui-datepicker .ui-datepicker-header {
  background-color: white;
  border: none;
  padding-bottom: 0;
  font-weight: bold;
  font-size: 16px;
}

// This will be applied when the calendar is appendTo="body"
body .ui-datepicker select.ui-datepicker-month,
body .ui-datepicker select.ui-datepicker-year,
// Styling when not appended to body
p-calendar .ui-datepicker select.ui-datepicker-month,
p-calendar .ui-datepicker select.ui-datepicker-year{
  border: none;
  font-size: 14px;
}

// Position the calendar at the bottom of the input
body .ui-datepicker.position-bottom,
p-calendar .ui-datepicker.position-bottom {
  top: 35px !important;
}

// Exclusive only for forecast form
p-calendar.forecast-form.ng-invalid.ng-touched input {
  border: 1px solid red;
}

// Position the calendar at the left of the input
townip-filing-service p-calendar .ui-datepicker.position-left {
  left: -100px !important;
}

townip-task-action-calendar p-calendar .ui-datepicker.position-left {
  left: -70px !important;
}

body .ui-datepicker table td a,
p-calendar .ui-datepicker table td a {
  min-width: 2em;
}

body .ui-calendar .ui-calendar-button,
p-calendar .ui-calendar .ui-calendar-button {
  color: $green;
  background-color: $white;
  border: 2px solid #cacaca;
  border-left: none;
}

body .ui-calendar .ui-calendar-button:hover,
body .ui-calendar .ui-calendar-button:active,
p-calendar .ui-calendar .ui-calendar-button:hover,
p-calendar .ui-calendar .ui-calendar-button:active {
  color: $green;
  background-color: $white;
}

townip-input-calendar p-calendar {
  padding: 0 !important;
}

townip-input-calendar p-calendar span {
  width: 100%;
}

townip-input-calendar p-calendar .ui-inputtext {
  padding: .8rem 1rem;
  border: none;
  width: 100%;
  line-height: 22px;
}

townip-input-calendar p-calendar .ui-inputtext:focus {
  border: none !important;
  box-shadow: none;
}

/** Today */
body .ui-datepicker table.ui-datepicker-calendar td:not(.ui-state-disabled) a.ui-state-highlight,
p-calendar .ui-datepicker table.ui-datepicker-calendar td:not(.ui-state-disabled) a.ui-state-highlight {
  color: rgba(55, 58, 60, .32);
  background-color: rgba(66, 189, 161, .32);
}

/**
  * Active Dates. Currently no way to determine between an active date in a range and a
  * date that is simply within that rage.
 */
body .ui-datepicker table.ui-datepicker-calendar td:not(.ui-state-disabled) a.ui-state-active,
p-calendar .ui-datepicker table.ui-datepicker-calendar td:not(.ui-state-disabled) a.ui-state-active {
  color: #ffffff;
  background-color: #01B2A9;
  border-radius: 3px;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
p-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
p-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  font-size: 20px;
  top: 15px;
}

body .ui-datepicker .ui-datepicker-header a:hover,
p-calendar .ui-datepicker .ui-datepicker-header a:hover {
  text-decoration: none;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before,
p-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
  content: $fa-prev;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
p-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before {
  content: $fa-next;
}

p-calendar.create-deposit-calendar .ui-inputtext {
  padding: 0.85rem;
}

.estimate-calendar p-calendar .ui-inputtext {
  width: 100% !important;
}

p-calendar .ui-datepicker .ui-datepicker-buttonbar {
  text-align: left;
}

/** Always show datepicker modal at the bottom,
 ** to prevent cutting display of month year section for #schedule datetime picker
 **/
townip-task-action-alert p-calendar#schedule .ui-datepicker {
  top: 0 !important;
}

// We have make it look like our default bootstrap styling
// TODO: Use variables/mixins to copy the changes from the base bootstrap style
p-calendar .bs-input {
  display: block
}

body .bs-input.ui-calendar .ui-inputtext,
p-calendar .bs-input.ui-calendar .ui-inputtext {
  @include bs-input;
}

body .ui-calendar.ui-calendar-w-btn .ui-inputtext,
p-calendar .ui-calendar.ui-calendar-w-btn .ui-inputtext {
  width: calc(100% - 44px) !important;
}

body .bs-input.ui-calendar.ui-calendar-w-btn .ui-inputtext,
p-calendar .bs-input.ui-calendar.ui-calendar-w-btn .ui-inputtext {
  // if ui-calendar-w-btn is present, meaning the prime calendar is showing the icon
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

body .bs-input.ui-calendar.ui-calendar-w-btn .ui-calendar-button,
p-calendar .bs-input.ui-calendar.ui-calendar-w-btn .ui-calendar-button {
  width: 3rem;
}

body .bs-input.ui-calendar.ui-calendar-w-btn .ui-button-icon-left,
p-calendar .bs-input.ui-calendar.ui-calendar-w-btn .ui-button-icon-left {
  font-size: x-large;
  color: $gray;
}

townip-calendar.highlight-invalid.ng-invalid.ng-touched p-calendar.bs-input input.ui-inputtext,
townip-calendar.highlight-invalid.ng-invalid.ng-touched p-calendar.bs-input button.ui-datepicker-trigger {
  border-color: $danger;
}

// When appendTo: 'body' is specified, the calendar needs to have a very high z-index to be visible
body > .ui-datepicker {
  z-index: 9001 !important; // !important is needed here because prime adds the z-index on the element
}


townip-q-patent-details townip-calendar p-calendar.warning .ui-calendar .ui-inputtext {
  border-color: #f59a39;
}

.advanced-datepicker-calendar.ui-calendar .ui-datepicker-inline {
  border: 0;
  overflow: hidden;
}

/**
Manually set height in calendar INPUT and BUTTON
Reasons:
1) paddings and margins are not working the same with different browsers
2) Inputs (ui-inputtext) have a global css design that is conflicting with the calendar input

NOTE: Currently, We set 50px height when using a bs-input class to match the height of all inputs globally
 */
body .bs-input.ui-calendar .ui-inputtext,
p-calendar .bs-input.ui-calendar .ui-inputtext {
  height: 50px
}

body .bs-input.ui-calendar.ui-calendar-w-btn .ui-calendar-button,
p-calendar .bs-input.ui-calendar.ui-calendar-w-btn .ui-calendar-button {
  height: 50px;
}

p-calendar .pm-calendar.ui-calendar .ui-datepicker-title {
  color: #00B1A8;
  font-size: 13px;
  margin-bottom: 10px;
}

p-calendar .pm-calendar.ui-calendar .ui-datepicker-calendar th {
  color: #999999;
  font-weight:normal;
  font-size:12px;
}

p-calendar .pm-calendar.ui-calendar .ui-datepicker.ui-datepicker-inline {
  border: 1px solid #E8E8E8;
}

p-calendar .pm-calendar.ui-calendar .ui-datepicker-calendar thead {
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
}

p-calendar .pm-calendar.ui-calendar .ui-datepicker th {
  padding: 1em .5em;
}

p-calendar .pm-calendar.ui-calendar body .ui-datepicker .ui-datepicker-header {
  padding-top: 20px;
}

p-calendar .pm-calendar.ui-calendar .ui-datepicker table.ui-datepicker-calendar td:not(.ui-state-disabled) a.ui-state-active,
p-calendar .pm-calendar.ui-calendar body .ui-datepicker table.ui-datepicker-calendar td:not(.ui-state-disabled) a.ui-state-active{
  color: #01B2A9;
  border-radius: 3px;
  background-color: rgba(100,186,162,0.2);
  font-weight: bold;
}

p-calendar .pm-calendar.ui-calendar .ui-datepicker td>span,
p-calendar .pm-calendar.ui-calendar body .ui-datepicker table td a,
p-calendar .pm-calendar.ui-calendar .ui-datepicker table td a {
  font-size: 11px;
  padding: 10px 0;
  color: #000;
}

p-calendar .pm-calendar.ui-calendar {
  font-weight: 400;
}

// START - @bootstrap/_tables.scss override for date-pickers inside tables
.table .ui-datepicker th {
  padding: 0.5em;
  border: 0;
}

.table .ui-datepicker td {
  padding: 0;
  border: 0;
}
// END - @bootstrap/_tables.scss override for date-pickers inside tables
