.ui-dropdown label {
    margin-bottom: auto;
}

.ui-dropdown {
    display: inline-block;
    position: relative;
    cursor: pointer;
    vertical-align: middle;
    min-width: 12.5em;
}

.ui-dropdown .ui-dropdown-clear-icon {
    position: absolute;
    right: 2em;
    top: 50%;
    font-size: 1em;
    height: 1em;
    margin-top: -.5em;
}

.ui-dropdown .ui-dropdown-trigger {
    border-right: none;
    border-top: none;
    border-bottom: none;
    cursor: pointer;
    width: 1.5em;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 .25em;
}

.ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    position: absolute;
}

.ui-dropdown .ui-dropdown-label  {
    display: block;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
    width: 100%;
    padding-right: 2.5em;
}

.ui-dropdown-item-empty,
.ui-dropdown-label-empty {
    text-indent: -9999px;   
    overflow: hidden;
}

.ui-dropdown.ui-state-disabled .ui-dropdown-trigger,
.ui-dropdown.ui-state-disabled .ui-dropdown-label {
    cursor: default;
}

.ui-dropdown label.ui-dropdown-label  {
    cursor: pointer;
}

.ui-dropdown input.ui-dropdown-label  {
    cursor: default;
}

.ui-dropdown .ui-dropdown-panel {
    min-width: 100%;
}

.ui-dropdown-panel {
    position: absolute;
    height: auto;
}

.ui-dropdown-panel .ui-dropdown-items-wrapper {
    overflow: auto;
}

.ui-dropdown-panel .ui-dropdown-item {
    font-weight: normal;
    border: 0 none;
    cursor: pointer;
    margin: 1px 0;
    padding: .125em .25em;
    text-align: left;
}

.ui-dropdown-panel .ui-dropdown-empty-message {
    padding: .5em;
} 

.ui-dropdown-panel .ui-dropdown-item-group {
    font-weight: bold;
    cursor: default;
}

.ui-dropdown-panel .ui-dropdown-list {
    padding: 0.4em;
    border: 0 none;
}

.ui-dropdown-panel .ui-dropdown-filter {
    width: 100%;
    box-sizing: border-box;
    padding-right: 1.5em;
}

.ui-dropdown-panel .ui-dropdown-filter-container {
    position: relative;
    margin: 0;
    padding: 0.4em;
    display: inline-block;
    width: 100%;
}

.ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
    position: absolute;
    top: .8em;
    right: 1em;
}

/** Dropdown **/
.ui-fluid .ui-dropdown {
    width: 100%;
    min-width: 100%;
}
