/** Breadcrumb **/
.ui-breadcrumb {
    margin: 0;
    padding: 0;
    padding: .5em;
}

.ui-breadcrumb ul {
    margin: 0;
    padding: 0;
}

.ui-breadcrumb ul li {
    display: inline-block;
    margin: 0 .25em;
}

.ui-breadcrumb-chevron, .ui-breadcrumb-home {
    vertical-align: middle;
}

.ui-breadcrumb ul li .ui-menuitem-link {
    text-decoration: none;
}

.ui-breadcrumb .ui-menuitem-icon {
    margin-right: .25em;
    vertical-align: middle;
}

.ui-breadcrumb .ui-menuitem-text {
    vertical-align: middle;
}