/*
 * FileUpload
 */
.ui-fileupload-buttonbar .ui-fileupload-choose.ui-state-disabled input {
    cursor: default;
}

.ui-fileupload-buttonbar {
    padding: .5em;
    border-bottom: 0 none;
}

.ui-fileupload-buttonbar .ui-button {
    vertical-align: middle;
    margin-right: .25em;
}

.ui-fileupload-content {
    padding: 1em;
    position: relative;
    transition: border-color .3s;
}

.ui-fileupload-content.ui-fileupload-highlight {
    border-color: #156090;
}

.ui-fileupload-files img {
    border: none;
}

.ui-fileupload-files {
    display: table;
}

.ui-fileupload-row {
    display: table-row;
}

.ui-fileupload-row > div {
    display: table-cell;
    padding: .5em 1em;
    vertical-align: middle;
}

.ui-fileupload-content .ui-progressbar {
    width: 100%;
    position: absolute;
    top: 1px;
    left: 0;
    height: .25em;
    border: 0 none;
}

.ui-fileupload-content .ui-progressbar-value {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none;
}

/* Simple */
.ui-fileupload-choose {
    position: relative;
    overflow: hidden;
}

.ui-fileupload-choose input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    direction: ltr;
    cursor: pointer;
    z-index: 1;
}

.ui-fileupload-choose.ui-fileupload-choose-selected input[type=file] {
    display: none;
}

/* ui-fluid */
.ui-fluid .ui-fileupload .ui-button {
    width: auto;
}

.ui-fluid .ui-fileupload-content .ui-button-icon-only {
    width: 2em;
}

@media (max-width: 40em) {
    .ui-fileupload-buttonbar .ui-button {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-bottom: .25em;
    }

    .ui-fileupload-buttonbar .ui-button:last-child {
        margin-bottom: 0;
    }

    .ui-fileupload-row > div {
        display: block;
    }
}


