@import '../stylesheet/custom_variables';

/* ---- */

.vendor-section .col-lg-6 {
  max-width: 100%;
}

.vendor-section .destination-title-container,
.vendor-section .panel-heading.card-header {
  margin-bottom: 0;
  padding-bottom: 0;
}

.vendor-section .accordion-header-button {
  margin-top: -25px;
}

.vendor-section .source-container .accordion-header-button {
  padding-right: 0;
}

.vendor-section .card-header {
  background-color: white;
}
.vendor-section .card {
  border: none;
}

.vendor-section .accordion-heading-title {
  margin-top: -35px;
  margin-left: 92px;
}

.vendor-section .destination-title-container .accordion-heading-title {
  margin-left: 0;
}

.vendor-section .drop-down-source .accordion-heading-title {
  margin-left: 0;
}

.vendor-section .accordion-heading-clear {
  position: absolute;
  padding-left: 0;
  margin-top: 0;
  margin-left: 90%;
  z-index: 1;
}

.vendor-section .drop-down-destination .main-dropdown .ui-select-container.dropdown {
  margin-left: 25px;
  max-width: 395px;
}


.vendor-section .tip-clear-button {
  padding: .3rem .5rem;
  float: right;
}

.vendor-section .tip-button {
  margin-top: 0;
  padding: 5px;
  margin-bottom: 20px;
  background-color: #F9F9F9;
  border: 1px solid $green;
  color: $green;
}

.vendor-section .contact-name {
  display: inline-block;
  width: 80%;
  padding-right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vendor-section .sel-proficiency {
  width: 100% !important;
}

.vendor-section .dropdown-toggle::after {
  float: right;
  margin: 6px -6px 0 15px;
}

.vendor-section .sel-proficiency button#dropdownMenuButton {
  margin-left: 0;
}

.vendor-section .show>.dropdown-menu {
  margin-left: 0;
}

.vendor-section .btn-remove-service {
  padding-right: 35px;
}

.vendor-section i.fa.fa-times.tip-clear-fa {
  color: #cccccc;
}

.vendor-section button.btn.btn-outline-secondary.tip-btn {
  padding: .3rem .5rem;
  float: right;
  margin-right: 17px;
}

.form-drop-d button.btn.btn-outline-secondary.tip-btn,
.vendor-section .drop-down-source button.btn.btn-outline-secondary.tip-btn {
  margin-right: 2px !important;
}

.vendor-section button.btn.btn-outline-secondary.tip-btn i {
  color: #cccccc;
}

.vendor-section button.btn.btn-outline-secondary.tip-btn:hover i {
  color: $white;
}

.vendor-section button.btn.btn-outline-secondary.tip-clear-button:hover i {
  color: $white;
}

.vendor-section .vendor-pref-description {
  margin-bottom: 20px;
  display: inline-block;
}

.vendor-section input.form-control.unit-cost {
  height: 35px !important;
}

.vendor-section .ui-rating {
  margin: 5px 0 13px 565px;
}

.vendor-section .destination-container .ui-rating {
  margin: -20px 0 0 300px;
}

.vendor-section .vendor-translation-lang-pair .destination-container .ui-rating {
  margin: 15px 0 0 610px;
}

.vendor-section townip-linguistic-vendor-row .row.form-drop-d.vendor-row { /* linguistic row */
  width: 1197px;
}

.vendor-section .row.translation-table-label {
  width: 100%;
  padding-left: 5px;
}

.vendor-section .translation-system-pricing-label {
  padding-left: 8px;
}

/* Vendor Preferences Accordion Title - Source & destination language */
.vendor-section .source-language-title,
.vendor-section .dest-languagage-title {
  margin-left: 0;
}

.vendor-section  .destination-container {
  margin-left: 10px;
}

.vendor-section .destination-container .panel-collapse.collapse.in.show .panel-body {
  padding-top: 20px !important;
}

.vendor-section .cols-title .ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
  margin-left: 45px !important;
  height: 35px;
}

.vendor-section .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  width: 100% !important;
}

.vendor-section .dropdown-source {
  padding: 0;
}

/* add service label */
.vendor-section span.add-service {
  margin-left: 0;
  margin-right: 5px;
  float: right;
}

/* ratng */
.vendor-section .service-row .ui-rating {
  margin: 0;
}

.service-row .form-group input {
  height: 35px;
}

/* --------- End of vendor settings --------- */

/* Companies & Vendors */
.company-vendor-container .settings-container.users-card-block {
  margin-top: 20px;
}

.company-vendor-container span.accordion-heading-title {
  margin: 0;
}

.company-vendor-container label.btn.btn-pill-right.btn-right {
  margin-left: -30px !important;
}

/* override the pill settings for application preferences 3 buttons*/
.company-vendor-container .btn-pill-group label.btn-pill-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.company-vendor-container  label.btn.btn-pill-left.ng-valid.ng-touched.active,
.company-vendor-container  label.btn.btn-pill-left.ng-untouched.ng-pristine.ng-valid.active {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.company-vendor-container .user-list-entry .show > .dropdown-menu {
  min-width: fit-content;
}


@media(max-width: 500px) {
  .company-vendor-container label.btn.btn-pill-right.btn-right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .company-vendor-container label.btn.btn-pill-right.btn-right {
    margin-left: 0;
  }
}

/*-----------------------------*/

/* Vendor Hours */
.vendor-hours-container .tip-holiday {
  background-color: #F9F9F9;
  border: 1px solid $green;
  padding: 8px 15px;
  margin: 20px 0;
}

.vendor-hours-container .tip-clear-button {
  float: right;
  padding: 3px 6px;
}

.vendor-hours-container .vendor-calendar {
  width: 413px;
  height: 15.6px;
  font-weight: 300;
  text-align: left;
  color: #39393a;
}

.vendor-hours-container .vendor-hours-container {
  margin-left: 60px;
}

.vendor-hours-container .vendor-hours-container h4 {
  letter-spacing: 0.7px;
  text-align: left;
  color: #9b9b9b;
}

.vendor-hours-container label {
  font-weight: 300;
  letter-spacing: 0.5px;
  text-align: center;
  color: #39393a;
}

.vendor-hours-container .vendor-hours-container input[type="checkbox"] {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  cursor: pointer;
}

.vendor-hours-container .vendor-day {
  border-bottom: 1px solid #9b9b9b;
  padding-bottom: 20px;
}

.vendor-hours-container .tip-clear-button {
  padding: 0 3px !important;
}

.vendor-hours-container .panel-heading.card-header {
  background: transparent !important;
}

.vendor-hours-container .day-container {
  width: 60px;
}

.vendor-hours-container .ui-chkbox .ui-chkbox-box.ui-state-active {
  background: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.vendor-hours-container .ui-chkbox .ui-chkbox-icon {
  color: $green;
}

.vendor-hours-container .ui-chkbox {
  transform: scale(1.5); /* checkbox size */
}

.vendor-hours-container .ui-chkbox.ui-widget { /* checkbox alignment */
  width: 100%;
  margin: 10px auto;
}

/* ----------------------*/

/* Vendor User Team */
.vendor-user-container .span-title {
  margin-top: 5px;
}

.vendor-user-container span.accordion-heading-title {
  margin: 0;
}

.vendor-user-container .tip-button {
  margin: 0;
}

.vendor-user-container label.btn.btn-pill-right.btn-right {
  margin-left: -20px;
}

.vendor-user-container label.btn.btn-pill-left.ng-valid.ng-touched.ng-dirty.active,
.vendor-user-container label.btn.btn-pill-left.ng-untouched.ng-pristine.ng-valid.active {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.vendor-user-container .settings-container.users-card-block {
  margin-top: 20px;
}

.settings-parent-container .team-users .user-list-entry .col-sm-4 {
  padding-left: 0;
}

@media(max-width: 500px) {
  .vendor-user-container label.btn.btn-pill-right.btn-right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
/* ------- */
