.ui-message {
    border: 1px solid;
    margin: 0px .25em;
    padding: .25em .5em;
    display: inline-block;
    vertical-align: top;
}

.ui-message .ui-message-icon,
.ui-message .ui-message-text {
    vertical-align: middle;
}

.ui-fluid .ui-message {
    display: block;
}