@import '../../app/calendar/service-colors';

.ui-overlaypanel.navbar-search-overlay {
  left: 0px !important;
  top: 48px !important;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}

.ui-overlaypanel.no-padding .ui-overlaypanel-content {
  padding: 0;
}

.full-calendar-overlay-panel.ui-overlaypanel .ui-overlaypanel-content {
    padding: .5em 0.5em;
}

.full-calendar-overlay-panel.ui-overlaypanel {
  font-weight: 400;
  width: 265px;
}

.full-calendar-overlay-panel.ui-overlaypanel.overdue {
  border-color: $calendar-overdue;
}

.ui-overlaypanel.recipient-search {
  height: 200px;
  overflow-y: auto;
  z-index: 1050 !important; // We should override 1002 of prime because we are using this on modal
  line-height: 0;
  width: 440px;
}

.advanced-datepicker-calendar-overlay.ui-overlaypanel {
  right: 23px !important;
  top: 35px !important;
  left: 0 !important;
}

.date-only-picker-range-overlay.ui-overlaypanel {
  top: 35px !important;
  left: 0 !important;
  margin-top: 0;
}

.date-only-picker-calendar-overlay.ui-overlaypanel {
  top: 35px !important;
  left: 0 !important;
  margin-top: 0;
}

.advanced-datepicker-inline .ui-overlaypanel {
  top: 35px !important;
  left: auto !important;
  right: 0 !important;
}

.advanced-datepicker-calendar-overlay.ui-overlaypanel .ui-overlaypanel-content {
  padding: 0;
}

.date-only-picker-range-overlay.ui-overlaypanel > .ui-overlaypanel-content {
  padding: 0;
  width: 300px;
  text-align: left;
}

.date-only-picker-calendar-overlay.ui-overlaypanel > .ui-overlaypanel-content {
  padding: 0;
}

.vendor-service-overlay.ui-overlaypanel .ui-overlaypanel-content {
  padding: 0;
}

.task-type-filter-overlay.ui-overlaypanel .ui-overlaypanel-content {
  width: 225px;
  padding: 0;
}
