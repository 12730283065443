@import "../custom_variables";

.popover.dark {
  width: auto;
  background-color: $dark-normal;
  z-index: 1;
}

.popover.dark.bs-popover-top .arrow::after {
  border-top-color: $dark-normal;
}

.popover.dark.bs-popover-auto[x-placement^=bottom] .arrow::after,
.popover.dark.bs-popover-bottom .arrow::after {
  border-bottom-color: $dark-normal;
}

.popover.dark.bs-popover-left .arrow::after {
  border-left-color: $dark-normal;
}

.popover.dark.bs-popover-auto[x-placement^=right],
.popover.dark.bs-popover-right .arrow::after {
  border-right-color: $dark-normal;
}

.popover.dark .popover-content {
  color: $light-normal;
}

.popover.find-recipient {
  max-width: 440px !important;
}

.popover.find-recipient .popover-body {
  width: 100%;
  padding: 3px;
}

.popover.client-info-popover .popover-content {
  padding: 0;
}

.popover.payment-instructions-tooltip {
  max-width: 500px !important; // !important because this is overridden in global styles as important
}

.popover.client-invoices {
  min-width: 300px;
  padding: 5px;
}

.popover.client-invoices .popover-body {
  width: 100%;
}

.popover.breakdown-tooltip {
  max-width: 800px !important; // !important because this is overridden in global styles as important
}

.popover.vendor-services-popover {
  width: auto;
  border: 1px solid $primary;
}

.popover.vendor-services-popover .popover-body {
  padding: 0;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.popover.max-width-700 {
  max-width: 700px !important; // !important because this is overridden in global styles as important
}

.popover.ip-right-trademark-popover {
  max-width: 200px !important; // !important because this is overridden in global styles as important
  z-index: 2;
}
