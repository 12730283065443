@import "../custom_variables";

p-checkbox {
  // This fixes the issue where long labels do not wrap properly on very long labels.
  // Accompanied by the label that has normal wrapping below: p-checkbox label
  white-space: nowrap;
}

p-checkbox .ui-chkbox {
  margin-right: 0;
}

p-checkbox .ui-chkbox + label {
  margin-left: 0.7em;
}

p-checkbox .ui-chkbox .ui-chkbox-box.ui-state-active {
  background-color: $green;
  border-color: $green;
}

p-checkbox label {
  margin-bottom: 0;
  white-space: normal;
}

townip-settings-v2 p-checkbox.form-control,
p-checkbox.form-control {
  border: none;
  padding: 0;
}

townip-route-options-form p-checkbox label.search-authority {
  font-weight: bolder;
}

townip-multiple-country-selector p-checkbox .ui-chkbox-label {
  font-weight: 500;
  font-size: 12px;
}

.vendor-selection.ui-chkbox .ui-chkbox-box.ui-state-active {
  background-color: #ffffff;
  border-color: #ffffff;
  color: $green
}

// Standard Control Styling for checkboxes
.standard-control.standard-checkbox.ng-touched.ng-invalid .ui-chkbox-box {
  border: 1px solid $danger;
}

.standard-control.standard-checkbox.ng-touched.ng-invalid .ui-chkbox-label {
  color: $danger;
}
