.ui-overlaypanel {
    padding: 0;
    margin: 0;
    position: absolute;
    margin-top: 10px;
}

.ui-overlaypanel-flipped {
    margin-top: 0;
    margin-bottom: 10px;
}

.ui-overlaypanel-content {
    padding: 0.5em 1em;
}

.ui-overlaypanel-close {
    position: absolute;
    top: -1em;
    right: -1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    cursor: pointer;
}

.ui-overlaypanel-close-icon {
    line-height: inherit;
}

.ui-overlaypanel:after, .ui-overlaypanel:before {
	bottom: 100%;
	left: 1.25em;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.ui-overlaypanel:after {
	border-width: 8px;
	margin-left: -8px;
}

.ui-overlaypanel:before {
	border-width: 10px;
	margin-left: -10px;
}

.ui-overlaypanel-shifted:after, .ui-overlaypanel-shifted:before {
    left: auto;
    right: 1.25em;
    margin-left: auto;
}

.ui-overlaypanel-shifted:after {
    margin-right: -8px;
}

.ui-overlaypanel:before {
    margin-right: -10px;
}

.ui-overlaypanel-flipped:after, .ui-overlaypanel-flipped:before {
    bottom: auto;
    top: 100%;
}

.ui-overlaypanel.ui-overlaypanel-flipped:after {
    border-bottom-color: transparent;
}

.ui-overlaypanel.ui-overlaypanel-flipped:before {
    border-bottom-color: transparent
}