@import "custom_variables.scss";

/* Create vendor component */
.onboarding-title {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.onboarding-container .active-step{
  color: $green;
}
/* -------------- */

.create-user-container {
  margin: 20px auto;
}

.create-user-container .accordion-heading-title {
  margin-top: -35px;
  margin-left: 92px;
}

.create-user-container .text-button:hover {
  background: none;
  color: $green;
  border-bottom: 1px solid $green;
}

.create-user-container .div-header-container button {
  max-width: 250px;
  margin-top: 0;
}

.create-user-container .educ-level-container {
  max-width: 100%;
}

.create-user-container .user-container-full-width {
  width: 100%;
}

.create-user-container .step-title {
  font-weight: 400;
}

.create-user-container .add-fee-container {
  position: absolute;
  left: 85%;
}

.create-user-container .filing-translation-container {
  margin-top: 30px;
  margin-left: 20px;
}
