.card {
  padding: 10px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
  0 2px 2px 0 rgba(0,0,0,.14),
  0 1px 5px 0 rgba(0,0,0,.12);
  transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
  display: block;
  position: relative;
  border-radius: 2px;
  border:none;
  width: fit-content;
}
