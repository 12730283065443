
.onboarding-container accordion .panel-heading.card-header {
  margin-left: 0;
  border: 1px solid #dfdfdf;
  font-weight: bold;
}

.onboarding-container accordion .card {
  box-shadow: none;
  padding: 0;
}

.onboarding-container accordion .panel-body {
  border-left: 1px solid #E2E5E8;
  border-right: 1px solid #E2E5E8;
  border-bottom: 1px solid #E2E5E8;
  border-radius: 0 0 3px 3px;
}


.onboarding-container accordion .form-control {
  border: 1px solid #ced4da;
  padding: .375rem .75rem;
  border-radius: .25rem;
}
