@import "../../stylesheet/custom_variables";

p-editor .ui-editor-container.messaging .ql-toolbar.ql-snow {
  border: none;
}

p-editor .ui-editor-container.messaging .ql-container.ql-snow {
  border: none;
}

p-editor .ui-editor-container.estimate-project-notes .ql-picker-label {
  bottom: 2px;
}

// ql-editor styling
// This will take effect on p-editor and when rendering the value of p-editor

.ql-editor {
  font-family: $font-family-default;
  font-weight: $font-weight-default;
  font-size: $font-size-default;
}

.ql-editor strong {
  font-weight: bold;
}
