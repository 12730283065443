/** TabMenu **/
.ui-tabmenu .ui-tabmenu-nav { 
    margin: 0;
    padding: .25em .5em 0 .25em; 
}

.ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem { 
    list-style: none; 
    float: left; 
    position: relative; 
    margin: 0 .2em 1px 0;  
    padding: 0; 
    white-space: nowrap;
    display: block;
    border-bottom: 0;
    top: 1px; 
}

.ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem a { 
    float: left; 
    padding: 0.5em 1em;
    text-decoration: none; 
}

.ui-tabmenu .ui-tabmenu-nav a { 
    padding: 0.5em 1em;
}

.ui-tabmenu .ui-menuitem-icon  { 
    margin-right: .25em;
    vertical-align: middle;
}

.ui-tabmenu .ui-menuitem-text  { 
    vertical-align: middle;
}

.ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-disabled a {
     cursor: default;
} 