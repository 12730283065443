.ui-datatable {
    position: relative;
}

.ui-datatable table {
	border-collapse:collapse;
    width: 100%;
    table-layout: fixed;
}

.ui-datatable .ui-datatable-header,
.ui-datatable .ui-datatable-caption,
.ui-datatable .ui-datatable-footer {
    text-align: center;
	padding: .5em .75em;
    box-sizing: border-box;
}

.ui-datatable .ui-datatable-caption,
.ui-datatable .ui-datatable-header {
    border-bottom: 0 none;
}

.ui-datatable .ui-datatable-footer {
    border-top: 0 none;
}

.ui-datatable thead th, .ui-datatable tfoot td {
    text-align: center;
}

.ui-datatable thead tr {
    border-width: 0;
}

.ui-datatable .ui-datatable-thead > tr > th,
.ui-datatable .ui-datatable-tfoot > tr > td,
.ui-datatable .ui-datatable-data > tr > td {
    border-color: inherit;
    box-sizing: border-box;
    padding: .25em .5em;
    border-width: 1px;
    border-style: solid;
}


.ui-datatable.ui-datatable-resizable .ui-datatable-thead > tr > th,
.ui-datatable.ui-datatable-resizable .ui-datatable-tfoot > tr > td,
.ui-datatable.ui-datatable-resizable .ui-datatable-data > tr > td {
    overflow: hidden;
}

.ui-datatable .ui-datatable-thead > tr > th,
.ui-datatable .ui-datatable-tfoot > tr > td {
    font-weight: normal;
}

.ui-datatable tbody {
    outline: 0;
}

.ui-datatable .ui-sortable-column {
    cursor: pointer;
}

.ui-datatable .ui-sortable-column-icon {
    display: inline-block;
    margin-left: .125em;
}

.ui-datatable tr.ui-state-highlight {
    cursor: pointer;
}

/* Scrollable */
.ui-datatable-scrollable-body {
    overflow: auto;
    overflow-anchor: none;
    min-height: 0%;
}

.ui-datatable-scrollable-header,
.ui-datatable-scrollable-footer {
    overflow: hidden;
}

.ui-datatable-scrollable .ui-datatable-scrollable-header,
.ui-datatable-scrollable .ui-datatable-scrollable-footer {
    position: relative;
    border: 0 none;
}

.ui-datatable-scrollable .ui-datatable-scrollable-header td {
    font-weight: normal;
}

.ui-datatable-scrollable-body .ui-datatable-data,
.ui-datatable-scrollable-body .ui-datatable-data > tr:first-child {
    border-top-color: transparent;
}

.ui-datatable .ui-datatable-data tr.ui-state-hover,
.ui-datatable .ui-datatable-data tr.ui-state-highlight {
    border-color: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.ui-datatable .ui-datatable-data tr.ui-rowgroup-header td a,
.ui-datatable .ui-datatable-data tr.ui-rowgroup-header td span.ui-rowgroup-header-name {
    display: inline-block;
    vertical-align: middle;
}

.ui-datatable-scrollable-theadclone {
    height: 0;
}

.ui-datatable-scrollable-theadclone tr {
    height: 0;
}

.ui-datatable-scrollable-theadclone th.ui-state-default {
    height: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    padding-top: 0;
    padding-bottom: 0;
    outline: 0 none;
}

.ui-datatable-scrollable-theadclone th span.ui-column-title {
  display: block;
  height: 0;
}

.ui-datatable .ui-paginator {
    padding: .125em;
}

.ui-datatable .ui-paginator-top {
    border-bottom-width: 0;
}

.ui-datatable .ui-paginator-bottom {
    border-top-width: 0;
}

.ui-datatable-rtl {
    direction: rtl;
}

.ui-datatable-rtl.ui-datatable thead th,
.ui-datatable-rtl.ui-datatable tfoot td {
    text-align: right;
}

/* Row Toggler */
.ui-row-toggler {
    cursor: pointer;
}

/* Resizable */
.ui-datatable .ui-column-resizer {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 0;
    width: .5em;
    height: 100%;
    padding: 0px;
    cursor:col-resize;
    border: 1px solid transparent;
}

.ui-datatable .ui-column-resizer-helper {
    width: 1px;
    position: absolute;
    z-index: 10;
    display: none;
}

.ui-datatable-resizable {
    padding-bottom: 1px;     /*fix for webkit overlow*/
    overflow:auto;
}

.ui-datatable-resizable thead th,
.ui-datatable-resizable tbody td,
.ui-datatable-resizable tfoot td {
    white-space: nowrap;
}

.ui-datatable-resizable th.ui-resizable-column {
    background-clip: padding-box;
    position: relative;
}

/** Reflow **/
.ui-datatable-reflow .ui-datatable-data td .ui-column-title {
    display: none;
}

/* Filter */
.ui-datatable .ui-column-filter {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-top: .25em;
}

/* Editing */
.ui-datatable .ui-editable-column input {
    width: 100%;
    outline: 0;
}

.ui-datatable .ui-datatable-data > tr > td.ui-editable-column {
    padding: .5em;
}

.ui-datatable .ui-editable-column > .ui-cell-editor {
    display: none;
}

.ui-datatable .ui-datatable-data > tr > td.ui-editable-column.ui-cell-editing {
    padding: 1px;
}

.ui-datatable .ui-editable-column.ui-cell-editing > .ui-cell-editor {
    display: block;
}

.ui-datatable .ui-editable-column.ui-cell-editing > .ui-cell-data {
    display: none;
}

.ui-datatable-stacked thead th,
.ui-datatable-stacked tfoot td {
    display: none !important;
}

.ui-datatable.ui-datatable-stacked .ui-datatable-data > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
}

.ui-datatable.ui-datatable-stacked .ui-datatable-data.ui-widget-content {
    border: 0 none;
}

.ui-datatable-stacked .ui-datatable-data tr.ui-widget-content {
    border-left: 0 none;
    border-right: 0 none;
}

.ui-datatable-stacked .ui-datatable-data td .ui-column-title {
    padding: .4em;
    min-width: 30%;
    display: inline-block;
    margin: -.4em 1em -.4em -.4em;
    font-weight: bold;
}

.ui-datatable .ui-selection-column .ui-chkbox,
.ui-datatable .ui-selection-column .ui-radiobutton {
     margin: 0;
     display: block;
}

.ui-datatable .ui-selection-column .ui-chkbox-box,
.ui-datatable .ui-selection-column .ui-radiobutton-box {
    display: block;
    box-sizing: border-box;
    margin: 0;
}

.ui-datatable-scrollable-wrapper {
    position: relative;
}

.ui-datatable-scrollable-view {
    
}

.ui-datatable-frozen-view .ui-datatable-scrollable-body {
    overflow: hidden;
}

.ui-datatable-unfrozen-view {
    position: absolute;
    top: 0px;
}

.ui-datatable .ui-datatable-load-status {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.ui-datatable .ui-datatable-virtual-table {
    position: absolute;
    top: 0px;
    left: 0px;
}

.ui-datatable .ui-datatable-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
}

.ui-datatable .ui-datatable-loading-content {
    position: absolute;
    left: 50%;
    top: 25%;
    z-index: 2;
}

@media ( max-width: 35em ) {
    .ui-datatable-reflow thead th,
    .ui-datatable-reflow tfoot td {
        display: none !important;
    }

    .ui-datatable-reflow .ui-datatable-data > tr > td {
        text-align: left;
        display: block;
        border: 0 none;
        width: 100% !important;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		float: left;
		clear: left;
    }

    .ui-datatable-reflow .ui-datatable-data.ui-widget-content {
        border: 0 none;
    }

    .ui-datatable-reflow .ui-datatable-data tr.ui-widget-content {
        border-left: 0 none;
        border-right: 0 none;
    }

    .ui-datatable-reflow .ui-datatable-data td .ui-column-title {
        padding: .4em;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4em;
        font-weight: bold;
    }
    
    .ui-datatable-reflow.ui-datatable-scrollable .ui-datatable-scrollable-body colgroup {
        display: block;
    }
}