// Contains Global Style Changes for the p-dataTable (GLOBAL ONLY, e.g changes the looks the component on all pages)
// Always place them under p-Datable scope! so it won't leak to other prime components
// TODO: move global-scoped styles from datatable.css to here

@import "../custom_variables";

.column-edit-header > button,
.column-edit-header > div {
  margin-right: 10px;
}

.btn-edit-columns {
  border: 1px solid #E1E5E6;
  display: inline-block;
  padding: 0 10px 5px 10px;
  border-radius: 5px;
  background-color: $white;
  /*margin: 10px 0;*/
  font-family: $font-family-default;
}

.column-edit-header .btn.btn-col-reset {
  padding: 7px 10px;
  border-radius: 5px;
  background-color: $white;
  border: 1px solid #E1E5E6;
  display: inline-block;
  font-family: $font-family-default;
  color: #39393a;
  font-weight: 300;
  font-size: 13px;
}

.column-edit-header .btn.btn-col-reset:hover,
.column-edit-header .btn.btn-col-reset:focus,
.column-edit-header .btn.btn-col-reset:active,
.column-edit-header .btn.btn-secondary.btn-col-reset:active:not([disabled]):not(.disabled):active {
  border-color: $green;
  background-color: #FFFFFF;
  color: #39393a;
  box-shadow: none;
}

.column-edit-header .btn.btn-col-reset.btn-primary,
.column-edit-header .btn.btn-col-reset.btn-primary:hover,
.column-edit-header .btn.btn-col-reset.btn-primary:focus {
  background-color: $primary;
  color: #ffffff;
}

.btn-edit-columns p-multiSelect .ui-multiselect .ui-multiselect-label {
  font-size: 13px;
  font-weight: 300;
  padding: 0.25rem 0 0.25rem 0.5rem;
}

.btn-edit-columns .ui-multiselect.ui-widget.ui-state-default.ui-corner-all{
  height: 29px;
  vertical-align: bottom;
  border-radius: 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #FFFFFF;
}

.btn-edit-columns:hover{
  border-color: $green;
}

.btn-edit-columns p-multiSelect .ui-multiselect {
  padding-top: 0.45rem;
  padding-right: 0 !important;
  padding-bottom: 0;
  padding-left: 0;
  border: none;
  font-family: $font-family-default;
}

.btn-edit-columns p-multiSelect .ui-multiselect-trigger {
  display: none;
}
