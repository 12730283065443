.ui-listbox {
    padding: .25em;
    width: 10em;
}

.ui-listbox .ui-listbox-list-wrapper {
    overflow:auto;
}

.ui-listbox .ui-listbox-list {
    list-style-type: none; 
    margin: 0;
    padding: 0;
}

.ui-listbox .ui-listbox-item {
    padding: .25em;
    border: 0 none;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 1px;
}

.ui-listbox .ui-listbox-item > span {
    vertical-align: middle;
}

.ui-listbox .ui-listbox-item:last-child {
    margin-bottom: 0;
}

.ui-listbox.ui-state-disabled .ui-listbox-item {
    cursor: default;
}

.ui-listbox-header {
    margin-bottom: 0.3em;
    padding: .125em .2em;
    position: relative;
}

.ui-listbox-header .ui-chkbox {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.ui-listbox-header .ui-listbox-filter-container {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
}

.ui-listbox-header.ui-listbox-header-w-checkbox .ui-listbox-filter-container {
    width: calc(100% - 2em);
}

.ui-listbox-header .ui-listbox-filter-container .ui-listbox-filter-icon {
    position: absolute;
    top: .25em;
    left: .25em;
}

.ui-listbox-header .ui-inputtext {
    padding: .125em .125em .125em 1.25em;
    width: 100%;
}

.ui-listbox-footer {
    padding: .125em .2em;
}