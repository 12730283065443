@import "../custom_variables";
@import "scss/functions";
@import "scss/variables";
@import "scss/mixins";

.date-only-picker-wrapper .form-control {
  border: $input-border-width solid $input-border-color;
}

.date-only-picker-wrapper .form-control:focus {
  @include form-control-focus();
}

townip-date-only-picker.ng-invalid.ng-touched .date-only-picker-wrapper .form-control {
  border-color: $danger;
  border-width: 2px;
}

townip-date-only-picker.ng-invalid.ng-touched .date-only-picker-wrapper .form-control.ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-color: $danger;
  box-shadow: 0 0 0 $input-focus-width rgba($danger, .25);
}

.clear-btn-visible .date-only-picker-input.ui-inputtext {
  padding-right: 2rem !important;
}

.date-only-picker-input.ui-inputtext.show-icon {
  padding-left: 40px !important;
}
