@import "custom_variables";

.settings-parent-container .settings-main-box { /* setting pages container class */
  max-width: 770px;
}
.settings-parent-container .main-container
.settings-parent-container ul.ul-company-profile {
  padding-right: 30px;
  list-style: none;
}

/* border style around form element */
.settings-parent-container .company-info,
.settings-parent-container .form-container {
  background-color: $white;
  box-shadow: inset 0 2px 0 0 rgba(255,255,255,0.5), 0 1px 3px 0 rgba(0,0,0,0.3);
  border: 1px solid $green;
  border-radius: 2px;
  padding: 40px;
  width: 100%;
}

.settings-parent-container .user-container {
  margin-left: 0 !important;
}

.settings-parent-container .settings-parent-container .company-summary {
  padding: 20px;
  background-color: $white;
  border: 1px solid $green;
  border-radius: 2px;
  word-wrap: break-word;
}

.settings-parent-container .main-container {
  margin: 30px auto;
}

.settings-parent-container .company-title {
  color: $gray-dark;
}

.settings-parent-container .company-header .initial {
  margin-right: 16px;
}

.settings-parent-container .pref-container {
  background-color: $white;
  box-shadow: inset 0 2px 0 0 rgba(255,255,255,0.5), 0 1px 3px 0 rgba(0,0,0,0.3);
  //border: 1px solid $green;
  border-radius: 2px;
  padding: 10px;
  margin-top: 30px;
}

.settings-parent-container .container-body {
  margin-top: 60px;
}

/* settings top logo */
.settings-parent-container img.settings-logo {
  padding-right: 15px;
}

/* active and inactive buttons */
/* active and inactive buttons */
.settings-parent-container .btn-pill-group {
  padding-bottom: 20px;
  margin-top: 40px;
  /* transition: all .2s ease-in-out; */
}

.settings-parent-container .btn-pill-group label {
  /* transition: all .2s ease-in-out; */
  height: 50px;
  transition: none;
}

.settings-parent-container .btn-pill-group label {
  text-transform: uppercase;
}

.settings-parent-container .btn-pill-group label:hover {
  /* background: linear-gradient(180deg, $green 0%, #34907B 100%); */
  background: $green;
  color: $white;
  transition: none;
}

/* ---------- settings active button ----------- */
.settings-parent-container .btn-pill-group label.active {
  background: $green;
  color: $white;
  border: 1px solid $green;
  box-shadow: none;
}
.settings-parent-container .btn-pill-group label.btn-pill-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}


.settings-parent-container .btn-pill-group label.btn-pill-left:hover {
  box-shadow: none;
}

.settings-parent-container .btn-pill-group label.btn-pill-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.settings-parent-container .btn-group.btn-pill-group label {
  padding: 12px;
  padding-left: 60px;
  padding-right: 60px;
  border: 1px solid #979797;
}

.settings-parent-container .btn-group.btn-pill-group {
  z-index: 2;
}

.settings-parent-container label.btn.btn-pill-right.btn-right {
  margin-left: -20px;
}


/* button  layout format */
.settings-parent-container .btn-group.btn-pill-group label.ng-valid {
  border: 1px solid $green;
}

/* Inactive buttons */
.settings-parent-container .btn-pill-right.ng-valid,
.settings-parent-container .btn-pill-left.ng-valid {
  color: $green;
}

.settings-parent-container .btn-select .btn.active {
  border-radius: 20px;
  box-shadow: none;
}

.settings-parent-container .modal-left-padding {
  padding-left: 45px;
}

/* external contacts */
.settings-parent-container .external-set-container .modal-content label {
  color: #9B9B9B;
}

.settings-parent-container .external-set-container .settings-card-title {
  margin-left: 0;
}

.settings-parent-container .external-set-container .name-text-margin {
  margin-top: -10px !important;
  padding-left: 20px;
  line-height: 2px;
}

.settings-parent-container .external-set-container .external-contact-list {
  min-width: 800px;
}

.settings-parent-container .modal-content .ui-dropdown-filter-container {
  width: 100%;
}

/* external companies */
.external-contact-companies .name-text-margin {
  padding-top: 15px;
}
/* ----- */

.settings-parent-container .modal-content
label.ui-dropdown-label.ui-inputtext.ui-corner-all {
  font-size: 11.5pt !important;
  color: $black;
}
.settings-parent-container .modal-content
span.fa.fa-fw.fa-caret-down.ui-clickable {
  margin-top: 1em !important;
}

/* remove green border and box shadow on modal / set container padding on modal*/
.settings-parent-container .modal-dialog .company-info,
.form-body .company-info {
  border: none;
  box-shadow: none;
  padding: 20px 40px 0 40px !important;
}

.settings-parent-container .internal-user-container .modal-dialog .ui-inputtext {
  font-size: 11pt !important;
}

.settings-parent-container .modal-dialog .internal-user {
  padding: 0 70px 0 70px;
  margin: 0;
}

.form-body .company-info {
  padding: 20px !important;
}

/* Third party components */
.settings-parent-container .ui-select-match-item {
  display: none;
}

.settings-parent-container i.fa.fa-angle-down {
  margin-left: -35px;
  margin-top: 10px;
}

.settings-parent-container span.btn-secondary {
  line-height: 2;
}

.settings-parent-container .settings-container,
.settings-parent-container .external-contact-companies .panel.card.panel-default { /* user settings */
  background-color: $white;
  box-shadow: inset 0 2px 0 0 rgba(255,255,255,0.5), 0 1px 3px 0 rgba(0,0,0,0.3);
  border: 1px solid $green;
  padding: 10px;
}

.settings-parent-container .external-contact-companies .panel.card.panel-default {
  margin-bottom: 20px;
  padding: 0;
}

.settings-parent-container .btn-users-teams .panel.card.panel-default { /* team-settings */
  background-color: $white;
  box-shadow: inset 0 2px 0 0 rgba(255,255,255,0.5), 0 1px 3px 0 rgba(0,0,0,0.3);
  border: 1px solid $green;
}

.settings-parent-container .settings-container .row {
  margin-left: 0;
}

.settings-parent-container .user-list-entry .row {
  background: #EAF6F3;
  margin: 10px;
}

.settings-parent-container .user-list-entry .col-sm-4.col-teams {
  color: $green;
}

.settings-parent-container .user-list-entry .col-teams {
  padding: 5px 23px;
  margin-top: 10px;
}

.settings-parent-container .vendor-user-dropdown .show > .dropdown-menu,
.settings-parent-container .user-list-entry .show > .dropdown-menu {
  right: 0 !important;
  width: fit-content;
}

.settings-parent-container .vendor-user-dropdown button#dropdownMenuButton {
  margin-bottom: 0;
  text-align: left;
}

/* add user container*/
.settings-parent-container .add-user-team {
  background-color: $white;
  box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.5), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid $green;
  padding: 10px;
}

.settings-parent-container .add-user-team .col-lg-6 {
  min-width: 100%;
  padding: 10px 50px 10px 50px;
}

.settings-parent-container .user-list-entry .vendor-action-button {
  width: 100%;
}
/* --------- */

.settings-parent-container .team-type-container p {
  margin: 0;
}

.settings-parent-container .btn-add-user { /* center the add user button - user team page */
  margin: 0 auto;
}

.settings-parent-container .user-list-entry .col-teams .team-chip {
  /*padding: 5px 20px 5px 20px;
  border: 1px solid $green;
  border-radius: 2px;
  background: $white;
  border-radius: 15px;
  display: inline-block;*/

  padding: 0;
  border: none;
  background: transparent;
  color: $green;

}

.settings-parent-container .close-btn {
  padding: 2px 6px;
}

.settings-parent-container .user-list-entry .col-company-username,
.settings-parent-container .user-list-entry .col-vendor-username{
  color: $green;
}

.settings-parent-container .user-list-entry .col-company-email,
.settings-parent-container .user-list-entry .col-vendor-email{
  color: #9B9B9B;
  margin-top: -12px;
  word-break: break-all;
}

.settings-parent-container .user-list-entry .btn-user-actions {
  background: $white;
  border: 1px solid $green;
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  margin-top: 10px;
  color: $green!important;
}

.settings-parent-container .user-list-entry .btn-user-actions:hover {
  background: #F9F9F9;
}

#addTeamModal .modal-dialog {
  min-width: 800px;
}

/* Team */
.settings-parent-container .panel-heading.card-header {
  margin-left: 0;
  margin-bottom: 20px;
  border-bottom: none; /* user teams title bottom border */
}

.settings-parent-container .panel-collapse.collapse.in.show {
  margin-top: 0;
  padding-left: 0;
}

.settings-parent-container .col-role button,
.settings-parent-container .col-visibility button {
  background: transparent;
  color: $green;
  border: none;
  margin-top: 20px;
}

/* add user team */
.settings-parent-container .dropdown.col-role.show {
  margin-right: -25px !important;
}

/* --------- */

/* show more - pagination*/
.settings-parent-container .pagination-btn button {
  background: transparent;
  color: $green !important;
  border: none;
}

.settings-parent-container .form-element {
  /* margin-top: 0; */
}

/* settings title */
.settings-parent-container .settings-heading-text {
  color: $gray-dark;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: normal;
  margin-left:  20px;
}

.settings-parent-container .back-container {
  margin-left: 40px;
}

.settings-parent-container .back-container .fa {
  padding-right: 10px;
}

.settings-parent-container .top-margin {
  margin-top:20px;
}

/* preferences */
.settings-parent-container .tip-close-button {
  padding: .3rem .5rem;
}

.settings-parent-container .team-chip .clear-text-button {
  padding: .5rem .2rem;
}

.settings-parent-container .name-text {
  color: $green;
  font-family: Arvo;
  line-height: 21px;
  margin-top: 13px;
  text-transform: capitalize;
}

.settings-parent-container .main .clear-text-button {
  text-decoration: underline;
  outline: none;
  box-shadow: none;
}

.settings-parent-container .name-text-margin {
  margin-top: -10px;
  padding-left: 40px;
  line-height: 2px;
}

.settings-parent-container p.bottom-q { /* 'not listed' bottom text */
  color: #9B9B9B;
  font-weight: 300;
  text-align: center;
}

.settings-parent-container .div-region p  { /* external contacts search input margin */
  margin-bottom: 0;
}

.settings-parent-container .btn-users-teams .panel-collapse .card-block { /* fixed extra padding on team card */
  padding: 0;
}

.settings-parent-container .btn-users-teams .panel.card.panel-default { /* teams card block bottom margin */
  margin-bottom: 20px;
  margin-top: 20px;
}

.settings-parent-container .settings-container.users-card-block { /* fixed users card block right and left extra padding */
  padding-right: 0;
  padding-left: 0;
}

.settings-parent-container .row-region {
  margin-left: 0;
  margin-right: 0;
}

.settings-parent-container .region-team-type {
  color: $green;
  text-transform: capitalize;
  font-family: "Arvo-Regular";
}

.settings-parent-container .btn-group.btn-pill-group.btn-select label{
  text-transform: uppercase;
}

.settings-parent-container .settings-card-title {
  color: #9B9B9B;
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 20px;
}

.settings-parent-container .col-sm-2.settings-card-title {
  margin-left: 0;
}

.settings-parent-container .col-sm-4.settings-card-title {
  margin-left: -15px;
}

.settings-parent-container townip-jurisdictional-vendor-row .settings-card-title {
  margin-left: 35px;
}

.settings-parent-container .drop-down-child .settings-card-title,
.settings-parent-container .filing-juris-container .settings-card-title {
  margin-left: 0;
}

.settings-parent-container span.accordion-heading-title {
  font-weight: 400;
}

/* Mobile */
@media(max-width: 450px) {
  .settings-parent-container .back-container {
    margin-left: 0;
  }
  .settings-parent-container .settings-heading-text {
    margin-left: 0;
  }
}


/* application preferences top buttons settings*/
@media(max-width: 500px) {
  .settings-parent-container .btn-pill-group label.btn-pill-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .settings-parent-container label.btn.btn-pill-middle,
  .settings-parent-container label.btn.btn-pill-right.button3 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
    z-index: 9;
  }

  .settings-parent-container label.btn.btn-pill-right.button3.ng-untouched.ng-pristine.ng-valid,
  .settings-parent-container label.btn.btn-pill-right.button3.ng-valid.ng-touched.ng-dirty {
    margin-left: 0;
  }

  .settings-parent-container .btn-group.btn-pill-group label.ng-valid:hover {
    border-radius: 0;
  }

  .settings-parent-container label.btn.btn-pill-left.active,
  .settings-parent-container label.btn.btn-pill-right.ng-valid.active,
  .settings-parent-container label.btn.btn-pill-right.btn-right.ng-valid {
    border-radius: 0;
  }

  .settings-parent-container .btn-pill-group label.btn-pill-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .settings-parent-container .accordion-child-div {
    margin-left: 0;
  }

  .settings-parent-container .panel-body.card-block {
    padding: 0;
  }
  .settings-parent-container .accordion-child-div {
    margin-left: 10px;
  }
  .settings-parent-container .panel-heading.card-header {
    padding-bottom: 30px;
  }
  .settings-parent-container .btn-pill-group {
    display: grid;
  }
  .settings-parent-container .pref-container {
    margin-left: 0;
  }
  .settings-parent-container .panel-body.card-block {
    padding-left: 0;
  }

  .settings-parent-container .radio-item {
    margin-top: 20px;
  }

  .settings-parent-container .settings-pill {
    margin-left: 0;
  }
}


@media (max-width: 768px) { /* fix elements overlapping width on mobile view */
  .settings-parent-container label.btn.btn-outline-secondary.tip-button {
    min-width: 259px;
  }
  .settings-parent-container .settings-heading-text { /* settings title on small screen */
    margin-left: 40px;
    margin-top: 20px;
  }
  .settings-parent-container .form-container { /* remove form container paddings on mobile */
    padding: 0;
  }
  .settings-parent-container .un-style .col-lg-1 {
    width: inherit;
  }
  .settings-parent-container .header-profile {
    text-align: left; /* company profile upload logo */
    margin-top: 20px;
  }
  .settings-parent-container .header-profile .upload-logo {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .settings-parent-container .settings-heading-text {
    margin-left: -40px;
    margin-top: 0;
  }
  .settings-parent-container .form-element.first-name {
    margin-left: -8px;
  }
}


/* Fieldset component */
.settings-parent-container legend {
  width: auto;
  font-family: $font-family-default;
}

/* vendor translation */

.settings-parent-container .ui-rating,
  /* Making sure this only works in vendor jurisdiction or it will break everything else that uses this control */
townip-vendor-jurisdiction-entry .ui-rating {
  position: absolute;
  margin: 12px 0 13px 200px;
  z-index: 1;
}

.settings-parent-container .destination-container a {
  text-decoration: none;
}

/* make default button */
.settings-parent-container .default-status {
  position: absolute;
  left: 930px;
  margin-top: 12px;
  z-index: 1;
  cursor: pointer;
  width: 100%;
}

/* vendor and app preferences add service text and button container */
.settings-parent-container .accordion-header-button.add-service-container {
  position: absolute;
  display: inline-flex;
  margin-left: 100%;
  right: 0;
  /*width: 157px;*/
  width: auto;
}

.settings-parent-container .proof-chkbox-div {
  padding-top: 5px;
  margin-left: 28px;
}

.settings-parent-container .proof-chkbox-div label {
  margin-bottom: 0;
}

.settings-parent-container .accordion-heading-div .accordion-heading-title {
  padding-right: 20px;
  display: inline-block;
  width: 100%;
  padding-right: 50px;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.settings-parent-container .destination-child-div .accordion-heading-div .accordion-heading-title {
  max-width: 100% !important;
  margin-left: 0;
}

.settings-parent-container label.ui-chkbox-label {
  margin-bottom: 0;
}

.settings-parent-container .proof-chkbox-div label.ui-chkbox-label {
  margin-left: 0;
}

/* filing services */
.settings-parent-container .make-default-status {
  position: absolute;
  left: 80%;
  margin-top: 12px;
  z-index: 1;
  cursor: pointer;
}

.settings-parent-container .filing-translation-container {
  margin-top: 30px;
  margin-left: 20px;
}

/* add fee container text and button section */
.settings-parent-container .add-fee-container {
  position: absolute;
  margin-left: 100%;
  width: 105px;
  right: 33px;
}

.settings-parent-container .filing-translation-container .add-fee-container {
  right: 20px;
}

.settings-parent-container .destination-child-div {
  padding-left: 12px;
}

.settings-parent-container .profile-container {
  border: 1px solid $green;
  margin-top: 20px;
}


.settings-parent-container label.btn.btn-pill-left.ng-untouched.ng-pristine.ng-valid.active {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.settings-parent-container .panel-open .panel-heading.card-header {
  margin-bottom: 0;
}

/* add external contact */
.settings-parent-container .modal-content
.ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  height: 100%;
}

.settings-parent-container .modal-content .ui-dropdown .ui-dropdown-label {
  height: 50px;
  font-size: 11.5pt !important;
}

.settings-parent-container .ui-dropdown-filter-container {
  width: 100%;
}

/* vendor hours card */
.vendor-hours-container .card {
  margin-top: 20px;
}

.day-off-form {
  padding: 20px;
}

.day-off-form .form-element label {
  display: block;
}

/* users and teams search icon styling */
.settings-parent-container .user-set-container .float-search i.fa.fa-search,
.settings-parent-container .vendor-user-container .float-search i.fa.fa-search {
  margin-left: -40px;
  margin-top: 13px;
}

.app-pref-container .contacts-container {
  background-color: transparent !important;
}

.vendor-section .panel-collapse.collapse.in.show .add-service-container {
  margin-right: -10px;
  padding-right: 0;
  margin-left: unset;
  position: absolute;
  right: 0;
}
